import React from 'react';
import './Bonus.css';
import woman from '../../assets/woman.png'
import girl from '../../assets/girl.png'
const Bonus = () => {
  return (
    <div className='bonus-container'>
      <div className='bonus'>
      <img src={woman} alt="img1" className='woman'/>
      <div className='midcontent'>
        <h2>FIRST PURCHASE</h2>
        <h1>BONUS PLUS</h1>
        <button>Make Your First Purchase</button>
      </div>
      <img src={girl} alt="img2" className='girl'/>
      </div>
    </div>
  );
};

export default Bonus;
