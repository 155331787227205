import React, { useState } from "react"
import {
  Box,
  Collapse,
  TableCell,
  TableRow,
  IconButton,
  Divider,
  Chip,
  Typography,
  Button,
} from "@material-ui/core"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import { KeyboardArrowUp, Phone } from "@material-ui/icons"

import { makeStyles } from "@material-ui/styles"
import { SendEmail } from "./helper"
import { makeDecrypt } from "../../utils/securels"
const useStyles = makeStyles((theme) => ({
  requiredBox: {
    border: "1px solid #df2800",
    color: "#df2800",
    borderRadius: "5px",
    padding: "3px 2px",
    lineHeight: 0.5,
    marginRight: "5px",
    outline: "none !important",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    margin: "0 3px",
    width: "fit-content",
  },
}))

export default function PastOrderTable({ row, index, snack }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleEmail = () => {
    SendEmail(makeDecrypt("user_email"), row.order, row.items, row.restaurant)
    snack(true)
  }
  return (
    <>
      <TableRow key={index} sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{new Date(row.orderedAt).toUTCString()}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>${row.totalPrice}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflow: "auto",
                  marginTop: "5px",
                }}
              >
                <Typography size="large" style={{ marginRight: 10 }}>
                  Items ordered:
                </Typography>
                {row.items.map((item) => (
                  <Box className={classes.requiredBox}>
                    <Typography
                      variant="caption"
                      fontSize="medium"
                      style={{ lineHeight: 1.1 }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "8px",
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button variant="contained" color="primary" onClick={handleEmail}>
                  Get Receipt
                </Button>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
