import { Box, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React, { useEffect, useState } from "react"
import { makeDecrypt } from "../utils/securels"
import MenuItem from "./MenuItem"

function MenuItems({
  menuItems,
  categories,
  showPopularity,
  tempSocket,
  setShowDishScreen,
  setActiveItem,
  loadingCat,
  emptyCategories,
  cartItems,
  setTranslatedItem,
}) {
  let count = 0
  const [primaryLang, setPrimaryLang] = useState()
  useEffect(() => {
    setPrimaryLang(makeDecrypt("primaryLang"))
  }, [])

  return (
    <div>
      {categories.map((category, index) => {
        count = 0
        if (category.status == "Online") {
          return (
            <Box key={index} id={`${category.name}`}>
              {(menuItems.some((menuItem) => menuItem.category == category.name) ||
                emptyCategories?.some((cat) => cat == category.name) ||
                loadingCat == category.name ||
                category.name == "discouted" ||
                category.name == "Featured") && (
                <Typography variant="h3">{category.name}</Typography>
              )}
              <Box sx={{ px: 0.7 }}>
                {menuItems.map((menuItem) => {
                  //logic to show counter button below item image

                  let cartItemCopy
                  let showCounter
                  menuItem.isOrdered = false
                  if (cartItems?.some((item) => item.itemId == menuItem._id)) {
                    showCounter = true
                    cartItems.map((item) => {
                      if (item.itemId == menuItem._id) {
                        if (item.isOrdered) {
                          showCounter = false
                        }
                        cartItemCopy = item
                      }
                    })
                  }
                  if (
                    (menuItem.category === category.name &&
                      menuItem.status == "Online") ||
                    category.name == "discouted" ||
                    category.name == "Featured"
                  ) {
                    count = 1
                    return (
                      <MenuItem
                        menuItem={menuItem}
                        key={menuItem._id}
                        categoryId={category._id}
                        showPopularity={showPopularity}
                        tempSocket={tempSocket}
                        setShowDishScreen={setShowDishScreen}
                        setActiveItem={setActiveItem}
                        cartItems={cartItems}
                        showCounter={showCounter}
                        orderedQuantity={cartItemCopy?.quantity}
                        primaryLang={primaryLang}
                        setPrimaryLang={setPrimaryLang}
                        setTranslatedItem={setTranslatedItem}
                      />
                    )
                  }
                })}
              </Box>
              {emptyCategories?.some((cat) => cat == category.name) && (
                <Typography>No Items in this category.</Typography>
              )}
              {!emptyCategories?.some((cat) => cat == category.name) &&
                loadingCat == category.name && (
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={94}
                    style={{ marginBottom: "10px" }}
                  />
                )}
            </Box>
          )
        }
      })}
    </div>
  )
}

export default MenuItems
