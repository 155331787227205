import {
  Box,
  Button,
  CardHeader,
  Avatar,
  CardMedia,
  Container,
  CardContent,
  CircularProgress,
  TextField,
  makeStyles,
  Typography,
  AppBar,
  Toolbar,
  InputAdornment,
  Card,
  Grid,
} from "@material-ui/core"
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/Close"
import React, { useEffect, useState } from "react"
import { BiScan } from "react-icons/bi"
import QrReader from "react-qr-scanner"
import { useHistory } from "react-router-dom"
import axios from "../axios"
import SearchIcon from "@material-ui/icons/Search"
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded"
import insta from '../assets/insta.png';
import fb from '../assets/fb.png';
import twitter from '../assets/twitter.png';
import { LocationOn } from '@mui/icons-material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WorkIcon from '@mui/icons-material/Work';
import Bonus from '../components/Bonus/Bonus'
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "cover",
    flexGrow: 1,
    padding: 0,
  },
  navbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md","sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: ""
    },
  },
  button: {
    width: "80px",
    textAlign: "center",
    fontSize: 14,
    [theme.breakpoints.up("md")]: {
      width: "auto",
      fontSize: 15,
    },
  },
  tags: {
    color: "#df2800",
    justifyContent:  "center",
    with: "max-content",
    borderRadius: "4px",
    padding: "1px 3px",
    lineHeight: 0.5,
    marginRight: "5px",
  },
  text: {
    marginBottom: 10,
    align: "center",
    padding: 5,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      typography: "h6",
      fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
      typography: "h3",
      fontSize: 30,
      marginRight: -80,
    },
  },
  font: {
    textAlign: "center",
    fontWeight: "700",
    padding: theme.spacing(2),
  },
  list: {
    width: "100%",
    maxHeight: "calc(100vh - 240px)",
    overflowY: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "#fcfae6",
    justifyContent: "space-between",
    display: "flex",
    padding: "5%"
  },
  listItem: {
    marginTop: 20,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    // Adjust the width as per your preference
    marginLeft: "5px",
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: 30
  },
  center: {
    margin: "10px",
    marginTop: "50px",
    padding: "20px",
  },
  circle: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: "#000",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
  },
}));

function HomeScreen() {
  const classes = useStyles();
  const history = useHistory();
  const [delay, setDelay] = useState(0);
  const [Restaurants, setRestaurants] = useState([]);
  const [defaultRes, setdefaultRes] = useState([]);
  const [result, setResult] = useState();
  const [noRes, setnoRes] = useState(false);
  const [showScan, setShowScan] = useState();
  const [search, setsearch] = useState("");
  const previewStyle = {
    height: 240,
    width: 320,
    borderRadius: "20px",
  };
  const theme = useTheme();

  useEffect(() => {
    axios
      .get("/restaurant/get-all-restaurants")
      .then((response) => {
        setRestaurants(response.data);
        setdefaultRes(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setnoRes(false);
    const regex = new RegExp(`^${search}`, "gi");
    if (search.length > 1) {
      setRestaurants(
        Restaurants.filter((rest) => rest.RestaurantInfo.name.match(regex))
      );
      if (!Restaurants.length) {
        setnoRes(true);
      }
    } else {
      setRestaurants(defaultRes);
    }
  }, [search]);

  const handleScan = (data) => {
    setResult(data);
    let temp = data?.text.split("/").includes("dine.dinify.io");
    if (temp) window.location.replace(data.text);
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <Container className={classes.root} style={{ backgroundColor: "#F7E3E3", width: "100%" }}>
      <AppBar style={{ backgroundColor: "white"}}>
        <Toolbar
          style={{ alignItems: "center", justifyContent: "space-between", zIndex: 0 }}
        >
          <Box>
            <img
              src="/logo.png"
              width="50px"
              style={{ height: "auto", display: "block", marginLeft: "10%", marginRight: "6rem" }}
            />
          </Box>
          <Box className={classes.navbar}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              href="https://www.dinify.io/"
              style={{ marginRight: 30 }}
            >
              For Business
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              style={{ marginRight: 100 }}
              onClick={() => history.push("/customer")}
            >
              For Customer
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ paddingTop: "5rem" }}>
        <Bonus />
      </Box>
      <Box
        sx={{
          paddingTop: 20,
          backgroundColor: "#F7E3E3",
        }}
      >
        {!showScan ? (
          <Box
            className={classes.searchBar}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent:" center",
            }}
          >
            <TextField
              variant="filled"
              onChange={(e) => setsearch(e.target.value)}
              style={{
                width: "50vw"
              }}
              className={classes.textfield}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "white",
                  borderRadius: 30,
                  marginBottom: 30,
                  width:"100%",
                  justifyContent:"center"
                }
              }}
            />

            {Restaurants.length ? (
              <Grid
                container
                spacing={2}
                className={classes.list}
                justifyContent="space-between"
                alignItems="center"
              >
                {Restaurants?.map((restaurant) => {
                  return (
                    <Grid
                      item
                      key={restaurant.RestaurantInfo._id}
                      className={classes.listItem}
                      xs={12}
                      sm={5}
                      md={5}
                      lg={3}
                      xl={3}
                      style={{
                        justifyContent: "space-between",
                        borderRadius: 30
                      }}
                    >
                      <Card
                      style={{
                        width: "100%"
                      }}
                        onClick={(e) => {
                          history.push(
                            `restaurant/${restaurant.RestaurantInfo._id}/table/takeout`
                          );
                          
                        }}
                      >
                        <Box sx={{
                          display: "flex",
                          justifyContent: "center"
                        }}>
                          <CardMedia
                            component="img"
                            style={{
                              border: "10px solid #F7E3E3",
                              borderRadius: "50%",
                              width: "50%",
                              aspectRatio: "1/1"
                            }}
                            image={
                              restaurant.image ??
                              "https://images.unsplash.com/photo-1493770348161-369560ae357d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                            }
                            alt="Favourite Dish"
                          />
                        </Box>
                        <Box sx={{
                          display: "flex",
                          justifyContent: "center"
                        }}>
                          <CardHeader
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            avatar={
                              <Avatar
                                src={
                                  restaurant.RestaurantInfo?.logoUrl
                                    ? restaurant.RestaurantInfo.logoUrl
                                    : ""
                                }
                                style={{
                                  backgroundColor: `${restaurant.RestaurantInfo?.logoUrl ? "" : "red"
                                    }`,
                                }}
                                aria-label="recipe"
                              >
                                {restaurant.RestaurantInfo?.logoUrl
                                  ? ""
                                  : restaurant.RestaurantInfo.name.charAt(0).toUpperCase()}
                              </Avatar>
                            }
                            titleTypographyProps={{ variant: "h6" }}
                            title={restaurant.RestaurantInfo.name}
                            subheader={
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <LocationOn style={{ marginRight: "4px" }} />
                                {restaurant.RestaurantInfo?.restaurantAddress?.streetName}
                              </div>
                            }
                          />
                        </Box>

                        <CardContent>
                          {!!restaurant.RestaurantInfo.tags?.length && (
                            <Box
                            className={classes.tags}
                            style={{
                              display: "flex",
                              
                              color: "#df2800",
                              justifyContent: "center", // Center the tags horizontally
                              textAlign: "center", // Center the text within the tag
                              margin: "0 3px", // Add some margin between tags
                              borderRadius: "4px",
                              padding: "1px 3px",
                              lineHeight: 0.5,
                            }}
                          >
                              {restaurant.RestaurantInfo.tags.map((tag) => (
                                <Box className={classes.tags}>
                                  <Typography
                                    variant="body1"
                                    style={{ 
                                      color: "black",
                              border: "1px solid #df2800", padding: 2
                                   }}
                                  >
                                    {tag}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          )}
                          <Box sx={{ display: "flex", justifyContent: "space-between", paddingTop: 5, paddingBottom: 5,
                        paddingLeft: 0,
                        paddingRight:0 }}>
                            <Box sx={{ display: "flex", padding: 1, marginTop: 5 }}>
                              <RestaurantIcon />
                              <Typography variant='body1' sx={{ marginLeft: 1 }}>
                                Dine In
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", padding: 1 }}>
                              <WorkIcon />
                              <Typography variant='body1' sx={{ marginLeft: 1 }}>
                                Take Out
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", padding: 1 }}>
                              <LocalShippingIcon />
                              <Typography variant='body1' sx={{ marginLeft: 1 }}>
                                Delivery
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: 5
                          }}>
                            <Button variant="contained" style={{
                              backgroundColor: "#C84141B2"
                            }}>Explore Now!!!</Button>
                          </Box>

                          <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: 5
                          }}>
                            <a href='https://www.instagram.com' target='_blank'><img src={insta} alt='insta' style={{
                              padding: 10
                            }} /></a>
                            <a href='https://www.facebook.com' target='_blank'><img src={fb} alt='fb' style={{
                              padding: 10
                            }} /></a>
                            <a href='https://twitter.com' target='_blank'><img src={twitter} alt='twitter' style={{
                              padding: 10
                            }} /></a>
                          </Box>

                          {!!restaurant.RestaurantInfo.cuisine?.length && (
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "auto",
                                maxWidth: "25vw",
                                marginTop: "2px",
                              }}
                            >
                              {restaurant.RestaurantInfo.cuisine.some(
                                (data) => Boolean(data?.length > 1)
                              ) && (
                                  <>
                                    {restaurant.RestaurantInfo.cuisine.map(
                                      (cuisine) => (
                                        <Box
                                          className={classes.tags}
                                          style={{ border: "1px solid #2074fa" }}
                                        >
                                          <Typography
                                            variant="body1"
                                            style={{ color: "black" }}
                                          >
                                            {cuisine}
                                          </Typography>
                                        </Box>
                                      )
                                    )}
                                  </>
                                )}
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : noRes ? (
              <Box>
                <Typography className={classes.text}>No restaurants</Typography>
              </Box>
            ) : (
              <CircularProgress />
            )}
          </Box>
        ) : (
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            flexDirection="column"
            className={classes.center}
          >
            <QrReader
              delay={delay}
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
            />
          </Box>
        )}

        <Box
          style={{
            position: "fixed",
            right: "10px",
            bottom: "100px",
          }}
        >
          {!showScan ? (
            <Box className={classes.circle}>
              <BiScan
                style={{
                  margin: "0",
                  color: "#F4F6F8",
                  fontSize: "30px",
                }}
                onClick={() => setShowScan(true)}
              />
            </Box>
          ) : (
            <Box className={classes.circle}>
              <CloseIcon
                style={{
                  margin: "0",
                  color: "#F4F6F8",
                  fontSize: "30px",
                }}
                onClick={() => setShowScan(false)}
              />
            </Box>
          )}
        </Box>
      </Box>

    </Container>
  );
}

export default HomeScreen;