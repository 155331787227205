import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import RemoveIcon from "@material-ui/icons/Remove"
import { Skeleton } from "@material-ui/lab"
import "firebase/auth"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import ShowMoreText from "react-show-more-text"
import { refreshCheckout } from "../actions/orderActions"
import { setRestaurant } from "../actions/restaurantActions"
import { setCurrentUser } from "../actions/userActions"
import axios from "../axios"
import AlertMessage from "../components/AlertMessage"
import CouponHighlighter from "../components/CouponHighlighter"
import Loader from "../components/Loader"
import { createUser } from "../components/rewards"
import Login from "../components/rewards/Login"
import { MyContext } from "../context"
import firebase from "../firebase"
import { supportedLangs } from "../utils/Data.js"
import { makeDecrypt, makeEncrypt } from "../utils/securels"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ReactPixel from "react-facebook-pixel"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: "20px 10px 40px",
  },
  dishImg: {
    height: "180px",
    width: "100%",
    borderRadius: "6px",
    objectFit: "cover",
  },
  btn: {
    width: "100%",
    borderRadius: "5px",
    height: "45px",
  },
  popup: {
    position: "absolute",
    width: "100%",
    margin: "0 auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    fontWeight: "700",
    marginTop: "10px",
  },
  addition: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between ",
    alignItems: "center",
    padding: "5px 0",
  },
  actionBtns: {
    width: "100%",
  },
  inputField: {
    width: "100%",
  },
  inputBox: {
    borderRadius: "6px",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrap: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    boxShadow: "-2px 1px 80px -28px rgba(0,0,0,0.66)",
    padding: "16px  !important",
  },
  counter: {
    position: "absolute",
    bottom: "-20px",
    backgroundColor: "#f4f4f4",
    borderRadius: "20px",
    padding: "7px",
  },
  bottomCounter: {
    display: "flex",
    borderRadius: "7px",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;",
    height: "45px",
  },
  counterItems: {
    padding: "6px",
    display: "flex",
    alignItems: "center",
    color: "#000",
    margin: "auto",
  },
  description: {
    marginTop: "10px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    letterSpacing: "-0.05px",
    fontSize: "13px",
    color: "#6b778c",
  },
  showMore: {
    color: "#023e8a",
    cursor: "pointer",
    fontWeight: "700",
  },
  imgBox: {
    borderRadius: "6px",
  },
  buttonProgress: {
    color: "red",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  closeBox: {
    position: "absolute",
    top: "7px",
    cursor: "pointer",
    right: "7px",
    boxShadow:
      " rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;",
    borderRadius: "50%",
    background: "white",
  },
  modifierBox: {
    // boxShadow:
    //   "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    borderRadius: "5px",
    // padding: "10px",
    marginTop: "20px",
  },
  modifierHeadingBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  requiredBox: {
    border: "1px solid #F15412",
    color: "#F15412",
    borderRadius: "4px",
    padding: "1px",
    lineHeight: 0.5,
  },
  modifierOptionsBox: {
    padding: "7px",
  },
  input: {
    width: "16px",
    height: "16px",
  },
}))

function DishScreen({
  match,
  update,
  itemsNo,
  currentItem,
  item,
  setShowDishScreen,
  setViewBill,
  menuId,
  objectId,
  setShowUpdateScreen,
  translatedItem,
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [values, setValues] = useState({
    firebaseOtp: false,
    menuItm: null,
    noOfItems: 1,
    specialInstructions: "",
  })
  const { firebaseOtp, menuItm, noOfItems, specialInstructions } = values
  const [menuItem, setMenuItem] = useState()
  // const [menuItm, setMenuItm] = useState()
  const [currentModifiers, setCurrentModifiers] = useState([])
  const [alertMessage, setAlertMessage] = useState("")
  const [allSelectedModifiers, setAllSelectedModifiers] = useState([])
  const [modifiersPrice, setModifiersPrice] = useState(0)
  const [dataToSend, setDataToSend] = useState([])
  const [quantityOver, setQuantityOver] = useState(false)
  const { restaurant, currencySign } = useSelector((state) => state.restaurant)
  const { thisUser } = useSelector((state) => state.user)
  const [open, setOpen] = useState(false)
  const [showPhoneNoForm, setShowPhoneNoForm] = useState(true)
  const [showOtpForm, setShowOtpForm] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState()
  const [tranlatedName, setTranslatedName] = useState()
  const [tranlatedDesc, setTranslatedDesc] = useState()
  const [showLanguages, setShowLanguages] = useState(false)
  const [translating, setTranslating] = useState()
  const [mandatoryModifiers, setMandatoryModifiers] = useState([])
  const [priceRange, setPriceRange] = useState({
    minPrice: "",
    maxPrice: "",
  })
  const { refreshCart, setRefreshCart, restaurantOpen } = useContext(MyContext)
  const [isTranslated, setIsTranslated] = useState()
  const [discountedPrice, setDiscountedPrice] = useState()
  const [discountLoaded, setDiscountLoaded] = useState()
  const [primaryLang, setPrimaryLang] = useState()
  const menuonly = window.location.search.split("menuonly=")[1]

  /**
   * getting user's and current menu items details
   *
   */

  // Note->Code is breaking for the menu item selcted with modifiers in cart
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {})
    const getMenuItem = async () => {
      try {
        setCurrentModifiers(item.modifiers ? item.modifiers : [])
        if (update && itemsNo) {
          setValues((values) => ({
            ...values,
            menuItm: item,
            noOfItems: itemsNo,
            specialInstructions: currentItem.specialInstructions,
          }))
        } else {
          setValues({ ...values, menuItm: item })
        }
      } catch (error) {
        setAlertMessage(error.message)
      }
    }

    const getUser = () => {
      axios
        .post(`/user/user-details`, {
          phone: makeDecrypt("user"),
          userId: makeDecrypt("userId"),
        })
        .then((response) => {
          if (update) {
            const data = response.data.cart.items.find(
              (item) => item.itemId === menuId
            )

            if (data.modifiers) {
              data.modifiers.map((item) => {
                item.selectedOptions.map((option) =>
                  setAllSelectedModifiers((prevValues) => [
                    ...prevValues,
                    option.modifierId,
                  ])
                )
              })
            }
          }
          // if (!restaurant) dispatch(setRestaurant(makeDecrypt("restaurantId")))
        })
        .catch((error) => {
          setAlertMessage(error.message)
        })
    }
    getMenuItem()
    if ((makeDecrypt("user") || makeDecrypt("userId")) && !thisUser) getUser()
    else if (thisUser) {
      if (update) {
        if (currentItem.modifiers.length) {
          currentItem.modifiers.map((item) => {
            item.selectedOptions.map((option) =>
              setAllSelectedModifiers((prevValues) => [
                ...prevValues,
                option.modifierId,
              ])
            )
          })
        }
      }
    }
    if (!restaurant) dispatch(setRestaurant(makeDecrypt("restaurantId")))
  }, [menuItm])

  useEffect(() => {
    if (menuItm?.modifiers.length) {
      let temp = []
      for (const thisModifier of menuItm.modifiers) {
        if (thisModifier.isMandatory) {
          temp.push(thisModifier.type)
        }
      }
      setMandatoryModifiers(temp)
    }
    if (menuItm) {
      if (menuItm.price === 0 && menuItm.modifiers.length) {
        let tempPrices = []
        for (const item of menuItm.modifiers) {
          if (item.options.length) {
            for (const thisOption of item.options) {
              tempPrices.push(thisOption.modifierPrice)
            }
          }
        }
        setPriceRange({
          minPrice: Math.min(...tempPrices),
          maxPrice: Math.max(...tempPrices),
        })
      }
    }
  }, [menuItm])

  useEffect(() => {
    setModifiersPrice(0)
    let tempPrice = 0
    dataToSend.map((item) => {
      item.selectedOptions.map((option) => {
        tempPrice = tempPrice + option.modifierPrice
      })
    })
    setModifiersPrice(tempPrice)
  }, [dataToSend])

  useEffect(() => {
    setQuantityOver(false)
  }, [noOfItems])

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
    }, 2000)
  }, [alertMessage])

  useEffect(() => {
    if (document.getElementById("dishContainer")) {
      if (values.firebaseOtp)
        document.getElementById("dishContainer").style.display = "none"
      else document.getElementById("dishContainer").style.display = ""
    }
  }, [values.firebaseOtp])

  const handleUserCreation = (result, dishScreen, createFlag) => {
    createUser(result.user.phoneNumber, createFlag)
      .then(async (data) => {
        let user = data
        let items = data.cart.items
        if (dishScreen) {
          if (data.cart.items.length) {
            try {
              let menuItem = await axios.get(
                `/menu/${items[0].itemId}/menu-item-detail`
              )
              dispatch(setRestaurant(menuItem.data.restaurant))
              makeEncrypt("user", user.phone)
              makeEncrypt("restaurantId", menuItem.data.restaurant)
              makeEncrypt("CustomerId", user.stripeDetails.customerId)
              makeEncrypt(
                "paymentMethodId",
                user.stripeDetails.paymentMethod.paymentMethodId
              )
              makeEncrypt(
                "paymentIntentId",
                user.stripeDetails.paymentMethod.paymentIntentId
              )
              makeEncrypt("orderId", user.order)
              makeEncrypt("checkout", true)
              makeEncrypt("userId", user._id)
              if (user.order) {
                const order = await axios.get(`/user/order/${user.order}`)
                makeEncrypt("tableNo", order.data.tableNumber)
              }
              setValues({ ...values, firebaseOtp: false })
              dispatch(setCurrentUser(user))
              history.push(`/checkout/${makeDecrypt("restaurantId")}`)
            } catch (error) {
              setAlertMessage(error)
            }
          } else {
            let userData = data
            makeEncrypt("user", result.user.phoneNumber)
            makeEncrypt("checkout", true)
            makeEncrypt("userId", userData._id)
            dispatch(setCurrentUser(userData))
            setOrdersInLocalStorage(userData._id, userData.phone)
            setValues({ ...values, firebaseOtp: false })
          }
        } else {
          let user = data
          makeEncrypt("CustomerId", user.stripeDetails.customerId)
          makeEncrypt(
            "paymentMethodId",
            user.stripeDetails.paymentMethod.paymentMethodId
          )
          makeEncrypt("userId", user._id)
          let userData = data
          makeEncrypt("user", result.user.phoneNumber)
          makeEncrypt("userId", userData._id)
          dispatch(setCurrentUser(userData))
          history.push(`/rewards`)
        }
      })
      .catch((error) => {
        setAlertMessage(error.message)
        setShowOtpForm(false)
        setShowPhoneNoForm(false)
        setShowLoading(false)
      })
  }

  /**
   * adding item to user's cart and checking for user's authentication
   *
   */
  const handleBillClick = () => {
    setBtnLoading(true)
    if (restaurant.userAuthentication || makeDecrypt("tableNo") == "takeout") {
      if (!makeDecrypt("user")) {
        setBtnLoading(false)
        setValues({ ...values, firebaseOtp: true })
      } else setOrdersInLocalStorage()
    } else {
      if (!makeDecrypt("userId")) {
        handleUserCreation({ user: { phoneNumber: "" } }, true, true)
      } else setOrdersInLocalStorage()
    }
    ReactPixel.track("AddToCart", { info: "User adds item to cart" })
  }

  /**
   * getting already selected modifiers for particular menu item from user's cart
   *
   */

  const getModifiers = () => {
    let data = []
    currentModifiers.map((item) => {
      if (item.options.some((option) => option.selected === true)) {
        let tempSelectedOptions = []
        item.options.map((thisOption) => {
          if (thisOption.selected === true)
            tempSelectedOptions.push({
              modifierId: thisOption._id,
              modifierName: thisOption.modifierName,
              modifierPrice: thisOption.modifierPrice,
            })
        })
        data.push({
          selectedTypeId: item.type,
          selectedOptions: tempSelectedOptions,
        })
      }
    })
    setDataToSend(data)
  }

  const setOrdersInLocalStorage = (userId, phone) => {
    try {
      if (dataToSend.length && mandatoryModifiers.length) {
        let flag = false
        let count = 0
        for (const item of dataToSend) {
          if (
            mandatoryModifiers.some((thisItem) => thisItem === item.selectedTypeId)
          )
            count++
        }
        if (count === mandatoryModifiers.length) {
          addToTheCart(dataToSend)
        } else {
          setAlertMessage("select mandatory modifier")
          setBtnLoading(false)
        }
      } else if (dataToSend.length === 0 && mandatoryModifiers.length) {
        setAlertMessage("select mandatory modifier")
        setBtnLoading(false)
      } else {
        addToTheCart(dataToSend)
      }
    } catch (error) {
      setAlertMessage(error.message)
    }
  }

  const addToTheCart = async (dataToSend) => {
    await axios
      .post(
        `user/${makeDecrypt("userId")}/${makeDecrypt("restaurantId")}/${
          item._id
        }/add-to-cart`,
        {
          phone: makeDecrypt("user"),
          quantity: noOfItems,
          name: menuItm.name,
          price:
            (discountedPrice ? discountedPrice : menuItm.price) + modifiersPrice,
          specialInstructions: specialInstructions,
          modifiers: dataToSend.length ? dataToSend : undefined,
          pstPercentage: menuItm.pstApplicable && menuItm.pstPercentage,
        }
      )
      .then((res) => {
        setBtnLoading(false)
        setRefreshCart(true)
        setShowDishScreen(false)
        setViewBill(true)
      })
      .catch((error) => setAlertMessage(error.message))
  }

  /**
   * updating or removing the item from cart depending on the action type
   *
   */
  const handleAction = (action, addToBill) => {
    setBtnLoading(true)
    if (action === "remove") {
      removeCartItem(makeDecrypt("user"))
    }
    if (action === "update") {
      updateCart(makeDecrypt("user"))
    }
  }

  /**
   * updating particular item in the user's cart
   */
  const updateCart = async (phone) => {
    try {
      await axios
        .put(
          `/user/${makeDecrypt("userId")}/${makeDecrypt(
            "restaurantId"
          )}/${menuId}/update-cart`,
          {
            phone: makeDecrypt("user"),
            menuItemId: menuId,
            quantity: noOfItems,
            price:
              (discountedPrice ? discountedPrice : menuItm.price) + modifiersPrice,
            specialInstructions: specialInstructions,
            modifiers: dataToSend.length ? dataToSend : undefined,
            objectId: currentItem._id,
          }
        )
        .then((res) => {
          setBtnLoading(false)
          setShowUpdateScreen(false)
          // dispatch(refreshCheckout())
          setRefreshCart(true)
        })
        .catch((error) => {
          setAlertMessage(error.message)
          setBtnLoading(false)
          setShowUpdateScreen(false)
        })
    } catch (error) {
      setAlertMessage(alertMessage)
    }
  }

  /**
   * removing particular item from the user's cart
   */
  const removeCartItem = (phone) => {
    axios
      .put(
        `/user/${makeDecrypt("userId")}/${makeDecrypt(
          "restaurantId"
        )}/${menuId}/delete-cart-item`,
        {
          menuItemId: menuId,
          phone: makeDecrypt("user"),
          objectId: currentItem._id,
        }
      )
      .then((res) => {
        history.push(`/checkout/${makeDecrypt("restaurantId")}`)
        setRefreshCart(true)
      })
      .catch((error) => setAlertMessage(error.message))
  }

  /**
   * handling/keeping track of the selected modifiers
   */
  const handleRadioChange = (
    e,
    typeId,
    optionId,
    isMultiSelect,
    index,
    thisIndex
  ) => {
    let tempModifiers = currentModifiers
    tempModifiers.map((item, index) => {
      if (item._id === typeId) {
        item.options.map((option, newIndex) => {
          if (isMultiSelect === false) {
            if (option._id === optionId) {
              tempModifiers[index].options[newIndex] = {
                ...option,
                selected: true,
              }
            } else {
              tempModifiers[index].options[newIndex] = {
                ...option,
                selected: false,
              }
            }
          } else {
            if (option._id === optionId) {
              tempModifiers[index].options[newIndex] = {
                ...option,
                selected: e.target.checked,
              }
            }
          }
        })
      }
    })
    getModifiers()
  }

  const handleClickModal = () => {
    setOpen(false)
    setValues({ ...values, firebaseOtp: true })
  }

  const getTranslation = (original) => {
    setShowLanguages(false)
    setTranslating(true)

    axios
      .post("/menu/get-translation", {
        itemName: item.name,
        itemDesc: item.description,
        destLang: original ? "en" : makeDecrypt("primaryLang"),
        sourceLang: "en",
      })
      .then((response) => {
        setTranslatedName(response.data.itemName)
        setTranslatedDesc(response.data.itemDesc)
        setTranslating(false)
        setIsTranslated(true)
      })
      .catch((error) => {
        setTranslating(false)
      })
  }

  const selectLanguage = () => {
    setTranslating(true)
    if (makeDecrypt("primaryLang")) getTranslation()
    else setShowLanguages(true)
  }

  useEffect(() => {
    let temp = supportedLangs.find(
      (lang) => lang.languageCode == makeDecrypt("primaryLang")
    )
  }, [])

  useEffect(() => {
    if (menuItm?.discount)
      axios
        .get(`discount/get-discount/${menuItm?.discount}`)
        .then((data) => {
          let discount = data.data
          let today = moment(new Date()).format("dddd")
          var currentTime = moment().format("HH:mm")
          var startTime = new Date("01/01/2000 " + discount.startTime)
          var endTime = new Date("01/01/2000 " + discount.endTime)
          currentTime = new Date("01/01/2000 " + currentTime)
          if (
            new Date(discount.startDate) < new Date() < new Date(discount.endDate) &&
            discount.days.find((day) => day == today) &&
            startTime < currentTime < endTime
          ) {
            if (discount.percentOff) {
              let amountOff = (discount.percentOff / 100) * menuItm?.price
              setDiscountedPrice(menuItm?.price - amountOff)
            } else {
              setDiscountedPrice(menuItm?.price - discount.amountOff)
            }
          }
          setDiscountLoaded(true)
        })
        .catch((error) => {
          setDiscountLoaded(true)
        })
    else if (menuItm && !menuItm.discount) setDiscountLoaded(true)
  }, [menuItm])

  useEffect(() => {
    setPrimaryLang(makeDecrypt("primaryLang"))
  }, [])

  useEffect(() => {
    if (translatedItem == item?._id) getTranslation()
  }, [])

  return (
    <Box sx={{ pb: 9 }}>
      <Helmet>
        <title>Menu | {menuItm ? menuItm.name : ""}</title>
      </Helmet>
      {menuItm ? (
        <>
          <Container
            id="rootContainer"
            className={classes.root}
            flexdirection="column"
            justifycontent="space-between"
          >
            <Box
              className={classes.closeBox}
              onClick={() =>
                update ? setShowUpdateScreen(false) : setShowDishScreen(false)
              }
              onTouchStart={() =>
                update ? setShowUpdateScreen(false) : setShowDishScreen(false)
              }
            >
              <CloseIcon />
            </Box>
            <Grid container sx={{ px: 2 }}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.imgBox}>
                      <img
                        className={classes.dishImg}
                        src={
                          menuItm.imageUrl ? menuItm.imageUrl : restaurant.logoUrl
                        }
                        alt=""
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className={classes.paper}>
                      <Box>
                        <Typography
                          style={{
                            fontSize: "11px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span onClick={selectLanguage}>
                            {isTranslated
                              ? "Translated to "
                              : `Translate  ${primaryLang && "to "}`}
                          </span>
                          <span
                            onClick={() => setShowLanguages(true)}
                            style={{ fontWeight: "500", cursor: "pointer" }}
                          >
                            {supportedLangs.map(
                              (lang) => lang.languageCode == primaryLang && lang.name
                            )}
                          </span>
                          <span>
                            <ExpandMoreIcon
                              fontSize="medium"
                              style={{ cursor: "pointer" }}
                              onClick={() => setShowLanguages(true)}
                            />
                          </span>
                          {isTranslated ? (
                            <span
                              onClick={() => {
                                setTranslatedName(null)
                                setTranslatedDesc(null)
                                setPrimaryLang("en")
                              }}
                            >
                              (English)
                            </span>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Box>
                      <Box display={"flex"} alignItems="center">
                        {translating ? (
                          <Skeleton variant="text" width={100} />
                        ) : (
                          <Typography variant="h4">
                            {tranlatedName ? tranlatedName : menuItm.name}
                          </Typography>
                        )}
                      </Box>
                      <Box sx={{ mt: 0.5 }}>
                        {discountLoaded ? (
                          <Box display={"flex"} alignItems="center">
                            <Typography
                              variant="h5"
                              style={{ color: discountedPrice && "#df2800" }}
                            >
                              {priceRange.maxPrice
                                ? `${currencySign}` +
                                  priceRange.minPrice +
                                  ` - ${currencySign}` +
                                  priceRange.maxPrice
                                : `${currencySign}` +
                                  ((discountedPrice
                                    ? discountedPrice
                                    : menuItm.price) +
                                    modifiersPrice)}
                            </Typography>
                            {discountedPrice && (
                              <Typography
                                style={{
                                  fontWeight: "400",
                                  textDecoration: " line-through",
                                  marginLeft: "7px",
                                  textDecorationThickness: "1.5px",
                                }}
                              >
                                {priceRange.maxPrice
                                  ? `${currencySign}` +
                                    priceRange.minPrice +
                                    ` - ${currencySign}` +
                                    priceRange.maxPrice
                                  : `${currencySign}` +
                                    (menuItm.price + modifiersPrice)}
                              </Typography>
                            )}
                          </Box>
                        ) : (
                          <Skeleton type="text" width={80} />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ my: 1 }}>
              {translating ? (
                <Skeleton variant="text" width={"100%"} />
              ) : (
                <ShowMoreText
                  lines={3}
                  more="Show more"
                  less="Show less"
                  className={classes.description}
                  anchorClass={classes.showMore}
                  expanded={false}
                  width={280}
                  truncatedEndingComponent={"... "}
                >
                  <Typography variant="body2" className={classes.description}>
                    {tranlatedDesc ? tranlatedDesc : menuItm.description}
                  </Typography>
                </ShowMoreText>
              )}
            </Box>

            <Divider />

            {restaurantOpen && restaurant.contactlessOrdering && !menuonly && (
              <Box>
                {/* Changed item to eachModifier */}
                {menuItm.modifiers.map((eachModifier, index) => {
                  return (
                    <>
                      {/* <Accordion square elevation={1} key={index}>
                        <AccordionSummary
                          expandIcon={<AddIcon />}
                          align="center"
                          className={classes.accordionSummary}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography component="p" className={classes.typo}>
                            {eachModifier.type}{" "}
                            {eachModifier.isMandatory ? (
                              <span style={{ color: "red" }}>*</span>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box width="100%">
                            {eachModifier.options.map((option, thisIndex) => {
                              return (
                                <Box
                                  className={classes.addition}
                                  key={option._id}
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography>
                                    {option.modifierName}
                                    {option.modifierPrice != 0
                                      ? `(Price: ${currencySign}${option.modifierPrice})`
                                      : ""}
                                  </Typography>
                                  <Box display="flex" alignItems="center">
                                    {!update &&
                                      (eachModifier.multiSelect ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            handleRadioChange(
                                              e,
                                              eachModifier._id,
                                              option._id,
                                              eachModifier.multiSelect,
                                              index,
                                              thisIndex
                                            )
                                          }
                                        />
                                      ) : (
                                        <input
                                          onChange={(e) =>
                                            handleRadioChange(
                                              e,
                                              eachModifier._id,
                                              option._id,
                                              eachModifier.multiSelect,
                                              index,
                                              thisIndex
                                            )
                                          }
                                          type="radio"
                                          name={eachModifier.type}
                                        />
                                      ))}

                                    {update &&
                                      (eachModifier.multiSelect ? (
                                        <input
                                          type="checkbox"
                                          checked={allSelectedModifiers.some(
                                            (id) => id === option._id
                                          )}
                                          onChange={(e) =>
                                            handleRadioChange(
                                              e,
                                              eachModifier._id,
                                              option._id,
                                              eachModifier.multiSelect,
                                              index,
                                              thisIndex
                                            )
                                          }
                                        />
                                      ) : (
                                        <input
                                          checked={allSelectedModifiers.some(
                                            (id) => id === option._id
                                          )}
                                          onChange={(e) =>
                                            handleRadioChange(
                                              e,
                                              eachModifier._id,
                                              option._id,
                                              eachModifier.multiSelect,
                                              index,
                                              thisIndex
                                            )
                                          }
                                          type="radio"
                                          name={eachModifier.type}
                                        />
                                      ))}
                                  </Box>
                                </Box>
                              )
                            })}
                          </Box>
                        </AccordionDetails>
                      </Accordion> */}
                      <Box className={classes.modifierBox}>
                        <Box className={classes.modifierHeadingBox}>
                          <Box>
                            <Typography
                              variant="h4"
                              className={classes.modifierHeading}
                            >
                              {eachModifier.type}
                            </Typography>
                          </Box>
                          {eachModifier.isMandatory ? (
                            <Box className={classes.requiredBox}>
                              <Typography
                                variant="caption"
                                style={{ lineHeight: 1.1 }}
                              >
                                REQUIRED
                              </Typography>
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Box width="100%" className={classes.modifierOptionsBox}>
                          {eachModifier.options.map((option, thisIndex) => {
                            return (
                              <Box
                                className={classes.addition}
                                key={option._id}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography>{option.modifierName}</Typography>
                                <Box display="flex" alignItems="center">
                                  <Box sx={{ mr: 1 }}>
                                    <Typography variant="body1">
                                      {option.modifierPrice != 0
                                        ? `${currencySign}${option.modifierPrice}`
                                        : ""}
                                    </Typography>
                                  </Box>
                                  {!update &&
                                    (eachModifier.multiSelect ? (
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleRadioChange(
                                            e,
                                            eachModifier._id,
                                            option._id,
                                            eachModifier.multiSelect,
                                            index,
                                            thisIndex
                                          )
                                        }
                                        className={classes.input}
                                      />
                                    ) : (
                                      <input
                                        onChange={(e) =>
                                          handleRadioChange(
                                            e,
                                            eachModifier._id,
                                            option._id,
                                            eachModifier.multiSelect,
                                            index,
                                            thisIndex
                                          )
                                        }
                                        className={classes.input}
                                        type="radio"
                                        name={eachModifier.type}
                                      />
                                    ))}

                                  {update &&
                                    (eachModifier.multiSelect ? (
                                      <input
                                        type="checkbox"
                                        checked={allSelectedModifiers.some(
                                          (id) => id === option._id
                                        )}
                                        onChange={(e) =>
                                          handleRadioChange(
                                            e,
                                            eachModifier._id,
                                            option._id,
                                            eachModifier.multiSelect,
                                            index,
                                            thisIndex
                                          )
                                        }
                                        className={classes.input}
                                      />
                                    ) : (
                                      <input
                                        checked={allSelectedModifiers.some(
                                          (id) => id === option._id
                                        )}
                                        onChange={(e) =>
                                          handleRadioChange(
                                            e,
                                            eachModifier._id,
                                            option._id,
                                            eachModifier.multiSelect,
                                            index,
                                            thisIndex
                                          )
                                        }
                                        className={classes.input}
                                        type="radio"
                                        name={eachModifier.type}
                                      />
                                    ))}
                                </Box>
                              </Box>
                            )
                          })}
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  )
                })}

                <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.inputField}
                    placeholder="Special Instruction"
                    value={specialInstructions}
                    onChange={(e) =>
                      setValues({ ...values, specialInstructions: e.target.value })
                    }
                    InputProps={{
                      className: classes.inputBox,
                    }}
                  />
                </Box>
                {quantityOver && (
                  <Box>
                    <Typography>
                      Only {noOfItems} {noOfItems === 1 ? "item" : "items"} are
                      available
                    </Typography>
                  </Box>
                )}
                <Grid container spacing={1} className={classes.btnWrap}>
                  <Grid id="bottomCounter" item xs={4}>
                    <Box className={classes.bottomCounter}>
                      <Box className={classes.counterItems}>
                        <RemoveIcon
                          onClick={() =>
                            setValues({
                              ...values,
                              noOfItems: noOfItems > 1 ? noOfItems - 1 : noOfItems,
                            })
                          }
                        />
                      </Box>
                      <Box className={classes.counterItems}>
                        <Typography
                          style={{
                            padding: "0 5px",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                          variant="h5"
                        >
                          {noOfItems}
                        </Typography>
                      </Box>
                      <Box className={classes.counterItems}>
                        <AddIcon
                          onClick={() =>
                            setValues({ ...values, noOfItems: noOfItems + 1 })
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      className={classes.btnWrapper}
                      sx={{ position: "relative" }}
                    >
                      {!update ? (
                        <Button
                          id="billBtn"
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          onClick={handleBillClick}
                          disabled={btnLoading}
                        >
                          <Typography variant="h4" style={{ textTransform: "none" }}>
                            {" "}
                            Add item {currencySign}
                            {(discountedPrice ? discountedPrice : menuItm.price) +
                              modifiersPrice}
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          onClick={() => handleAction("update")}
                          disabled={btnLoading}
                        >
                          Update
                        </Button>
                      )}

                      {btnLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Dialog
              style={{ textAlign: "center", alignItems: "center" }}
              onClose={() => setOpen(false)}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpen(false)}
              >
                Phone Number
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  We use your phone number to create an account & securely save your
                  card information. This information is never shared with third
                  parties or to serve advertisements. We take your privacy concerns
                  very seriously.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  style={{ margin: "auto" }}
                  onClick={handleClickModal}
                  color="black"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            {firebaseOtp && (
              <Login
                setOrdersInLocalStorage={setOrdersInLocalStorage}
                dishScreen={true}
                values={values}
                setValues={setValues}
                showPhoneNoForm={showPhoneNoForm}
                showOtpForm={showOtpForm}
                showLoading={showLoading}
                setShowPhoneNoForm={setShowPhoneNoForm}
                setShowOtpForm={setShowOtpForm}
                setShowLoading={setShowLoading}
                handleUserCreation={handleUserCreation}
              />
            )}
            <AlertMessage alertMessage={alertMessage} />
          </Container>
        </>
      ) : !alertMessage ? (
        <Loader />
      ) : (
        <Typography>No Menu Item Available</Typography>
      )}

      <Dialog
        open={showLanguages}
        onClose={() => setShowLanguages(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Select Language</DialogTitle>

        <DialogContent>
          <List style={{ cursor: "pointer" }}>
            {supportedLangs.map((language) => {
              return (
                <ListItem
                  onClick={() => {
                    makeEncrypt("primaryLang", language.languageCode)
                    setPrimaryLang(language.languageCode)
                    getTranslation()
                    setTranslating(true)
                  }}
                  key={language.name}
                >
                  <ListItemText primary={language.name} />
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default DishScreen
