import axios from "../axios"
import { makeDecrypt } from "../utils/securels"

export const setHighlightedCoupon = (restId) => async (dispatch) => {
  try {
    axios
      .get("/stripe/getPromocodes")
      .then((res) => {
        // console.log(res);
        let activePromoCodes = res.data.data.filter(
          (eachPromoCode) => eachPromoCode.active
        )

        let highlightedPromo = activePromoCodes.find(
          (item) =>
            item.metadata.highlight === "true" &&
            item.metadata.restaurantId === makeDecrypt("restaurantId")
        )
        dispatch({
          type: "SET_PROMOS",
          payload: activePromoCodes,
        })
        dispatch({
          type: "SET_HIGHLIGHTED_PROMO",
          payload: highlightedPromo ? highlightedPromo : null,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}
