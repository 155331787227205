import { Box, Typography } from "@material-ui/core"
import React from "react"

function PciBox() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        margin: "10px 0",
        padding: "5px",
      }}
    >
      <img
        src="/pci.png"
        height="40px"
        width="110px"
        style={{ margin: "auto 5px" }}
      />
      <Typography variant="body2">
        {/* We are PCI DSS Certified. */}Click{" "}
        <a
          href="https://www.pcisecuritystandards.org/"
          target="_blank"
          style={{ color: "red" }}
        >
          here{" "}
        </a>
        to know more.{" "}
      </Typography>
    </Box>
  )
}

export default PciBox
