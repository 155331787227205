import {
  AppBar,
  Box,
  CircularProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import SwipeableViews from "react-swipeable-views"
import { setRestaurant } from "../actions/restaurantActions"
import CouponHighlighter from "../components/CouponHighlighter"
import { getTotolOrders, getUser } from "../components/rewards"
import AchievedRewards from "../components/rewards/AchievedRewards"
import Login from "../components/rewards/Login"
import Referral from "../components/rewards/Referral"
import UpcomingRewards from "../components/rewards/UpcomingRewards"
import TopBar from "../components/TopBar"
import { makeDecrypt } from "../utils/securels"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginTop: "80px",
  },
  topBar: {
    position: "fixed",
    top: 0,
    backgroundColor: "white",
    width: "100%",
    zIndex: "100000",
    paddingBottom: "10px",
  },
  loaderBox: {
    position: "fixed",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
  },
}))

function Rewards() {
  const history = useHistory()
  const classes = useStyles()
  const [user, setUser] = useState("")
  const [name, setName] = useState("")
  const [totalOrders, setTotalOrders] = useState(0)
  const [eligible, setEligible] = useState(false)
  const [value, setValue] = React.useState(0)
  const [loginOpen, setLoginOpen] = useState(true)
  const { restaurant } = useSelector((state) => state.restaurant)
  const dispatch = useDispatch()
  const direction = "rtl"

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  useEffect(() => {
    getUser()
      .then((user) => {
        setUser(user)
        if (!restaurant) dispatch(setRestaurant(makeDecrypt("restaurantId")))
      })
      .catch((error) => {
        console.log(error)
      })

    getTotolOrders()
      .then((noOfOrders) => {
        setTotalOrders(noOfOrders)
        if (noOfOrders >= 5) setEligible(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [loginOpen])

  useEffect(() => {
    !localStorage.getItem("user") && setLoginOpen(true)
  }, [localStorage.getItem("user")])

  return (
    <div>
      <CouponHighlighter />
      <div className={classes.topBar}>
        <TopBar />
      </div>
      {!loginOpen ? (
        <Box className={classes.root}>
          {totalOrders ? (
            eligible ? (
              <Box>
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Achieved" {...a11yProps(0)} />
                    <Tab label="Upcoming" {...a11yProps(1)} />
                    <Tab label="Referral" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  axis={direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={direction}>
                    <Referral user={user} />
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={direction}>
                    <AchievedRewards />
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={direction}>
                    <UpcomingRewards totalOrders={totalOrders} />
                  </TabPanel>
                </SwipeableViews>
              </Box>
            ) : (
              <Box>You are not eligible for rewards</Box>
            )
          ) : (
            <Box className={classes.loaderBox}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      ) : (
        <Login setLoginOpen={setLoginOpen} />
      )}
    </div>
  )
}

export default Rewards
