import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import { useSelector } from "react-redux"
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: "45px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99",
    textAlign: "center",
    padding: "5px",
  },
}))

function CouponHighlighter() {
  const classes = useStyles()
  const { highlightedPromo } = useSelector((state) => state.coupon)
  const { currencySign } = useSelector((state) => state.restaurant)

  return (
    <>
      {highlightedPromo?.code && (
        <Box className={classes.root}>
          One time offer | Use Coupon : {highlightedPromo?.code} | Minimum
          {currencySign}
          {(highlightedPromo?.restrictions?.minimum_amount / 100).toFixed(2)} order
        </Box>
      )}
    </>
  )
}

export default CouponHighlighter
