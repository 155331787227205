import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import "firebase/auth"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { setRestaurant } from "../../actions/restaurantActions"
import { setCurrentUser } from "../../actions/userActions"
import axiosInstance from "../../axios"
import firebase from "../../firebase"
import { makeDecrypt, makeEncrypt } from "../../utils/securels"
import { createUser } from "./index"

const useStyles = makeStyles((theme) => ({
  topBar: {},
  root: {
    backgroundColor: "white",
    padding: "20px 5px",
  },
  otpWarning: {
    textAlign: "center",
    width: "90%",
    margin: "auto",
    marginBottom: "30px",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    height: "53px",
    width: "100%",
  },
  backdrop: {
    zIndex: "100000",
  },
  refBox: {
    textAlign: "center",
  },
  btnBox: {
    display: "flex",
    width: "fit-content",
    margin: "20px 0px 10px auto",
  },
  closeBox: {
    position: "absolute",
    top: "10px",
    right: "10px",
    boxShadow:
      " rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;",
    borderRadius: "50%",
  },
}))

function Login({
  setOrdersInLocalStorage,
  dishScreen,
  values,
  setValues,
  setLoginOpen,
  showPhoneNoForm,
  showOtpForm,
  showLoading,
  setShowPhoneNoForm,
  setShowOtpForm,
  setShowLoading,
  handleUserCreation,
}) {
  const classes = useStyles()
  const [phoneNo, setPhoneNo] = useState("")
  const [otp, setOtp] = useState("")
  const [alertMessage, setAlertMessage] = useState()
  const [refScreenOpen, setRefScrrenOpen] = useState(true)
  const [refCode, setRefCode] = useState()
  const dispatch = useDispatch()
  const history = useHistory()

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          handleSignInSubmit()
        },
        defaultCountry: "CA",
      }
    )
  }

  const handleSignInSubmit = (e) => {
    setShowLoading(true)
    e.preventDefault()
    configureCaptcha()
    const phoneNumber = "+1" + phoneNo
    const appVerifier = window.recaptchaVerifier
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult
        setShowPhoneNoForm(false)
        setShowOtpForm(true)
        setShowLoading(false)
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...

        setShowLoading(false)
      })
  }

  const handleSubmitOtp = (e) => {
    setShowLoading(true)
    e.preventDefault()
    const code = otp
    console.log(code)
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        console.log(result)
        // User signed in successfully.
        createUser(result.user.phoneNumber)
          .then(async (data) => {
            let user = data
            let items = data.cart.items
            console.log("items")
            console.log(items)
            if (dishScreen) {
              if (data.cart.items.length) {
                try {
                  let menuItem = await axiosInstance.get(
                    `/menu/${items[0].itemId}/menu-item-detail`
                  )
                  console.log(menuItem)
                  console.log(menuItem.data)
                  dispatch(setRestaurant(menuItem.data.restaurant))
                  makeEncrypt("user", user.phone)
                  makeEncrypt("restaurantId", menuItem.data.restaurant)
                  makeEncrypt("CustomerId", user.stripeDetails.customerId)
                  makeEncrypt(
                    "paymentMethodId",
                    user.stripeDetails.paymentMethod.paymentMethodId
                  )
                  makeEncrypt(
                    "paymentIntentId",
                    user.stripeDetails.paymentMethod.paymentIntentId
                  )
                  makeEncrypt("orderId", user.order)
                  makeEncrypt("checkout", true)
                  makeEncrypt("userId", user._id)
                  if (user.order) {
                    const order = await axiosInstance.get(
                      `/user/order/${user.order}`
                    )
                    makeEncrypt("tableNo", order.data.tableNumber)
                  }
                  setValues({ ...values, firebaseOtp: false })
                  dispatch(setCurrentUser(user))
                  history.push(`/checkout/${makeDecrypt("restaurantId")}`)
                } catch (error) {
                  console.log(error)
                  setAlertMessage(error)
                }
              } else {
                let userData = data
                makeEncrypt("user", result.user.phoneNumber)
                makeEncrypt("checkout", true)
                setValues({ ...values, firebaseOtp: false })
                makeEncrypt("userId", userData._id)
                dispatch(setCurrentUser(userData))
                setOrdersInLocalStorage(userData._id, userData.phone)
              }
            } else {
              let user = data
              makeEncrypt("CustomerId", user.stripeDetails.customerId)
              makeEncrypt(
                "paymentMethodId",
                user.stripeDetails.paymentMethod.paymentMethodId
              )
              makeEncrypt("userId", user._id)
              let userData = data
              makeEncrypt("user", result.user.phoneNumber)
              makeEncrypt("userId", userData._id)
              dispatch(setCurrentUser(userData))
              setShowLoading(false)
              if (user.refScreen) {
                setRefScrrenOpen(true)
              } else {
                setLoginOpen(false)
              }
            }
          })
          .catch((error) => {
            console.log(error)
            setAlertMessage(error.message)
            setShowOtpForm(false)
            setShowPhoneNoForm(false)
            setShowLoading(false)
          })
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        console.log(error)
        setShowLoading(false)
      })
  }

  const addReference = () => {
    axiosInstance
      .get(`user/add-reference/${makeDecrypt("userId")}/${refCode}`)
      .then((data) => {})
      .catch((error) => {})
  }

  return (
    <Dialog open={true}>
      <Typography
        variant="h5"
        style={{
          textAlign: "center",
          marginTop: "10px",
          fontWeight: 600,
          color: "#DF2800",
        }}
      >
        DINIFY
      </Typography>
      <Typography variant="body2" style={{ textAlign: "center" }}>
        New era of dining!
      </Typography>
      <Box
        onClick={() => setValues({ ...values, firebaseOtp: false })}
        className={classes.closeBox}
      >
        <CloseIcon />
      </Box>
      <Divider style={{ marginTop: "10px" }} />
      <DialogContent>
        <Box className={[classes.root, "root"]}>
          {showPhoneNoForm ? (
            <Box>
              <form
                onSubmit={(e) => {
                  handleSignInSubmit(e)
                }}
                id="phoneBox"
              >
                <div id="sign-in-button"></div>
                <Typography className={classes.otpWarning}>
                  An OTP will be sent to your mobile number for verification
                </Typography>
                <TextField
                  value={phoneNo}
                  name="phoneNo"
                  variant="outlined"
                  label="Enter Phone Number"
                  fullWidth
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!phoneNo}
                  style={{ marginTop: "20px" }}
                >
                  Get OTP
                </Button>
              </form>
            </Box>
          ) : showOtpForm ? (
            <Box>
              <form id="otpBox" onSubmit={(e) => handleSubmitOtp(e)}>
                <Typography className={classes.otpWarning}>
                  Please enter the OTP sent on your phone number.
                </Typography>
                <TextField
                  value={otp}
                  name="otp"
                  variant="outlined"
                  label="Enter OTP Code"
                  fullWidth
                  style={{ marginTop: "20px" }}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!otp}
                  style={{ marginTop: "20px" }}
                >
                  Submit
                </Button>
              </form>
            </Box>
          ) : (
            <CircularProgress />
          )}
        </Box>
        <Backdrop className={classes.backdrop} open={showLoading}>
          <CircularProgress style={{ color: "black" }} />
        </Backdrop>
        <Typography style={{ textAlign: "center" }} variant="body2">
          Your information is never shared with third parties.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default Login
