import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import CreateIcon from "@material-ui/icons/Create"
import DeleteIcon from "@material-ui/icons/Delete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Rating, Skeleton } from "@material-ui/lab"
import moment from "moment"
import React, { useContext, useEffect, useRef, useState } from "react"
import Circle from "react-circle"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { default as axios, default as axiosInstance } from "../axios"
import { MyContext } from "../context"
import UpdateItem from "../screens/UpdateItem"
import { supportedLangs } from "../utils/Data.js"
import { makeDecrypt, makeEncrypt } from "../utils/securels"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    minHeight: "60px",
    margin: `10px 0`,
    padding: "7px 10px",
    // boxShadow:
    //   "rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",

    borderRadius: "7px",
    overflow: "hidden",
    "&:hover": {
      boxShadow: "0 5px 10px rgba(0,0,0,0.16), 0 5px 5px rgba(0,0,0,0.22)",
    },
    "&::before": {
      content: (currentMenuItemStatus) => {
        if (currentMenuItemStatus == "Offline") return '""'
        else return ""
      },
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  notAvailable: {
    // position: "absolute",
    // left: "50%",
    // top: "50%",
    // transform: "translate(-50%, -50%)",
    // color: "white",
  },
  details: {
    // display: "flex",
    // flexDirection: "column",
    // width: "70%",
    textAlign: "left",
    width: "100%",

    // position: "relative",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    // flex: "1 0 auto",
    // padding: "0px !important",
    // padding: "0px 10px",
  },
  img: {
    borderRadius: "7px",
    objectFit: "cover",
  },
  feedbackBtn: {
    // position: "absolute",
    // bottom: "10px",
    // right: "10px",
    fontSize: "10px",
  },
  popularityCont: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    // marginLeft: "auto",
    position: "absolute",
    bottom: "-8px",
    right: "10px",
  },
  iconBox: {
    border: "1px solid black",
    borderRadius: "2px",
  },
  icons: {
    boxShadow:
      " rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "15px",
    cursor: "pointer",
  },
  description: {
    fontSize: "12px",
  },
  addBtn: {
    position: "absolute",
    bottom: "-10px",
    backgroundColor: "#FFEEEB",
    border: "1px solid #df2800",
    borderRadius: "6px",
    padding: "0 10px",
    cursor: "pointer",
    boxShadow:
      " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    "&.MuiChip-root": {
      height: "17px",
    },
  },
  requiredBox: {
    border: "1px solid #df2800",
    color: "#df2800",
    borderRadius: "4px",
    padding: "1px 3px",
    lineHeight: 0.5,
    marginRight: "5px",
  },
  discountRibbon: {
    width: "85px ",
    height: "20px",
    backgroundColor: "#df2800",
    zIndex: "999",
    position: "absolute",
    top: "11px",
    right: "-20px",
    color: "white",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    "&::-webkit-transform": "rotate(45deg)",
    "&::-moz-transform": "rotate(45deg)",
    "&::-ms-transform": "rotate(45deg)",
    "&::-o-transform": "rotate(45deg)",
    transform: "rotate(45deg)",
  },
}))

export default function MenuItem({
  menuItem,
  isCheckout,
  setSnackOpen,
  setStatus,
  showFeedback,
  categoryId,
  showPopularity,
  tempSocket,
  setShowDishScreen,
  setActiveItem,
  cartItems,
  showCounter,
  orderedQuantity,
  primaryLang,
  setPrimaryLang,
  setTranslatedItem,
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const socket = useRef()
  const [currentMenuItemStatus, setCurrentMenuItemStatus] = useState("")
  const [currentMenuItemId, setCurrentMenuItemId] = useState("")
  const classes = useStyles({ currentMenuItemStatus })
  const [open, setOpen] = useState(false)
  const [stars, setStars] = useState(0)
  const [feedBackMsg, setFeedbackMsg] = useState("")
  const [popularity, setPopularity] = useState()
  const [itemQty, setItemQty] = useState(orderedQuantity)
  const [loading, setLoading] = useState(false)
  const { restaurant, currencySign } = useSelector((state) => state.restaurant)
  const [showUpdateScreen, setShowUpdateScreen] = useState()
  const [tranlatedName, setTranslatedName] = useState()
  const [tranlatedDesc, setTranslatedDesc] = useState()
  const [showLanguages, setShowLanguages] = useState(false)
  const [translating, setTranslating] = useState()
  const [isTranslated, setIsTranslated] = useState()
  const [priceRange, setPriceRange] = useState({
    minPrice: "",
    maxPrice: "",
  })
  const [discountedPrice, setDiscountedPrice] = useState()
  const [discountLoaded, setDiscountLoaded] = useState()
  const [discount, setDiscount] = useState()
  const { refreshCart, setRefreshCart, restaurantOpen } = useContext(MyContext)

  const menuonly = window.location.search.split("menuonly=")[1]

  let componentMounted = true
  useEffect(() => {
    if (menuItem) {
      if (menuItem.price === 0 && menuItem.modifiers?.length) {
        let tempPrices = []
        for (const item of menuItem.modifiers) {
          if (item.options.length) {
            for (const thisOption of item.options) {
              tempPrices.push(thisOption.modifierPrice)
            }
          }
        }
        setPriceRange({
          minPrice: Math.min(...tempPrices),
          maxPrice: Math.max(...tempPrices),
        })
      }
    }
  }, [menuItem])

  useEffect(() => {
    setCurrentMenuItemStatus(menuItem.status)
    setCurrentMenuItemId(menuItem._id)
    tempSocket.current.on("menuStatusChange", (data) => {
      if (data.menuId === menuItem._id) {
        setCurrentMenuItemStatus(data.status)
        setCurrentMenuItemId(data.menuId)
      }
    })
    tempSocket.current.on("changeSameCategoryItemStatus", (data) => {
      if (
        data.categoryName === menuItem.category &&
        data.restaurantId === makeDecrypt("restaurantId")
      ) {
        setCurrentMenuItemStatus(data.status)
      }
    })
    tempSocket.current.on("changeMainMenuStatus", (data) => {
      if (
        data.categories.some((cate) => cate == categoryId) &&
        data.restaurantId === makeDecrypt("restaurantId")
      ) {
        setCurrentMenuItemStatus(data.status)
      }
    })
    return () => {
      componentMounted = false
    }
  }, [])
  useEffect(() => {
    orderedQuantity && setItemQty(orderedQuantity)
  }, [orderedQuantity])

  const handleClick = () => {
    if (!menuItem.isOrdered) {
      // No need to check for the current menu item status
      // because once it added in cart we have give option to edit even
      // it get offline after that
      // if (currentMenuItemStatus === "Online") performAction()
      performAction()
    }
  }

  const handleShowLanguages = (e) => {
    e.stopPropagation()
    setShowLanguages(true)
  }

  const handleTiktok = (e) => {
    e.stopPropagation()
    window.location.href = menuItem.tiktokVideoUrl
  }

  const handleInsta = (e) => {
    e.stopPropagation()
    window.location.href = menuItem.instagramVideoUrl
  }

  const handleFb = (e) => {
    e.stopPropagation()
    window.location.href = menuItem.facebookVideoUrl
  }

  const performAction = () => {
    if (isCheckout) {
      setShowUpdateScreen(true)
    } else {
      setActiveItem(menuItem)
      setShowDishScreen(true)
    }
  }

  const giveFeedback = () => {
    setOpen(false)
    axiosInstance
      .post(
        `/user/${makeDecrypt("restaurantId")}/${makeDecrypt(
          "userId"
        )}/give-feedback`,
        {
          item: menuItem._id,
          stars,
          feedBackMsg,
          type: "menu-item",
        }
      )
      .then((response) => {
        setStatus("Thanks for valuable Feedback!")
        setSnackOpen(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const removeCartItem = (phone) => {
    setLoading(true)
    axios
      .put(
        `/user/${makeDecrypt("userId")}/${makeDecrypt("restaurantId")}/${
          menuItem._id
        }/delete-cart-item`,
        {
          menuItemId: menuItem._id,
          phone: makeDecrypt("user"),
          objectId: menuItem.objectId,
        }
      )
      .then((res) => {
        setRefreshCart(true)
        dispatch({ type: "REFRESH" })
        if (res.data.message) setLoading(false)
        // isCheckout
        //   ? window.location.reload()
        //   : history.push(`/checkout/${makeDecrypt("restaurantId")}`)
      })
      .catch((error) => console.log(error.message))
  }

  const getTranslation = (original) => {
    setShowLanguages(false)
    setTranslating(true)
    if (!original) setTranslatedItem(menuItem._id)
    axios
      .post("/menu/get-translation", {
        itemName: menuItem.name,
        itemDesc: menuItem.description,
        destLang: original ? "en" : makeDecrypt("primaryLang"),
        sourceLang: "en",
      })
      .then((response) => {
        setTranslatedName(response.data.itemName)
        setTranslatedDesc(response.data.itemDesc)
        setTranslating(false)
        setIsTranslated(true)
      })
      .catch((error) => {
        setTranslating(false)
      })
  }

  const selectLanguage = () => {
    setTranslating(true)
    if (makeDecrypt("primaryLang")) getTranslation()
    else setShowLanguages(true)
  }

  useEffect(() => {
    if (menuItem.discount)
      axios
        .get(`discount/get-discount/${menuItem.discount}`)
        .then((data) => {
          let discount = data.data
          let today = moment(new Date()).format("dddd")
          var currentTime = moment().format("HH:mm")
          var startTime = new Date("01/01/2000 " + discount.startTime)
          var endTime = new Date("01/01/2000 " + discount.endTime)
          currentTime = new Date("01/01/2000 " + currentTime)
          if (
            new Date(discount.startDate).getTime() <= new Date().getTime() &&
            new Date().getTime() <= new Date(discount.endDate).getTime() &&
            discount.days.find((day) => day == today) &&
            startTime < currentTime < endTime
          ) {
            if (discount.percentOff) {
              let amountOff = (discount.percentOff / 100) * menuItem?.price
              setDiscountedPrice(menuItem?.price - amountOff)
            } else {
              setDiscountedPrice(menuItem?.price - discount.amountOff)
            }
            setDiscount(discount)
          }
          setDiscountLoaded(true)
        })
        .catch((error) => {
          setDiscountLoaded(true)
        })
    else if (menuItem && !menuItem.discount) setDiscountLoaded(true)
  }, [])

  return (
    <Box
      className={classes.root}
      sx={{ boxShadow: 2 }}
      style={{
        backgroundColor: menuItem.isOrdered && "#FAF3F3",
      }}
    >
      {!loading ? (
        <Grid className="viewmenubutton" container spacing={1}>
          {discount && (
            <Box className={classes.discountRibbon}>
              <Typography variant="caption" style={{ fontSize: "11px" }}>
                {discount?.amountOff
                  ? `$${discount?.amountOff}`
                  : `${discount?.percentOff}%`}{" "}
                OFF
              </Typography>
            </Box>
          )}
          <Grid item xs={isCheckout ? 9 : 12}>
            <Grid container onClick={handleClick}>
              <Grid
                item
                xs={8}
                className={classes.details}
                fontWeight="fontWeightBold"
              >
                <Grid className={classes.content}>
                  <Grid item>
                    <Box>
                      {!isCheckout && (
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleShowLanguages(e)}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span onClick={selectLanguage}>
                              {isTranslated
                                ? "Translated to "
                                : `Translate  ${primaryLang && "to "}`}
                            </span>
                            <span
                              onClick={() => setShowLanguages(true)}
                              style={{ fontWeight: "500", cursor: "pointer" }}
                            >
                              {supportedLangs.map(
                                (lang) =>
                                  lang.languageCode == primaryLang && lang.name
                              )}
                            </span>
                            <span>
                              <ExpandMoreIcon
                                fontSize="medium"
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                            {isTranslated ? (
                              <span
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setTranslatedName(null)
                                  setTranslatedDesc(null)
                                  setPrimaryLang("en")
                                }}
                              >
                                (English)
                              </span>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </Box>
                      )}

                      {!isCheckout && menuItem.tags.length ? (
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            overflowX: "auto",
                            maxWidth: "180px",
                            marginTop: "5px",
                          }}
                        >
                          {menuItem.tags.map((tag, index) => {
                            return (
                              <Box className={classes.requiredBox}>
                                <Typography
                                  variant="caption"
                                  style={{ lineHeight: 1.1 }}
                                >
                                  {tag}
                                </Typography>
                              </Box>
                            )
                          })}
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box sx={{ mt: 0.3 }}>
                        {translating ? (
                          <Skeleton variant="text" />
                        ) : (
                          <Typography
                            variant="h6"
                            style={{
                              color:
                                currentMenuItemStatus === "Offline" &&
                                currentMenuItemId === menuItem._id &&
                                "gray",
                            }}
                            onClick={handleClick}
                          >
                            {tranlatedName
                              ? tranlatedName.length > 25
                                ? tranlatedName.slice(0, 25) + "..."
                                : tranlatedName
                              : menuItem.name.length > 25
                              ? menuItem.name.slice(0, 25) + "..."
                              : menuItem.name}
                            {menuItem.tiktokVideoUrl ? (
                              <img
                                src="/tiktok_transparent.png"
                                height="20px"
                                width="20px"
                                style={{ marginLeft: "3px" }}
                                onClick={handleTiktok}
                              ></img>
                            ) : null}
                            {menuItem.instagramVideoUrl ? (
                              <img
                                src="/insta_transparent.png"
                                height="20px"
                                width="20px"
                                style={{ marginLeft: "3px" }}
                                onClick={handleInsta}
                              ></img>
                            ) : null}
                            {menuItem.facebookVideoUrl ? (
                              <img
                                src="/fb_transparent.png"
                                height="20px"
                                width="20px"
                                style={{ marginLeft: "3px" }}
                                onClick={handleFb}
                              ></img>
                            ) : null}
                          </Typography>
                        )}
                      </Box>
                      {discountLoaded ? (
                        <Box display={"flex"} alignItems="center">
                          <Typography
                            variant="h6"
                            style={{
                              color: discountedPrice && "#df2800",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                            onClick={handleClick}
                          >
                            <span style={{ marginRight: "1px" }}>
                              {currencySign}
                            </span>
                            {priceRange.maxPrice
                              ? priceRange.minPrice +
                                ` - ${currencySign} ` +
                                priceRange.maxPrice
                              : discountedPrice
                              ? discountedPrice
                              : menuItem.price}
                            {!isCheckout && menuItem?.modifiers?.length ? "+" : ""}
                          </Typography>
                          {discountedPrice && (
                            <Typography
                              variant="h6"
                              style={{
                                fontSize: "13px",
                                fontWeight: "400",
                                textDecoration: " line-through",
                                marginLeft: "7px",
                                textDecorationThickness: "1.5px",
                              }}
                              onClick={handleClick}
                            >
                              <span style={{}}>{currencySign}</span>
                              {priceRange.maxPrice
                                ? priceRange.minPrice +
                                  ` - ${currencySign} ` +
                                  priceRange.maxPrice
                                : menuItem.price}
                              {!isCheckout && menuItem?.modifiers?.length ? "+" : ""}
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        <Skeleton type="text" width={80} />
                      )}
                      {!isCheckout && (
                        <Box sx={{ marginTop: "5px" }}>
                          <Typography style={{ fontSize: "12px", width: "170px" }}>
                            {translating ? (
                              <Skeleton variant="text" />
                            ) : (
                              <>
                                <span className={classes.descriptionText}>
                                  {tranlatedDesc
                                    ? tranlatedDesc.substring(0, 20)
                                    : menuItem.description.substring(0, 20)}
                                </span>
                                {menuItem.description.length > 20 && (
                                  <span
                                    onClick={handleClick}
                                    style={{ color: "#023e8a", cursor: "pointer" }}
                                  >
                                    ...more
                                  </span>
                                )}
                              </>
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>

                  {currentMenuItemStatus == "Online" && popularity && (
                    <Grid
                      item
                      xs={3}
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      <Box className={classes.popularityCont}>
                        <Box sx={{ width: "40px" }}>
                          <Circle
                            animate={true}
                            animationDuration="1s"
                            responsive={true}
                            size={10}
                            progress={popularity}
                            progressColor="#DF2800"
                            textColor="#DF2800"
                            textStyle={{
                              font: "bold 6rem Helvetica, Arial, sans-serif",
                            }}
                            percentSpacing={10}
                            roundedStroke={true}
                            showPercentage={true}
                            showPercentageSymbol={true}
                          />
                        </Box>

                        <Typography style={{ fontSize: "12px" }}>
                          popularity
                        </Typography>
                      </Box>
                    </Grid>
                  )}

                  {currentMenuItemStatus === "Offline" &&
                    !isCheckout &&
                    currentMenuItemId === menuItem._id && (
                      <Typography
                        className={classes.notAvailable}
                        variant="h6"
                        color="warning"
                      >
                        Not Available
                      </Typography>
                    )}
                </Grid>
              </Grid>
              {!isCheckout && (
                <Grid
                  className={classes.cover}
                  onClick={handleClick}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    position: "relative",
                    alignItems: "center",
                  }}
                  item
                  xs={4}
                >
                  <Box sx={{ position: "relative", width: "90px", height: "90px" }}>
                    <img
                      src={
                        menuItem.imageUrl ? menuItem.imageUrl : restaurant?.logoUrl
                      }
                      alt="Future of dining"
                      className={classes.img}
                      width="90px"
                      height="90px"
                      loading="lazy"
                    />
                    <Box className={classes.addBtn}>
                      <Typography
                        onClick={handleClick}
                        style={{
                          color: "#df2800",
                          textAlign: "center",
                        }}
                        align="center"
                        variant="h6"
                      >
                        {restaurantOpen &&
                        restaurant?.contactlessOrdering &&
                        !menuonly
                          ? "ADD"
                          : "VIEW"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
          {isCheckout && (
            <Grid item xs={3} style={{ display: "flex", justifyContent: "right" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box id="bottomCounter">
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "16PX",
                      fontWeight: "500",
                      textAlign: "right",
                      marginBottom: "5px",
                    }}
                  >
                    Qty - {menuItem.quantity}
                  </Typography>
                </Box>
                {menuItem.isOrdered ? (
                  <Button
                    variant="outlined"
                    color="black"
                    size="small"
                    disabled={!showFeedback}
                    onClick={() => setOpen(true)}
                    className={classes.feedbackBtn}
                  >
                    Liked It?
                  </Button>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box onClick={handleClick} className={classes.icons}>
                      <CreateIcon style={{ fontSize: "22px", color: "#8e412e" }} />
                    </Box>
                    <Box className={classes.icons} onClick={removeCartItem}>
                      <DeleteIcon style={{ fontSize: "22px" }} color="primary" />
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          )}
          {isCheckout && menuItem.modifiers && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",

                padding: "0 4px",
              }}
            >
              <Box
                style={{ display: "flex", flexDirection: "row", overflow: "scroll" }}
              >
                {menuItem.modifiers.map((modifier) => {
                  {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Typography
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "max-content",
                            fontWeight: "500",
                          }}
                        >
                          {modifier.selectedTypeId}
                          <Typography variant="title" color="inherit" noWrap>
                            &nbsp;
                          </Typography>
                          :
                          <Typography variant="title" color="inherit" noWrap>
                            &nbsp;
                          </Typography>
                          {modifier.selectedOptions.map((option, index) => {
                            return (
                              <>
                                <Typography
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "max-content",
                                    fontWeight: "400",
                                  }}
                                >
                                  {option.modifierName}
                                </Typography>
                                {modifier.selectedOptions.length - 1 == index
                                  ? ";"
                                  : ","}
                                <Typography variant="title" color="inherit" noWrap>
                                  &nbsp;
                                </Typography>
                              </>
                            )
                          })}
                          <Typography variant="title" color="inherit" noWrap>
                            &nbsp;
                          </Typography>
                        </Typography>
                      </Box>
                    )
                  }
                })}
              </Box>
            </Grid>
          )}
          {isCheckout && menuItem.specialInstructions && (
            <Grid item xs={12} style={{ padding: "0 4px" }}>
              <Box
                style={{ display: "flex", flexDirection: "row", overflow: "scroll" }}
              >
                <Typography>{menuItem.specialInstructions}</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      ) : (
        <Box
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContet: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">How was {menuItem.name}?</DialogTitle>
        <DialogContent>
          <Rating
            name="simple-controlled"
            value={stars}
            size="large"
            onChange={(event, newValue) => {
              setStars(newValue)
            }}
            style={{ fontSize: "40px" }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="feedBack"
            label="Give feedback"
            type="text"
            fullWidth
            onChange={(e) => setFeedbackMsg(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            size="small"
            color="black"
          >
            Cancel
          </Button>
          <Button
            onClick={giveFeedback}
            variant="contained"
            size="small"
            color="primary"
            disabled={stars < 1}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Drawer
        anchor={"bottom"}
        open={showUpdateScreen}
        onClose={() => setShowUpdateScreen(false)}
      >
        <UpdateItem
          objectId={menuItem.objectId}
          menuId={menuItem._id}
          item={menuItem}
          setShowUpdateScreen={setShowUpdateScreen}
        />
      </Drawer>
      <Dialog
        open={showLanguages}
        onClose={() => setShowLanguages(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Select Language</DialogTitle>
        <DialogContent>
          <List>
            {supportedLangs.map((language) => {
              return (
                <ListItem
                  style={{ cursor: "pointer" }}
                  key={language.name}
                  onClick={() => {
                    makeEncrypt("primaryLang", language.languageCode)
                    setPrimaryLang(language.languageCode)
                    getTranslation()
                    setTranslating(true)
                  }}
                >
                  <ListItemText primary={language.name} />
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
