import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import CreateIcon from "@material-ui/icons/Create"
import DeleteIcon from "@material-ui/icons/Delete"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import React, { useContext, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { setCurrentUser } from "../actions/userActions"
import axios from "../axios"
import { MyContext } from "../context"
import CheckoutScreen from "../screens/CheckoutScreen"
import UpdateItem from "../screens/UpdateItem"
import { makeDecrypt, makeEncrypt } from "../utils/securels"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  viewBill: {
    display: "flex",
    width: "100%",
    padding: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundImage:
      "linear-gradient(to bottom, rgba(255,255,255,0.8), rgba(255,255,255,1))",
    paddingTop: "30px",
  },
  mainBtn: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    width: "100%",
    padding: "15px 10px",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "15px",
  },
  loader: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContet: "center",
  },
  wrapper: {
    width: "100%",
    height: "50vh",

    overflow: "scroll",
  },
  cartItemsWrapper: {
    backgroundColor: "white",
    boxShadow:
      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    padding: "10px 0",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconBox: {
    border: "1px solid black",
    borderRadius: "2px",
  },
  icons: {
    boxShadow:
      " rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "15px",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  partition: {
    width: "2px",
    backgroundColor: "white",
    height: "15px",
    margin: "auto 10px",
  },
  closeBox: {
    position: "absolute",
    top: "10px",
    right: "10px",
    boxShadow:
      " rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;",
    borderRadius: "50%",
  },
  textWrapper: {
    display: "flex",
    alignItems: "center",
  },
}))

function ViewBill({ menuScreen, match }) {
  const classes = useStyles()
  const [currentCartItems, setCurrentCartItems] = useState([])
  const [open, setOpen] = useState(false)
  const [progress, setProgress] = useState(0)
  const { currencySign, restaurant } = useSelector((state) => state.restaurant)
  const { thisUser, cartItems } = useSelector((state) => state.user)
  const [taxTypes, setTaxTypes] = useState()
  const [totalPrice, setTotalPrice] = useState(0)
  const [fee, setFee] = useState(0)
  const [dinifySidePayments, setDinifySidePayments] = useState(true)
  const [paythroughApp, setPayThroughApp] = useState(false)
  const [tax, setTax] = useState(0)
  const [taxValue, setTaxValue] = useState(0)
  const [pstValue, setPstValue] = useState(0)
  const [activeItem, setActiveItem] = useState()
  const [showUpdateScreen, setShowUpdateScreen] = useState()
  const [showCheckout, setShowCheckout] = useState()
  const { refreshCart, setRefreshCart, setViewBill } = useContext(MyContext)
  const dispatch = useDispatch()
  useEffect(() => {
    if (restaurant) {
      setDinifySidePayments(
        restaurant.dinifySidePayments === undefined
          ? true
          : restaurant.dinifySidePayments
      )
      if (restaurant.dinifySidePayments && !makeDecrypt("orderId"))
        setPayThroughApp(true)
    }
  }, [restaurant])

  useEffect(() => {
    if (restaurant) {
      const { GST, PST } = restaurant?.taxTypes
      setTaxTypes({
        GST,
        PST,
      })
      setTax(parseFloat(GST))
      setFee(
        restaurant.dinify_payment_plan.push_to_customer
          ? restaurant.dinify_payment_plan.application_service_fee
          : restaurant.dinify_payment_plan.half_to_customer
          ? restaurant.dinify_payment_plan.application_service_fee / 2
          : 0
      )
    }
  }, [restaurant, dinifySidePayments])

  useEffect(() => {
    let value = parseFloat((tax * parseFloat(totalPrice)) / 100)
    setTaxValue(value)
  }, [tax, totalPrice])

  useEffect(() => {
    let tempPrice = 0
    let temp = 0
    currentCartItems?.length &&
      currentCartItems.map((order) => {
        tempPrice = tempPrice + order.order.price * order.noOfItems

        if (order.order.pstApplicable) {
          temp =
            temp +
            ((order.order.price *
              (order.order.pstPercentage
                ? order.order.pstPercentage
                : parseFloat(taxTypes.PST))) /
              100) *
              order.noOfItems
        }
      })
    setPstValue(temp.toFixed(2))
    setTotalPrice(tempPrice)

    let value = parseFloat((tax * parseFloat(tempPrice)) / 100)
    setTaxValue(value)
  }, [currentCartItems, tax, fee, pstValue, taxValue])

  useEffect(() => {
    makeEncrypt("totalPrice", "")
    if (refreshCart) viewCartItems()
  }, [refreshCart])

  useEffect(() => {
    if (thisUser && !refreshCart && cartItems.length) {
      setCurrentCartItems(cartItems)
      setProgress(100)
    } else if (thisUser && !refreshCart && !cartItems.length) {
      const cart_Items = thisUser.cart.items.filter(
        (item) => item.restaurant == makeDecrypt("restaurantId")
      )
      setCartItems(cart_Items)
    }
  }, [thisUser])
  const setCartItems = (cart) => {
    let justItems = []
    cart.map(async (item) => {
      try {
        let menuItem = await axios.get(`/menu/${item.itemId}/menu-item-detail`)
        menuItem = menuItem.data
        justItems.push({
          order: {
            objectId: item._id,
            category: menuItem.category,
            price: item.price,
            _id: menuItem._id,
            name: menuItem.name,
            imageUrl: menuItem.imageUrl,
            pstApplicable: menuItem.pstApplicable,
            pstPercentage: menuItem.pstPercentage,
            specialInstructions: item.specialInstructions,
            modifiers: item.modifiers,
          },
          noOfItems: item.quantity,
          isOrdered: item.isOrdered,
        })
        if (justItems.length == cart.length) {
          setCurrentCartItems(justItems)
          dispatch({ type: "SET_CART_ITEMS", payload: justItems })
          setProgress(100)
        }
      } catch (error) {
        console.log(error.message)
      }
    })
  }

  const viewCartItems = () => {
    if (refreshCart) {
      axios
        .post(
          `/user/user-details`,
          {
            phone: makeDecrypt("user"),
            userId: makeDecrypt("userId"),
          },
          {
            onDownloadProgress: (p) => {
              setProgress((p.loaded / p.total) * 100)
            },
          }
        )
        .then((response) => {
          const cartItems = response.data.cart.items.filter(
            (item) => item.restaurant == makeDecrypt("restaurantId")
          )
          setCartItems(cartItems) //response.data.cart
          dispatch(setCurrentUser(response.data))
          if (response.data.cart.items.length == 0) {
            // window.location.reload()
            dispatch({ type: "SET_CART_ITEMS", payload: [] })
            setCurrentCartItems([])
            makeEncrypt("totalPrice", 0)
            makeEncrypt("checkout", false)
            setProgress(100)
            setViewBill(false)
          }
          setRefreshCart(false)
        })
    }
  }

  const removeCartItem = (item) => {
    setProgress(0)
    axios
      .put(
        `/user/${makeDecrypt("userId")}/${makeDecrypt("restaurantId")}/${
          item.order._id
        }/delete-cart-item`,
        {
          menuItemId: item.order._id,
          phone: makeDecrypt("user"),
          objectId: item.order.objectId,
        }
      )
      .then((res) => {
        // viewCartItems()
        setRefreshCart(true)
      })
      .catch((error) => {
        console.log(error.message)
        setProgress(100)
      })
  }

  const editItem = async (item) => {
    setActiveItem(item.order)
    setOpen(false)
    setShowUpdateScreen(true)
  }

  var container = document.getElementById("billContainer")

  container && container.addEventListener("touchstart", startTouch, false)
  container && container.addEventListener("touchmove", moveTouch, false)

  // Swipe Up / Down / Left / Right
  var initialX = null
  var initialY = null

  function startTouch(e) {
    initialX = e.touches[0].clientX
    initialY = e.touches[0].clientY
  }

  function moveTouch(e) {
    if (initialX === null) {
      return
    }
    if (initialY === null) {
      return
    }
    var currentX = e.touches[0].clientX
    var currentY = e.touches[0].clientY
    initialX = null
    initialY = null
    e.preventDefault()
  }

  const handleClick = () => {
    if (restaurant?.restaurantAddress.country.toUpperCase() == "INDIA") {
      viewCartItems()
    } else if (restaurant?.restaurantAddress.country.toUpperCase() == "CANADA") {
      if (dinifySidePayments) {
        setOpen(true)
        viewCartItems()
      } else {
        setShowCheckout(true)
      }
    }
  }

  return (
    <Box className={classes.root} id="billContainer">
      <Box className={classes.viewBill}>
        {currentCartItems?.length ? (
          <Grid className={classes.mainBtn}>
            <Grid
              className={classes.textWrapper}
              item
              xs={8}
              onClick={() => {
                handleClick()
              }}
            >
              {totalPrice ? (
                <>
                  <Typography style={{ color: "white" }} variant="h6">
                    {currentCartItems?.length > 1
                      ? `${currentCartItems?.length} items`
                      : `${currentCartItems?.length} item`}
                  </Typography>
                  <Box className={classes.partition}></Box>
                  <Typography style={{ color: "white" }} variant="h6">
                    {currencySign}
                    {makeDecrypt("totalPrice")
                      ? makeDecrypt("totalPrice")
                      : parseFloat(
                          totalPrice + taxValue + fee + parseFloat(pstValue)
                        ).toFixed(2)}
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Grid>
            {progress == 100 ? (
              <Grid item>
                <Box display={"flex"} alignItems="center">
                  {dinifySidePayments || makeDecrypt("tableNo") == "takeout" ? (
                    <Link
                      to={`/checkout/${makeDecrypt("restaurantId")}`}
                      style={{ color: "white" }}
                    >
                      <Typography variant="h6">CHECKOUT</Typography>
                    </Link>
                  ) : (
                    <Typography
                      onClick={() => {
                        setShowCheckout(true)
                      }}
                      style={{ color: "white" }}
                      variant="h6"
                    >
                      CHECKOUT
                    </Typography>
                  )}
                  <NavigateNextIcon style={{ color: "white" }} />
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        ) : (
          ""
        )}
      </Box>
      <Drawer anchor={"bottom"} open={open} onClose={() => setOpen(false)}>
        <Box className={classes.cartItemsWrapper}>
          <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
          <Typography variant="h6" style={{ textAlign: "center" }}>
            CART ITEMS
          </Typography>
          <Divider />
          <Box className={classes.wrapper}>
            {currentCartItems?.length && progress == 100 ? (
              <List style={{ overflow: "scroll" }}>
                {currentCartItems.map((item) => {
                  return (
                    <>
                      <ListItem className={classes.listItem}>
                        <Box>
                          <Typography style={{ color: "black" }}>
                            {item.order.name ? item.order.name : item.order._id}
                          </Typography>
                          <Typography variant="body">
                            Qty - {item.noOfItems}
                          </Typography>
                        </Box>
                        {!item.isOrdered && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              onClick={() => editItem(item)}
                              className={classes.icons}
                            >
                              <CreateIcon
                                style={{ fontSize: "22px", color: "#8e412e" }}
                              />
                            </span>
                            <span
                              className={classes.icons}
                              onClick={() => removeCartItem(item)}
                            >
                              <DeleteIcon
                                style={{ fontSize: "22px" }}
                                color="primary"
                              />
                            </span>
                          </Box>
                        )}
                      </ListItem>
                      <Divider />
                    </>
                  )
                })}
              </List>
            ) : (
              <Box className={classes.loader}>
                {progress == 100 ? (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Your cart is empty</Typography>
                  </Box>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Drawer>
      <Drawer
        anchor={"bottom"}
        open={showUpdateScreen}
        onClose={() => setShowUpdateScreen(false)}
      >
        <UpdateItem
          objectId={activeItem?.objectId}
          menuId={activeItem?._id}
          item={activeItem}
          setShowUpdateScreen={setShowUpdateScreen}
        />
      </Drawer>
      <Drawer
        PaperProps={{ square: false }}
        anchor={"bottom"}
        open={showCheckout}
        onClose={() => setShowCheckout(false)}
      >
        <Box sx={{ borderRadius: "10px" }}>
          <Box
            sx={{
              boxShadow:
                " rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
              py: 1,
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Order Details
            </Typography>
            <Divider
              style={{
                height: "2px",
                backgroundColor: "black",
                width: "50px",
                margin: "10px auto 0",
              }}
            />
            <Box className={classes.closeBox}>
              <CloseIcon onClick={() => setShowCheckout(false)} />
            </Box>
          </Box>
          <Box
            sx={{
              my: 1,
              maxHeight: "60vh",
              position: "absolut",
              overflow: "scroll",
            }}
          >
            <CheckoutScreen onMenuScreen={true} match={match} />
          </Box>
        </Box>
      </Drawer>
    </Box>
  )
}

export default ViewBill
