import {
  Backdrop,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem as MenuDrop,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import { Alert, Skeleton } from "@material-ui/lab"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import "animate.css"
import dayjs from "dayjs"
import React, { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { setRestaurant } from "../actions/restaurantActions"
import { setCurrentUser } from "../actions/userActions"
import axios from "../axios"
import CouponHighlighter from "../components/CouponHighlighter"
import Loader from "../components/Loader"
import { MyContext } from "../context"
import { makeDecrypt, makeEncrypt } from "../utils/securels"
// import googlePlaces from "google-maps-reviews"
// import StarRatings from "react-star-ratings";
import ReviewCard from "../components/restaurant/ReviewCard"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import ReactPixel from "react-facebook-pixel"
import { getDiscount } from "../actions/discountActions"
import DiscountCard from "../components/restaurant/DiscountCard"
import { Facebook, Instagram, Twitter } from "@mui/icons-material"
import { setLoading } from "../actions/loadingActions"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.default, //theme.palette.primary.default
    // height: "",
    width: "100%",
    // padding: "10px 0",
  },
  overlayStyles: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    // height: '400px',
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "white",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  image: {
    // borderRadius: "50%",
    width: "max-content",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    objectFit: "contain",
    margin: "0 auto",
  },
  btn: {
    margin: "10px auto",
    padding: "8px 20px",
    borderRadius: "10px",
    zIndex: "1",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  data: {
    // margin: `10px 0`,
    width: "90%",
    // background: "#fff9f9",
    textColor: theme.palette.text.primary,
    padding: "10px",
    // boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px;",
  },
  paper: {
    position: "absolute",
    width: 400,
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  demo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderBox: {
    display: "flex",
    minHeight: "100px",
    justifyContent: "center",
    alignItems: "center",
  },
  notServicableBox: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: " translate(-50%, -50%)",
    width: "fit-content",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
    borderRadius: "5px",
    padding: "10px",
  },
  discountContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    gap: "10px",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  discountCard: {
    height: "50px",
    marginRight: "15px",
    flexGrow: 1,
    minWidth: "120px",
    maxWidth: "125px",
    backgroundColor: "pink",
  },
  dishWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    overflowY: "hidden",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  dishContainer: {
    display: "flex",
    position: "relative",
    marginRight: "10px",
  },
  dishImg: {
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    objectFit: "cover",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    cursor: "pointer",
    transition: "transform 0.15s ease-in-out",
    "&:hover": {
      transform: "scale3d(1.05, 1.05, 1)",
    },
  },
  imgOverlay: {
    width: "120px",
    height: "50px",
    borderRadius: "5px",
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)",
  },
  dishContext: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    padding: "7px",
    width: "100%",
  },
  circle: {
    borderRadius: "50%",
    backgroundColor: "#DF2800",
    p: 0.5,
    mb: 1,
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  extraBox: {
    height: "0px",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
    },
  },
  //banner styles
  logo: {
    maxHeight: "80px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "60px",
      marginTop: "20px",
    },
  },
  powerText: {
    fontSize: "14px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  todaySpecialText: {
    fontSize: "25px",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  rightDiv: {
    width: "350px",
    marginLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "130px",
      marginLeft: "10px",
    },
  },
}))

function RestaurantScreen({ match }) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [reviewModal, setReviewModal] = useState(false)
  const { restaurant, currencySign, instructions } = useSelector(
    (state) => state.restaurant
  )
  const { discounts } = useSelector((state) => state.discount)
  const DayofWeek = restaurant?.resWorkingHours[dayjs(new Date()).day() - 1 ?? 6]
  const [AlertMessage, setAlertMessage] = useState("")
  const classes = useStyles()
  const [statusPopup, setStatusPopup] = useState(false)
  const [offerPopup, setOfferPopup] = useState(false)
  const [steps, setSteps] = useState([])
  const { highlightedPromo } = useSelector((state) => state.coupon)
  const { thisUser } = useSelector((state) => state.user)
  const [tableStatus, setTableStatus] = useState(null)
  const [specialDish, setSpecialDish] = useState()
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { restaurantOpen, showWorkingMessage, workingHoursMessage } =
    useContext(MyContext)
  const history = useHistory()
  const [msg, setmsg] = useState("")
  const [date, setdate] = useState(new Date())
  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [restOffline, setRestOffline] = useState(false)
  const [showBooking, setshowBooking] = useState(false)
  const [showGoogleReview, setShowGoogleReview] = useState(false)
  const [reviews, setReviews] = useState([])
  const [customerFacingDiscounts, setCustomerFacingDiscounts] = useState([])
  const [imageLoading, setImageLoading] = useState(true)

  const apiKey = "AIzaSyDu_2OQ4GnlZBUPj7q87kM-sev1sFx3WbA"
  const placeId = "ChIJv95Qo9J1hlQRBlqdCGJCduc"

  const [TableName, setTableName] = useState([])
  const Reserve = async () => {
    if (!makeDecrypt("userId")) {
      const user = await axios.post("/user/create-user", {
        name: name,
        email: email,
      })
      axios
        .post(`/restaurant/reserve-table/${match.params.restaurantId}/`, {
          Tables: TableName.join(","),
          Time: date,
          userId: user.data._id,
        })
        .then((res) => {
          setshowBooking(false)
          setmsg(res.data?.message)
          setOpenSnackbar(true)
        })
    } else {
      axios
        .post(`/restaurant/reserve-table/${match.params.restaurantId}/`, {
          Tables: TableName.join(","),
          Time: date,
          userId: makeDecrypt("userId"),
        })
        .then((res) => {
          setshowBooking(false)
          setmsg(res.data?.message)
          setOpenSnackbar(true)
        })
    }
  }
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setTableName(typeof value === "string" ? value.split(",") : value)
  }

  useEffect(() => {
    // ReactPixel.track('ViewMenu', { info: 'User views the menu' });
    const getUserDetails = () => {
      axios
        .post(`/user/user-details`, {
          phone: makeDecrypt("user"),
          userId: makeDecrypt("userId"),
        })
        .then(async (response) => {
          let user = response.data
          let items = response.data.cart.items
          if (items.length) {
            try {
              if (!restaurant) dispatch(setRestaurant(match.params.restaurantId))
              makeEncrypt("restaurantId", match.params.restaurantId)
              makeEncrypt("CustomerId", user.stripeDetails.customerId)
              makeEncrypt(
                "paymentMethodId",
                user.stripeDetails.paymentMethod.paymentMethodId
              )
              makeEncrypt(
                "paymentIntentId",
                user.stripeDetails.paymentMethod.paymentIntentId
              )
              // if (items.some((item) => item.isOrdered === true)) {
              //   makeEncrypt("orderId", user.order)
              //   setOpen(true)
              // }
              // makeEncrypt("checkout", true)
              makeEncrypt("userId", user._id)
              dispatch(setCurrentUser(user))
            } catch (error) {
              setAlertMessage(error.message)
            }
          } else {
            if (!restaurant) getRestaurant()
          }
        })
        .catch((error) => setAlertMessage(error.message))
    }
    const getRestaurant = () => {
      dispatch(setRestaurant(match.params.restaurantId))
      makeEncrypt("tableNo", match.params.tableNo)
      makeEncrypt("restaurantId", match.params.restaurantId)
      setOfferPopup(true)
    }

    if (instructions.length) {
      setSteps(instructions)
    } else {
      axios.get("https://strapi.dinify.io/instructions").then((res) => {
        setSteps(res.data)
        dispatch({ type: "SET_INSTRUCTIONS", payload: res.data })
      })
    }
    if (makeDecrypt("userId") && !thisUser) {
      getUserDetails()
    } else if (!restaurant) getRestaurant()
  }, [])

  useEffect(() => {
    if (restaurant && open) {
      checkForActiveSubcription()
    }

    if (restaurant) {
      let temp = restaurant.tables.find(
        (table) => table.tableNo == match.params.tableNo
      )
      if (!temp?.isDisabled) setTableStatus("active")
      else if (temp?.isDisabled) setTableStatus("inactive")
    }
    if (restaurant?.specialDish) {
      dispatch(setLoading(true))
      axios
        .post(`/menu/${restaurant._id}/get-daily-special-items`, {
          menuItems: restaurant.specialDish,
        })
        .then((response) => {
          setSpecialDish(response.data)
        })
        .catch((error) => {})
        .finally(dispatch(setLoading(false)))
    }
  }, [restaurant])

  const handleTiktok = (e) => {
    e.stopPropagation()
    window.open(restaurant.tiktokEmbed)
  }
  const handleInsta = (e) => {
    e.stopPropagation()
    window.open(restaurant.instagramEmbed)
  }

  const handleFb = (e) => {
    e.stopPropagation()
    window.open(restaurant.facebookEmbed)
  }

  const openPopup = () => {
    setShowGoogleReview(true)
  }

  const closePopup = () => {
    setShowGoogleReview(false)
  }

  // useEffect(() => {
  //   socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
  //     transports: ["websocket"],
  //   })
  //   setTempSocket(socket)
  //   const makeConnection = async () => {
  //     socket.current.emit("checkConnection", makeDecrypt("restaurantId"))
  //     socket.current.on("checkConection", (data) => {
  //       if (!data && makeDecrypt("tableNo") == "takeout") {
  //         setRestOffline(true)
  //       }
  //     })
  //   }
  //   makeConnection()
  // }, [])

  useEffect(() => {
    if (restaurant?.acceptTakeout == false && makeDecrypt("tableNo") == "takeout")
      setRestOffline(true)
  }, [restaurant])

  const checkForActiveSubcription = () => {
    if (restaurant.status === "active") {
      axios
        .get(
          `/stripe/getSubscription/${restaurant.dinify_payment_plan.subscriptionId}`
        )
        .then((data) => {
          if (data.status !== "active" && data.status !== "trialing") {
            setStatusPopup(true)
          }
        })
        .catch((error) => {
          return error
        })
    } else if (restaurant.status === "inactive") setStatusPopup(true)
  }
  useEffect(() => {
    if (restaurant?._id) {
      if (restaurant.facebookPixelId != null) {
        ReactPixel.init(restaurant.facebookPixelId)
        ReactPixel.pageView() // Optional: Fire a page view event
      }
      axios
        .get(`/place/details?restaurantId=${restaurant._id}`)
        .then((res) => setReviews(res.data))
    }
  }, [restaurant])
  useEffect(() => {
    dispatch(getDiscount())
  }, [restaurant])
  useEffect(() => {
    const getCustomerFacingTrueDiscounts = (array) => {
      return array.filter(
        (obj) =>
          (obj.customerFacing === true && obj.visibleToCustomers === true) || false
      )
    }
    // const getVisibleToCustomersDiscounts = (array) => {
    //   return array.filter(obj => obj.visibleToCustomers === true || false);
    // }
    const result = getCustomerFacingTrueDiscounts(discounts)
    if (match.params.tableNo === "takeout") {
      const takeoutDiscounts = result.filter(
        (item) => item.applicableFor === "take_out" || item.applicableFor === "both"
      )
      setCustomerFacingDiscounts(takeoutDiscounts)
    } else {
      const dineInDiscounts = result.filter(
        (item) => item.applicableFor === "dine_in" || item.applicableFor === "both"
      )
      setCustomerFacingDiscounts(dineInDiscounts)
    }
  }, [discounts, restaurant])

  function SampleNextArrow({ className, style, onClick }) {
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "gray",
          padding: "2px",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    )
  }
  const settings = {
    accessibility: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  // console.log('res, dis, sd', restaurant, discounts, specialDish);
  if (imageLoading && !specialDish) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>Restaurant | {restaurant ? restaurant.name : ""}</title>
      </Helmet>
      <CouponHighlighter />
      {restaurant && tableStatus == "active" ? (
        <>
          <Box
            className={classes.root}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
          >
            <Box
              className={classes.data}
              style={{
                position: "relative",
                padding: "0px",
                width: "100%",
                backgroundColor: "black",
              }}
            >
              {/* <img onLoad={()=>setImageLoading(false)} style={{ width: '100%', maxHeight: '60vh', objectFit: 'cover', opacity: '0.5' }} src={restaurant?.coverPhotoUrl || "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg" || ""} /> */}
              <Box style={{ width: "100%" }}>
                <LazyLoadImage
                  onLoad={() => setImageLoading(false)}
                  width={"100%"}
                  style={{
                    width: "100%",
                    maxHeight: "60vh",
                    objectFit: "cover",
                    opacity: `${!imageLoading ? 0.5 : 0}`,
                    transition: "opacity 0.5s ease",
                  }}
                  src={
                    restaurant?.coverPhotoUrl ||
                    "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg" ||
                    ""
                  }
                  effect="blur"
                />
              </Box>
              {!imageLoading && (
                <Box className={classes.overlayStyles}>
                  <Box style={{ width: "100%", position: "relative" }}>
                    <Box className={classes.image}>
                      <img
                        style={{
                          opacity: `${!imageLoading ? 1 : 0}`,
                          transition: "opacity 0.5s ease",
                        }}
                        className={classes.logo}
                        src={
                          restaurant.logoUrl !== "none"
                            ? restaurant.logoUrl
                            : "https://images.unsplash.com/photo-1592861956120-e524fc739696?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cmVzdGF1cmFudHN8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"
                        }
                      />
                    </Box>
                    <Typography className={classes.powerText}>
                      Powered by Dinify
                    </Typography>
                    <Box
                      className={classes.data}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0px auto",
                      }}
                    >
                      <Box style={{ margin: "0px auto" }}>
                        <Typography
                          variant="h1"
                          className={classes.todaySpecialText}
                        >
                          {restaurant && restaurant.name}
                        </Typography>
                        <Box align="center">
                          <Box align="center">
                            <center>
                              {restaurant.tiktokEmbed ? (
                                <img
                                  src="/tiktok_transparent.png"
                                  height="25px"
                                  width="25px"
                                  style={{ marginLeft: "0px" }}
                                  onClick={handleTiktok}
                                ></img>
                              ) : null}
                              {restaurant.instagramEmbed ? (
                                <img
                                  src="/insta_transparent.png"
                                  height="25px"
                                  width="25px"
                                  style={{ marginLeft: "10px" }}
                                  onClick={handleInsta}
                                ></img>
                              ) : null}
                              {restaurant.facebookEmbed ? (
                                <img
                                  src="/fb_transparent.png"
                                  height="25px"
                                  width="25px"
                                  style={{ marginLeft: "10px" }}
                                  onClick={handleFb}
                                ></img>
                              ) : null}
                            </center>
                          </Box>
                        </Box>
                        {/* view menu button  */}
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Button
                            variant="contained"
                            className={[classes.btn, "animate__animated "]}
                            color="primary"
                          >
                            <Link
                              to={`/restaurant/menu/${restaurant._id}`}
                              style={{ color: "white" }}
                            >
                              {makeDecrypt("tableNo") == "takeout"
                                ? "Takeout Order"
                                : "VIEW MENU"}
                            </Link>
                          </Button>
                          {restaurant?.allowReservations &&
                            makeDecrypt("tableNo") == "takeout" && (
                              <Button
                                variant="contained"
                                className={[classes.btn, "animate__animated "]}
                                color="primary"
                                onClick={(e) => {
                                  setshowBooking(true)
                                }}
                              >
                                BOOK A TABLE
                              </Button>
                            )}
                        </Box>
                        {showWorkingMessage && workingHoursMessage && (
                          <Box textAlign="center" className={classes.data}>
                            <Typography variant="body2">
                              {workingHoursMessage}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      {/* <Box style={{ width: '50%', margin: 'auto 0px' }}>
                      <img className={classes.rightDiv} src="/food.png" />
                    </Box> */}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            {/* icons social  */}
            {/* 
            {makeDecrypt("tableNo") == "takeout" && (
              <Typography>Takeout Order</Typography>
            )} */}
            {restaurant?.chefMessage && restaurant.chefMessage.trim() && (
              <Box textAlign="center" className={classes.data}>
                <Typography variant="h6">Updates</Typography>
                <Typography>{restaurant?.chefMessage}</Typography>
              </Box>
            )}
            {customerFacingDiscounts?.length > 0 && !imageLoading && (
              <Box className={classes.data}>
                <Typography align="center" variant="h6">
                  Discounts
                </Typography>
                <Box className={classes.discountContainer}>
                  {customerFacingDiscounts?.map((discount) => (
                    <DiscountCard key={discount._id} discount={discount} />
                  ))}
                </Box>
              </Box>
            )}

            {!imageLoading && (
              <Box className={classes.data}>
                <Typography align="center" variant="h6">
                  Today's Special
                </Typography>
                <Box className={classes.dishWrapper}>
                  {specialDish
                    ? specialDish.map((dish) => (
                        <Box
                          className={classes.dishContainer}
                          onClick={() =>
                            history.push(`/restaurant/menu/${restaurant._id}`)
                          }
                        >
                          {/* <img
                        src={dish.imageUrl}
                        alt="special dish"
                        className={classes.dishImg}
                      /> */}
                          <Box
                            className={classes.dishImg}
                            sx={{
                              backgroundImage: `url(${dish.imageUrl})`,
                            }}
                          >
                            <Box className={classes.imgOverlay}></Box>
                          </Box>
                          <Box className={classes.dishContext}>
                            <Typography
                              align="center"
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              {dish.name}
                            </Typography>
                            <Typography
                              align="center"
                              style={{
                                fontSize: "14px",
                                color: "white",
                              }}
                            >
                              {currencySign + dish.price}
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    : [1, 2, 3].map(() => (
                        <Box className={classes.dishContainer}>
                          <Skeleton
                            className={classes.dishImg}
                            variant="rect"
                            width={120}
                            height={120}
                          />
                          May be every dish is special today!!
                        </Box>
                      ))}
                </Box>
              </Box>
            )}

            {reviews?.reviews?.length > 0 && !imageLoading && (
              <Box className={classes.data}>
                <Typography align="center" variant="h5">
                  Community Voice
                </Typography>
                {/* <Box className={classes.container} style={{ marginTop: "15px", marginBottom: "15px" }} > */}
                <Slider style={{ marginTop: "10px" }} {...settings}>
                  {reviews?.reviews?.map((item) => (
                    <ReviewCard key={item.id} item={item} />
                  ))}
                </Slider>
                {/* </Box> */}
                {restaurant?.googleReviewPlaceId && (
                  <center>
                    <div>
                      <Button
                        variant="contained"
                        className={[classes.btn, "animate__animated "]}
                        color="secondary"
                        onClick={() => {
                          window.open(
                            "https://search.google.com/local/writereview?placeid=" +
                              restaurant.googleReviewPlaceId,
                            "_blank"
                          )
                        }}
                      >
                        Write Us A Review
                      </Button>
                      {/* <a href="https://search.google.com/local/writereview?placeid=ChIJv95Qo9J1hlQRBlqdCGJCduc" target="_blank">Leave a Google Review</a> */}
                    </div>
                  </center>
                )}
                {/* <Box textAlign={"center"}>
                    <a href={reviews.url}>
                      <Button variant="contained" className={[classes.btn, "animate__animated "]} color="secondary">See More Reviews</Button>
                    </a>
                  </Box> */}
              </Box>
            )}

            {/* <Box className={classes.data}>
              <Typography align="center" variant="h5">
                Follow Us 😎
              </Typography>

              <Box align="center">
                <Box align="center">
                  <center>
                    {restaurant.tiktokEmbed ? (
                      <img
                        src="/tiktok_transparent.png"
                        height="30px"
                        width="30px"
                        style={{ marginLeft: "0px" }}
                        onClick={handleTiktok}
                      ></img>
                    ) : null}
                    {restaurant.instagramEmbed ? (
                      <img
                        src="/insta_transparent.png"
                        height="30px"
                        width="30px"
                        style={{ marginLeft: "10px" }}
                        onClick={handleInsta}
                      ></img>
                    ) : null}
                    {restaurant.facebookEmbed ? (
                      <img
                        src="/fb_transparent.png"
                        height="30px"
                        width="30px"
                        style={{ marginLeft: "10px" }}
                        onClick={handleFb}
                      ></img>
                    ) : null}
                  </center>
                </Box>
              </Box>
            </Box> */}

            {/* {restaurantOpen && (
              <Box textAlign="center" className={classes.data}>
                <Typography variant="h6">Dinifying is Simple! 😎</Typography>
                <Box className={classes.demo}>
                  {steps.length ? (
                    <List dense>
                      {steps.map((step, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemText
                              primary={
                                <Typography>{`${step.id}. ${step.instruction}`}</Typography>
                              }
                            />
                          </ListItem>
                        )
                      })}
                    </List>
                  ) : (
                    <Box className={classes.loaderBox}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Box>
            )} */}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                variant="filled"
                severity={msg === "Reservation Successful" ? "success" : "error"}
              >
                {msg}
              </Alert>
            </Snackbar>
            {/* {makeDecrypt("checkout") && <ViewBill />} */}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={showBooking}
              onClose={(e) => setshowBooking(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showBooking}>
                <Box
                  component={Paper}
                  style={{
                    height: "70vh",
                    display: "flex",
                    width: "60vw",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#e0dedc",
                  }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    Book A Table
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Select Time"
                      value={date}
                      minTime={dayjs(new Date())}
                      maxTime={dayjs(DayofWeek?.closingTime, "HH:mm")}
                      autoOk={true}
                      onChange={(val) => {
                        setdate(val)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{
                            alignSelf: "center",
                            width: "20vw",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    style={{
                      marginTop: "20px",
                    }}
                    type="number"
                    label="People Count"
                    variant="outlined"
                  />
                  <FormControl sx={{ mt: 10, width: "20vw" }}>
                    <InputLabel id="table-multiple">Select Table</InputLabel>
                    <Select
                      labelId="table-multiple"
                      id="multiple-table"
                      multiple
                      style={{ width: "20vw", marginTop: "20px" }}
                      value={TableName}
                      onChange={handleChange}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} color="primary" />
                          ))}
                        </Box>
                      )}
                    >
                      {restaurant?.tables.map((data) => (
                        <MenuDrop key={data.tableNo} value={data.tableNo}>
                          TableNo {data.tableNo}
                        </MenuDrop>
                      ))}
                    </Select>
                  </FormControl>
                  {!makeDecrypt("userId") && (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-around",
                      }}
                    >
                      <TextField
                        style={{
                          marginTop: "20px",
                        }}
                        type="text"
                        placeholder="Name"
                        label="User name"
                        onChange={(e) => setname(e.target.value)}
                        variant="outlined"
                        required
                      />
                      <TextField
                        style={{
                          marginTop: "20px",
                        }}
                        type="email"
                        placeholder="Email"
                        label="user Email"
                        onChange={(e) => setemail(e.target.value)}
                        variant="outlined"
                        required
                      />
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px" }}
                    onClick={Reserve}
                  >
                    BOOK NOW
                  </Button>
                </Box>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={showGoogleReview}
              onClose={(e) => closePopup(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showGoogleReview}>
                <Box
                  component={Paper}
                  style={{
                    height: "70vh",
                    display: "flex",
                    width: "60vw",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#e0dedc",
                  }}
                >
                  <iframe src="https://search.google.com/local/writereview?placeid=ChIJv95Qo9J1hlQRBlqdCGJCduc" />
                </Box>
              </Fade>
            </Modal>
            {/* <Dialog
              open={open}
              aria-labelledby="form-dialog-title"
              textAlign="center"
              alignItems="center"
            >
              <DialogTitle id="form-dialog-title">
                <Typography variant="h5" color="primary">
                  Order Alert
                </Typography>
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  You have your items in the cart either remove them or complete
                  order
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  href={`/checkout/${makeDecrypt("restaurantId")}`}
                  variant="outlined"
                  color="black"
                >
                  GO TO CHECKOUT PAGE
                </Button>
              </DialogActions>
            </Dialog> */}
            <Dialog
              open={statusPopup}
              aria-labelledby="form-dialog-title"
              textAlign="center"
              alignItems="center"
            >
              <DialogTitle id="form-dialog-title">!Error</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  This restaurant is temporary not active This restaurant is
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button href={`/`} variant="outlined" color="black">
                  SEE THE RESTAURANT LISTS
                </Button>
              </DialogActions>
            </Dialog>

            {highlightedPromo && (
              <Dialog
                open={offerPopup && highlightedPromo}
                aria-labelledby="form-dialog-title"
                textAlign="center"
                alignItems="center"
                onClose={(e, r) => {
                  if (r === "clickaway") {
                    return
                  }
                  setOfferPopup(false)
                }}
              >
                <DialogTitle id="form-dialog-title">
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    New Customer Offer | Use Code: {highlightedPromo?.code}
                  </Typography>
                </DialogTitle>

                <DialogContent>
                  <DialogContentText style={{ textAlign: "center" }}>
                    Use this code to get {currencySign}
                    {(highlightedPromo.coupon.amount_off / 100).toFixed(2)} off with
                    minimum {currencySign}
                    {(highlightedPromo.restrictions.minimum_amount / 100).toFixed(
                      2
                    )}{" "}
                    order.
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            )}

            <Dialog open={restOffline}>
              <DialogTitle>
                <Box display={"flex"} alignItems={"center"} flexDirection="column">
                  <Box className={classes.circle} sx={{ mb: 1 }}>
                    <Typography
                      variant="h1"
                      style={{ color: "white", fontWeight: "700" }}
                    >
                      X
                    </Typography>
                  </Box>
                  <Typography variant="h1" color="primary">
                    Ooops,
                  </Typography>
                  <Divider />
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box display={"flex"} alignItems={"center"} flexDirection="column">
                  <Typography align="center">
                    {restaurant.name} is temporarily not accepting takeout orders.
                    Please call the restaurant at{" "}
                    <a href={`tel:${restaurant.restaurantAddress.phoneNo}`}>
                      {restaurant.restaurantAddress.phoneNo}
                    </a>
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      endIcon={<ArrowRightAltIcon fontSize="large" />}
                      href={`/restaurant/menu/${restaurant._id}?menuonly=${true}`}
                    >
                      menu
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
        </>
      ) : tableStatus == "inactive" ? (
        <Box className={classes.notServicableBox}>
          <Typography>This table is not servicable</Typography>
          <Typography>Please change the table.</Typography>
        </Box>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default RestaurantScreen
