import { Box, Button, Typography } from "@material-ui/core"
import PaymentIcon from "@material-ui/icons/Payment"
import { Elements, PaymentRequestButtonElement } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useEffect, useState } from "react"
import axios from "../axios"
import AlertModalForNativePayment from "../components/AlertModalForNativePayment"
import { makeDecrypt, makeEncrypt } from "../utils/securels"
// import Stripe from "stripe";

/* global Stripe */
var stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const Gpay = ({
  setSuccessMessage,
  prevNativePaymentMethodId,
  prevNativeCardLastDigits,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  createCustomerId,
  attachPaymentMethodIdToCustomer,
  detachPaymentMethodIdFromCustomer,
  prevPaymentMethodId,
  setAlertMessage,
  tab,
  setButtonTypes,
  setShouldLoad,
  buttonTypes,
}) => {
  const elements = stripe.elements()
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [isAlert, setIsAlert] = useState(false)
  useEffect(() => {
    ;(async () => {
      if (!stripe || !elements || prevNativePaymentMethodId === null) {
        return
      }

      const pr = await stripe.paymentRequest({
        country: "CA",
        currency: "cad",
        total: {
          label: "Save your google pay card",
          amount: 0,
        },
        dataLabel: "Save Card",
        requestPayerName: true,
        requestPayerEmail: true,
      })

      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setButtonTypes(result)
            setPaymentRequest(pr)
            setIsAlert(false)
          } else {
            if (!prevNativePaymentMethodId) setShouldLoad(false)
            else setShouldLoad(true)
            setIsAlert(true)
          }
        })
        .catch((error) => {
          setAlertMessage(error.message)
        })

      pr.on("paymentmethod", async (e) => {
        if (e.paymentMethod.id) {
          let thisCustomerId
          if (!makeDecrypt("CustomerId")) {
            try {
              thisCustomerId = await createCustomerId(makeDecrypt("email"))
              makeEncrypt("CustomerId", thisCustomerId)
              executeNativeCardChanges(
                e,
                e.paymentMethod.id,
                e.paymentMethod.card.last4,
                e.paymentMethod.card.wallet.type,
                thisCustomerId
              )
            } catch (error) {
              e.complete("fail")
              setAlertMessage(error.message)
            }
          } else {
            thisCustomerId = makeDecrypt("CustomerId")
            executeNativeCardChanges(
              e,
              e.paymentMethod.id,
              e.paymentMethod.card.last4,
              e.paymentMethod.card.wallet.type,
              thisCustomerId
            )
          }
        }
      })
    })()
  }, [stripe, prevNativePaymentMethodId])

  const executeNativeCardChanges = (
    e,
    nativePaymentMethodId,
    nativeCardLastDigits,
    nativeType,
    customerId
  ) => {
    changeNativeCardDetails(
      nativePaymentMethodId,
      nativeCardLastDigits,
      nativeType,
      customerId
    )
      .then(async (data) => {
        if (
          !makeDecrypt("paymentMethodId") &&
          e.paymentMethod.card.wallet.type !== "google_pay"
        ) {
          makeEncrypt("paymentMethodId", e.paymentMethod.id)
        }
        attachPaymentMethodIdToCustomer(e.paymentMethod.id, customerId)
          .then((res) => {
            e.complete("success")
            window.location.reload()
          })
          .catch((error) => {
            setAlertMessage(error.message)
            e.complete("fail")
            if (e.paymentMethod.card.wallet.type !== "google_pay")
              window.location.reload()
          })
      })
      .catch((error) => {
        setAlertMessage(error.message)
        e.complete("fail")
      })
  }
  const changeNativeCardDetails = async (
    nativePaymentMethodId,
    nativeCardLastDigits,
    nativeType,
    customerId
  ) => {
    return await axios
      .patch(`/user/${makeDecrypt("userId")}/add-update-native-payment-method`, {
        nativePaymentMethodId,
        nativeCardLastDigits,
        nativeType,
        customerId: customerId
          ? customerId
          : prevPaymentMethodId
          ? makeDecrypt("CustomerId")
          : "",
      })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        setAlertMessage(error.message)
        return error
      })
  }

  const deleteGooglePayCard = () => {
    changeNativeCardDetails("", "", "", makeDecrypt("CustomerId"))
      .then((res) => {
        if (selectedPaymentMethod === makeDecrypt("paymentMethodId"))
          localStorage.removeItem("paymentMethodId")

        detachPaymentMethodIdFromCustomer(prevNativePaymentMethodId)
          .then((data) => {
            window.location.reload()
          })
          .catch((error) => setAlertMessage(error.message))
      })
      .catch((error) => setAlertMessage(error.message))
  }

  const SavedNativeCard = () => {
    return (
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2, mt: 0 }}
        >
          <Box display="flex" alignItems="center" sx={{ my: 2 }}>
            <Box>
              <PaymentIcon fontSize="medium" />
            </Box>

            <Typography variant="body1">
              **** **** {prevNativeCardLastDigits}
            </Typography>
          </Box>

          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => {
              if (isAlert) setOpen(true)
              else deleteGooglePayCard()
            }}
          >
            DELETE CARD
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box alignItems="center" justifyContent="center" height="100%">
      <Elements stripe={stripePromise}>
        {paymentRequest ? (
          !prevNativePaymentMethodId ? (
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          ) : (
            <SavedNativeCard />
          )
        ) : prevNativePaymentMethodId ? (
          <SavedNativeCard />
        ) : (
          ""
        )}
      </Elements>
      <AlertModalForNativePayment
        open={open}
        setOpen={setOpen}
        deleteGooglePayCard={deleteGooglePayCard}
      />
    </Box>
  )
}

export default Gpay
