import React from "react"
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  makeStyles,
} from "@material-ui/core"
import PastOrderTable from "./PastOrderTable"

const useStyles = makeStyles((theme) => ({
  paginationBox: {
    display: "flex",
    alignItems: "right",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "right",
    },
  },
}))

export const PastOrders = ({ data, snack }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Past Orders" />

      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#f5d0b0", color: "whitesmoke" }}>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell>Orderd Time</TableCell>
                <TableCell>DineIn/Takeout</TableCell>
                <TableCell>Total price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <PastOrderTable row={row} index={index} snack={snack} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  )
}
