import React from "react"
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Card,
  Paper,
  Box,
  CardContent,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/styles"
const useStyles = makeStyles((theme) => ({
  requiredBox: {
    border: "1px solid #df2800",
    color: "#df2800",
    borderRadius: "5px",
    padding: "3px 2px",
    lineHeight: 0.5,
    marginRight: "5px",
    outline: "none !important",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    margin: "0 3px",
    minWidth: "fit-content",
  },
  special: {
    color: "black",
  },
}))
export default function LiveOrders({ data }) {
  const classes = useStyles()
  return (
    <ListItem alignItems="flex-start">
      <ListItemText
        primary="Live Orders"
        secondary={
          <>
            <Typography
              sx={{ display: "inline" }}
              color="textPrimary"
              component="span"
              variant="h4"
            >
              Restaurent name: {data.name}
            </Typography>
            <Typography sx={{ fontSize: 14 }} className={classes.special}>
              Total Price : ${data.totalPrice}
            </Typography>
            <Paper>
              <Typography sx={{ fontSize: 14 }}>Items Orderd:</Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflowX: "auto",
                  marginTop: "5px",
                }}
              >
                {data.items?.map((item) => (
                  <Box className={classes.requiredBox}>
                    <Card>
                      <CardContent>
                        <Typography
                          variant="h6"
                          fontSize="large"
                          style={{ lineHeight: 1.1 }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontSize="medium"
                          style={{ lineHeight: 1.1 }}
                        >
                          Price of item: {item.price}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Box>
            </Paper>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              Status: {data.status}
            </Typography>
          </>
        }
      />
    </ListItem>
  )
}
