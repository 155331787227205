import React, { useState } from "react"
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom"
import HomeScreen from "./screens/HomeScreen"
import { Box, colors, ThemeProvider, Typography } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import Login from "./components/rewards/Login"
import AddPaymentMethod from "./screens/AddPaymentMethod"
import CheckoutScreen from "./screens/CheckoutScreen"
import DishScreen from "./screens/DishScreen"
import Gpay from "./screens/Gpay"
import MenuScreen from "./screens/MenuScreen"
import RestaurantScreen from "./screens/RestaurantScreen"
import Rewards from "./screens/Rewards"
import UpdateItem from "./screens/UpdateItem"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setHighlightedCoupon } from "./actions/couponActions"
import { MyContext } from "./context"
import moment from "moment"
import theme from "./theme/index.js"
import CustomerLogin from "./screens/CustomerLoginScreen"
import CustomerDashboard from "./screens/CustomerDashboard"
import UserProfile from "./screens/UserProfile"
import { makeDecrypt } from "./utils/securels"
const dotenv = require("dotenv")
dotenv.config()

const App = () => {
  const { restaurant } = useSelector((state) => state.restaurant)
  const { authenticated } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [refreshCart, setRefreshCart] = useState(false)
  const [viewBill, setViewBill] = useState(false)
  const [restaurantOpen, setRestaurantOpen] = useState(true)
  const [showWorkingMessage, setShowWorkingMessage] = useState(false)
  const [workingHoursMessage, setWorkingHoursMessage] = useState("")
  useEffect(() => {
    if (restaurant) {
      if (restaurant.restaurantAddress.country?.toLowerCase() === "india") {
        dispatch({ type: "SET_CURRENCY_SYMBOL", payload: "₹" })
      } else if (restaurant.restaurantAddress.country?.toLowerCase() === "nepal") {
        dispatch({ type: "SET_CURRENCY_SYMBOL", payload: "रु" })
      } else {
        dispatch(setHighlightedCoupon(restaurant._id))
        dispatch({ type: "SET_CURRENCY_SYMBOL", payload: "$" })
      }
      //checking for working hours
      let date = new Date()

      let currentTime = date.toString().split(" ")[4]

      let today = moment().format("dddd")

      let todaysWorkingHours = restaurant.resWorkingHours.find(
        (eachWorkingHour) => eachWorkingHour.day.trim() === today
      )

      if (todaysWorkingHours && !todaysWorkingHours.closed) {
        let thisStart = parseInt(
          `${
            todaysWorkingHours.openingTime.split(":")[0] +
            todaysWorkingHours.openingTime.split(":")[1]
          }`
        )
        let thisEnd = parseInt(
          `${
            todaysWorkingHours.closingTime.split(":")[0] +
            todaysWorkingHours.closingTime.split(":")[1]
          }`
        )
        let thisTime = parseInt(
          `${currentTime.split(":")[0] + currentTime.split(":")[1]}`
        )

        let timeCondition = thisStart < thisTime && thisTime < thisEnd
        setRestaurantOpen(timeCondition)
        if (!timeCondition) {
          getNextWorkingDayHours()
        }
      } else {
        setRestaurantOpen(false)
        getNextWorkingDayHours()
      }
    }
  }, [restaurant])

  const getNextWorkingDayHours = () => {
    let tomorrow
    if (
      moment().isBefore(
        moment(
          restaurant.resWorkingHours[moment().day() - 1 < 0 ? 6 : moment().day()].openingTime,
          "hh:mm"
        )
      )
    )
      tomorrow = moment().format("dddd").toString()
    else tomorrow = moment().add(1, "day").format("dddd").toString()
    let tommorowsWorkingHours = restaurant.resWorkingHours.find(
      (eachWorkingHour) => eachWorkingHour.day === tomorrow
    )
    if (tommorowsWorkingHours && !tommorowsWorkingHours.closed) {
      setShowWorkingMessage(true)
      setWorkingHoursMessage(
        `Currently restaurant is closed and it will open on ${tomorrow} at ${tommorowsWorkingHours.openingTime}`
      )
    } else {
      let dayAfterTomorrow = moment().add(2, "day").format("dddd").toString()
      let dayAfterTomorrowsWorkingHours = restaurant.resWorkingHours.find(
        (eachWorkingHour) => eachWorkingHour.day === dayAfterTomorrow
      )
      if (dayAfterTomorrowsWorkingHours && !dayAfterTomorrowsWorkingHours.closed) {
        setShowWorkingMessage(true)
        setWorkingHoursMessage(
          `Currently restaurant is closed and and it will open on ${dayAfterTomorrow} at ${dayAfterTomorrowsWorkingHours.openingTime}`
        )
      } else {
        setShowWorkingMessage(true)
        setWorkingHoursMessage(
          `Currently restaurant is closed and and it will be close for next two days.`
        )
      }
    }
  }
  const location = useLocation()
  const PrivateRoute = ({ component: Component, redirectTo, isAuth, path }) => {
    if (
      location.pathname.includes("/customer") &&
      !(isAuth || makeDecrypt("auth"))
    ) {
      return <Redirect to={redirectTo} />
    }
    if ((isAuth || makeDecrypt("auth")) && location.pathname === "/customer") {
      return <Redirect to="/customer/dashboard" />
    }
    return <Route path={path} component={Component} />
  }

  return (
    <ThemeProvider theme={theme}>
      <MyContext.Provider
        value={{
          refreshCart,
          setRefreshCart,
          setViewBill,
          viewBill,
          restaurantOpen,
          showWorkingMessage,
          workingHoursMessage,
        }}
      >
        <Router>
          <Route
            path="/restaurant/:restaurantId/table/:tableNo"
            exact
            component={RestaurantScreen}
          />
          <Route
            path="/restaurant/menu/:restaurantId"
            exact
            component={MenuScreen}
          />
          <Route path="/dish/:menuId" exact component={DishScreen} />
          <Route path="/checkout/:restaurantId" exact component={CheckoutScreen} />
          <Route
            path="/checkout/:restaurantId/:orderId/:userId"
            exact
            component={CheckoutScreen}
          />
          <Route path="/update/:menuId/:objectId" exact component={UpdateItem} />
          <Route path="/add_payment" exact component={AddPaymentMethod} />
          <Route path="/rewards" exact component={Rewards} />
          <Route path="/login" exact component={Login} />
          <Route path="/search/:keyword" component={HomeScreen} exact />
          <Route path="/page/:pageNumber" component={HomeScreen} exact />
          <Route path="/google-pay" component={Gpay} exact />
          <Route
            path="/search/:keyword/page/:pageNumber"
            component={HomeScreen}
            exact
          />
          <Route path="/" component={HomeScreen} exact />
          <Route path="/customer" exact component={CustomerLogin} />
          <PrivateRoute
            isAuth={authenticated}
            path="/customer/dashboard"
            component={CustomerDashboard}
            redirectTo="/customer"
          />
          <PrivateRoute
            isAuth={authenticated}
            path="/customer/profile"
            component={UserProfile}
            redirectTo="/customer"
          />
        </Router>
      </MyContext.Provider>
    </ThemeProvider>
  )
}

export default App
