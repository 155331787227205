import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React, { useState } from "react"
import axios from "../axios"
import { makeDecrypt, makeEncrypt } from "../utils/securels"
import CustomCard from "./CustomCard"
import NativePayment from "./NativePayment"

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
})

export default function PaymentAccordion({
  setSuccessMessage,
  prevNativePaymentMethodId,
  prevNativeCardLastDigits,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  createCustomerId,
  attachPaymentMethodIdToCustomer,
  detachPaymentMethodIdFromCustomer,
  prevPaymentMethodId,
  setAlertMessage,
  isCardAdded,
  prevLast4,
  deleteLoading,
  setDeleteLoading,
  handleIsDelete,
  isLoading,
  cardElement,
}) {
  const classes = useStyles()
  const [buttonTypes, setButtonTypes] = useState({
    applePay: true,
    googlePay: true,
  })
  const [shouldLoad, setShouldLoad] = useState(true)

  const updateActivePaymentMethodId = (thisActivePaymentMethodId) => {
    axios
      .patch(`/user/${makeDecrypt("userId")}/update-active-payment-method`, {
        activePaymentMethodId: thisActivePaymentMethodId,
      })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        setAlertMessage(error.message)
        return error
      })
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" style={{ margin: "20px 0 20px 20px" }}>
        Select Payment Method
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <input
            type="radio"
            name="default"
            value={selectedPaymentMethod}
            onClick={(event) => event.stopPropagation()}
            onChange={() => {
              if (prevPaymentMethodId) {
                setSelectedPaymentMethod(prevPaymentMethodId)
                makeEncrypt("paymentMethodId", prevPaymentMethodId)
                updateActivePaymentMethodId(prevPaymentMethodId)
              }
            }}
            checked={
              selectedPaymentMethod === prevPaymentMethodId && prevPaymentMethodId
            }
            style={{ margin: "auto 5px" }}
          />
          <Typography>Debit/Credit Card</Typography>
          <img
            src="/cards.png"
            height="20px"
            width="110px"
            style={{ margin: "auto" }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <CustomCard
            isCardAdded={isCardAdded}
            prevLast4={prevLast4}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            prevPaymentMethodId={prevPaymentMethodId}
            deleteLoading={deleteLoading}
            setDeleteLoading={setDeleteLoading}
            handleIsDelete={handleIsDelete}
            isLoading={isLoading}
            cardElement={cardElement}
            tab={false}
          />
        </AccordionDetails>
      </Accordion>
      {shouldLoad ? (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions2-content"
            id="additional-actions2-header"
          >
            <input
              type="radio"
              name="native"
              value={selectedPaymentMethod}
              checked={
                selectedPaymentMethod === prevNativePaymentMethodId &&
                prevNativePaymentMethodId
              }
              onClick={(event) => event.stopPropagation()}
              onChange={() => {
                if (prevNativePaymentMethodId) {
                  setSelectedPaymentMethod(prevNativePaymentMethodId)
                  makeEncrypt("paymentMethodId", prevNativePaymentMethodId)
                  updateActivePaymentMethodId(prevNativePaymentMethodId)
                }
              }}
              style={{ margin: "auto 5px" }}
            />

            <Typography>
              {buttonTypes?.googlePay
                ? "Google Pay"
                : buttonTypes?.applePay
                ? "Apple Pay"
                : "Added Card"}
            </Typography>
            {(buttonTypes?.googlePay || buttonTypes?.applePay) && (
              <img
                src={
                  buttonTypes?.googlePay
                    ? "/gpay.png"
                    : buttonTypes?.applePay
                    ? "/applepay.png"
                    : ""
                }
                height="20px"
                width="40px"
                style={{ margin: "auto" }}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <NativePayment
              setSuccessMessage={setSuccessMessage}
              prevNativePaymentMethodId={prevNativePaymentMethodId}
              prevNativeCardLastDigits={prevNativeCardLastDigits}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              selectedPaymentMethod={selectedPaymentMethod}
              detachPaymentMethodIdFromCustomer={detachPaymentMethodIdFromCustomer}
              attachPaymentMethodIdToCustomer={attachPaymentMethodIdToCustomer}
              createCustomerId={createCustomerId}
              prevPaymentMethodId={prevPaymentMethodId}
              setAlertMessage={setAlertMessage}
              setButtonTypes={setButtonTypes}
              setShouldLoad={setShouldLoad}
              buttonTypes={buttonTypes}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        ""
      )}
    </div>
  )
}
