import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Backdrop,
  Snackbar,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import React, { useEffect, useState } from "react"
import { Formik } from "formik"
import { Helmet } from "react-helmet"
import { useDispatch } from "react-redux"
import { Link as RouterLink, useHistory } from "react-router-dom"
import * as Yup from "yup"
import { loginAction } from "../actions/loginActions"
import Otp from "../components/customer/OTP"
import firebase from "../firebase"
import "firebase/auth"
import { makeDecrypt, makeEncrypt } from "../utils/securels"
const CustomerLogin = () => {
  // const { authenticated } = useSelector(
  //   (state) => state.auth
  // );
  const [open, setOpen] = useState(false)
  const [snack, setsnack] = useState(false)
  const [msg, setmsg] = useState("")
  const [severity, setseverity] = useState("error")
  const navigate = useHistory()
  const dispatch = useDispatch()
  const [user, setuser] = useState({})
  const [showLoading, setshowLoading] = useState(false)

  useEffect(() => {
    // Verify the user went through an email link and the saved email is not null
    if (
      firebase.auth().isSignInWithEmailLink(window.location.href) &&
      !!makeDecrypt("user_email")
    ) {
      setshowLoading(true)
      // Sign the user in
      firebase
        .auth()
        .signInWithEmailLink(makeDecrypt("user_email"), window.location.href)
        .then(() => {
          makeEncrypt("auth", true)
          dispatch({ type: "SET_AUTHENTICATED", payload: true })
          setshowLoading(false)
          navigate.push("/customer/dashboard", { replace: true })
        })
        .catch((r) => console.log(r))
    }
  }, [])

  const validateEmail = (email) => {
    return Yup.string().email().isValidSync(email)
  }
  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          handleSignInSubmit()
        },
        defaultCountry: "CA",
      }
    )
  }

  const handleSignInSubmit = (userData) => {
    configureCaptcha()
    if (!isNaN(parseInt(userData.email_or_phone))) {
      const phoneNumber = "+1" + userData.email_or_phone
      const appVerifier = window.recaptchaVerifier
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          setshowLoading(false)
          setOpen(true)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      // Sign the user inelse {
      firebase
        .auth()
        .sendSignInLinkToEmail(userData.email_or_phone, {
          url: "https://dine-demo.dinify.io/customer/",
          handleCodeInApp: true,
          // dynamicLinkDomain: "dine-demo.dinify",
        })
        .then(() => {
          setseverity("success")
          setmsg("Please check your email for the SignIn link")
          setsnack(true)
          console.log("SETTTING")
          // Save the users email to verify it after they access their email
          makeEncrypt("user_email", userData.email_or_phone)
          setshowLoading(false)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const validatePhone = (phone) => {
    return Yup.number()
      .integer()
      .positive()
      .test((phone) => {
        return phone && phone.toString().length >= 8 && phone.toString().length <= 12
          ? true
          : false
      })
      .isValidSync(phone)
  }
  const handleClose = () => {
    setOpen(false)
  }

  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  return (
    <>
      <Helmet>
        <title>Login | Dinify</title>
      </Helmet>
      <Backdrop style={{ zIndex: 10000 }} open={showLoading}>
        <CircularProgress style={{ color: "black" }} />
      </Backdrop>
      {open && (
        <Otp
          user={user}
          setShowLoading={setshowLoading}
          showLoading={showLoading}
          setOpen={setOpen}
          open={open}
        />
      )}
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email_or_phone: "",
            }}
            validationSchema={Yup.object().shape({
              email_or_phone: Yup.string()
                .required("Email OR Phone is required")
                .test("email_or_phone", "Email or Phone is invalid", (value) => {
                  return (
                    validatePhone(parseInt(value ?? "0")) || validateEmail(value)
                  )
                }),
            })}
            onSubmit={(
              values,
              { setSubmitting, setFieldError, setErrors, setStatus }
            ) => {
              const userData = {
                email_or_phone: values.email_or_phone,
              }
              dispatch(loginAction(userData, navigate)).then((response) => {
                console.log(userData)
                if (response.message == "Valid user") {
                  setuser(response.user)
                  handleSignInSubmit(userData)
                  setSubmitting(false)
                  setshowLoading(true)
                } else {
                  setmsg("please use the platfrom atleast once to login")
                  setseverity("error")
                  setsnack(true)
                  setSubmitting(false)
                }
              })
            }}
          >
            {({
              errors,
              error,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                {error && <div>{error}</div>}
                <Box
                  sx={{
                    flex: "1 1 auto",
                    alignItems: "center",
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: 500,
                      px: 3,
                      py: "100px",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ mb: 3 }}>
                      <Typography color="textPrimary" variant="h2">
                        Welcome
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h5"
                        sx={{ mt: 2 }}
                      >
                        Sign in on the customer platform to track your orders
                      </Typography>
                    </Box>
                    <TextField
                      error={Boolean(
                        touched.email_or_phone && errors.email_or_phone
                      )}
                      fullWidth
                      helperText={touched.email_or_phone && errors.email_or_phone}
                      label="Email/Phone"
                      margin="normal"
                      name="email_or_phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email_or_phone}
                      variant="outlined"
                    />
                    <div id="sign-in-button"></div>
                    <Box sx={{ py: 2, position: "relative" }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Get OTP
                      </Button>
                    </Box>
                    <Snackbar
                      open={snack}
                      autoHideDuration={6000}
                      onClose={() => setsnack(false)}
                    >
                      <Alert
                        onClose={() => setsnack(false)}
                        severity={severity}
                        sx={{ width: "100%" }}
                      >
                        {msg}
                      </Alert>
                    </Snackbar>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: -12,
                          marginLeft: -12,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default CustomerLogin
