import { Button, makeStyles } from '@material-ui/core';
import DiscountIcon from '@mui/icons-material/Discount';
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
    discountCard: {
        marginRight: '10px',
        backgroundColor: 'white',
        padding: '10px 15px',
        border: "0.5px solid rgba(0, 0, 0, 0.1)",
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        transition: 'box-shadow 0.5s ease-in-out',
        borderRadius: '2px',
    },
    modal: {
        backgroundColor: 'black',
        borderRadius: '5px',
        color: 'white',
        textAlign: 'justify'
    },
    text: {
        fontSize: '10px',
        color: "#DF2800",
        fontWeight: "bold"
    },
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    boxShadow: 24,
    p: 2,
};
const DiscountCard = ({ discount }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { name, startDate, endDate, applicableFor, percentOff, amountOff, days, startTime, endTime, minOrderValue, limitPerCustomer } = discount;
    const handleCopy = () => {
        toast.success("Promo code copied!")
    }
    return (
        <>

            <Toaster />
            <button onClick={handleOpen} style={{display: "flex", gap: "10px", alignItems: "center"}}  className={classes.discountCard}>
                <DiscountIcon className={classes.text} style={{ color: '#DF2800' }} />
                <Typography className={classes.text}>
                    {name}
                </Typography>
            </button>
            {/* modal  */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className={classes.modal}>
                        <Box display={"flex"} sx={{ position: "relative" }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: 'red', margin: 'auto', align: 'center' }}>
                                {amountOff > 0 && '$'}{amountOff > 0 && amountOff} {percentOff > 0 && percentOff} {percentOff > 0 && '%'} Discount
                            </Typography>
                            <CloseIcon
                                style={{
                                    marginLeft: "auto",
                                    position: "absolute",
                                    right: "10px",
                                    cursor: "pointer",
                                    border: "0.5px solid gray",
                                    // borderRadius: '50%'
                                }}
                                onClick={() => setOpen(false)}
                            />
                        </Box>
                        <Box style={{ display: 'flex', gap: '10px', margin: '5px 0', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                Promo Code: {name}
                            </Typography>
                            <CopyToClipboard text={name}>
                                <ContentCopyIcon onClick={handleCopy} style={{ fontSize: '15px' }} />
                            </CopyToClipboard>
                        </Box>
                        <Typography id="modal-modal-description" style={{ fontSize: '13px' }}>
                            The discount is applicable on {applicableFor} {applicableFor !== 'both' && 'only'} {applicableFor === 'both' && 'Dine In and Take Out'}. Discount is valid during {startTime} and {endTime} between {startDate} to {endDate}  on {days.join(', ')} and can be used {limitPerCustomer} time(s) per customer. {minOrderValue > 0 ? `The minimum order value is $${minOrderValue}.` : "There is no minimum order requirement."}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default DiscountCard