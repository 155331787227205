export const setPaymentIntentId = (paymentIntentId) => {
  return {
    type: "SET_INTENT_PAYMENT_ID",
    payload: paymentIntentId,
  }
}

export const setPaymentMethodId = (paymentMethodId) => {
  return {
    type: "SET_PAYMENT_METHOD_ID",
    payload: paymentMethodId,
  }
}
export const isAddToBill = (value) => {
  return {
    type: "ADD_TO_BILL",
    payload: value,
  }
}
