import { Box, CircularProgress } from "@material-ui/core"
import React from "react"

const Loader = () => {
  return (
    <Box
      sx={{
        transform: "translate(-50%,-50%)",
        position: "fixed",
        top: "50%",
        left: "50%",
      }}
    >
      <CircularProgress
        animation="border"
        role="status"
        style={{
          width: "50px",
          height: "50px",
          color: "#000",
        }}
      ></CircularProgress>
    </Box>
  )
}

export default Loader
