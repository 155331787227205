let initialState = { thisUser: null, cartItems: [], authenticated: false }

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        thisUser: action.payload,
      }
    case "SET_AUTHENTICATED":
      return {
        ...state,
        authenticated: action.payload,
      }
    case "SET_CART_ITEMS":
      return {
        ...state,
        cartItems: action.payload,
      }
    case "SET_INITIAL_USER_STATE":
      return initialState
    default:
      return state
  }
}
