import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import Gpay from "../screens/Gpay"

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: "10px 5px",
    margin: "20px 0",
    width: "100%",
  },
}))

function NativePayment({
  setSuccessMessage,
  prevNativePaymentMethodId,
  prevNativeCardLastDigits,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  createCustomerId,
  attachPaymentMethodIdToCustomer,
  detachPaymentMethodIdFromCustomer,
  prevPaymentMethodId,
  setAlertMessage,
  setButtonTypes,
  setShouldLoad,
  buttonTypes,
}) {
  const classes = useStyles()

  return (
    <Box className={classes.cardContainer}>
      <Gpay
        setSuccessMessage={setSuccessMessage}
        prevNativePaymentMethodId={prevNativePaymentMethodId}
        prevNativeCardLastDigits={prevNativeCardLastDigits}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        selectedPaymentMethod={selectedPaymentMethod}
        detachPaymentMethodIdFromCustomer={detachPaymentMethodIdFromCustomer}
        attachPaymentMethodIdToCustomer={attachPaymentMethodIdToCustomer}
        createCustomerId={createCustomerId}
        prevPaymentMethodId={prevPaymentMethodId}
        setAlertMessage={setAlertMessage}
        tab={false}
        setButtonTypes={setButtonTypes}
        setShouldLoad={setShouldLoad}
        buttonTypes={buttonTypes}
      />
    </Box>
  )
}

export default NativePayment
