export const couponReducer = (
  state = { highlightedPromo: null, promos: [] },
  action
) => {
  switch (action.type) {
    case "SET_HIGHLIGHTED_PROMO":
      return {
        ...state,
        highlightedPromo: action.payload,
      }
    case "SET_PROMOS":
      return {
        ...state,
        promos: action.payload,
      }

    default:
      return state
  }
}
