import {
  Box,
  Button,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import axios from "../axios"

const useStyles = makeStyles((theme) => ({
  root: {},
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    color: "white",
    textAlign: "center",
    fontWeight: "700",
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  pos: {
    marginBottom: 12,
  },
  tabWrapper: {
    display: "flex",
    width: "100%",
    overflow: "auto",
    padding: "10px 5px",
  },
  scroller: {
    flexGrow: "0",
  },
  scrollWrap: {
    minHeight: "30px !important",
  },
  eachTab: {
    // minHeight: "30px !important",
    outline: "none !important",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    borderRadius: "10px",
    margin: "0 3px",
    cursor: "pointer",
    padding: "4px 7PX",
    minWidth: "fit-content",
  },
  activeTab: {
    outline: "none !important",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    borderRadius: "10px",
    margin: "0 3px",
    padding: "4px 7px",
    backgroundColor: "#df2800",
    color: "white",
    cursor: "pointer",
    minWidth: "fit-content",
  },
  loaderBox: {
    display: "flex",
    height: "37px",
    width: "100%",
    padding: "auto 10px",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px auto",
  },
}))

function AvailableMenus({ activeMenuId, setActiveMenuId, restId, setMenuProgress }) {
  const classes = useStyles()
  const [availableMenus, setAvailableMenus] = useState([])
  const [alertMessage, setAlertMessage] = useState("")
  const [value, setValue] = useState(0)

  const [progress, setProgress] = useState()
  const dispatch = useDispatch()
  const { menu } = useSelector((state) => state)
  const { restaurant } = useSelector((state) => state.restaurant)
  useEffect(() => {
    const getAllMainMenu = () => {
      axios
        .get(`/mainMenu/${restId}/all-menu`, {
          onDownloadProgress: (p) => setProgress((p.loaded / p.total) * 100),
        })
        .then((res) => {
          let date = new Date()
          let currentTime = date.toString().split(" ")[4]
          let menus = res.data.filter((thisMenu) => {
            let thisStart = parseInt(
              `${
                thisMenu.availability.start.split(":")[0] +
                thisMenu.availability.start.split(":")[1]
              }`
            )
            let thisEnd = parseInt(
              `${
                thisMenu.availability.end.split(":")[0] +
                thisMenu.availability.end.split(":")[1]
              }`
            )
            let thisTime = parseInt(
              `${currentTime.split(":")[0] + currentTime.split(":")[1]}`
            )
            return (
              thisStart < thisTime &&
              thisTime < thisEnd &&
              thisMenu.status == "Online"
            )
          })
          setAvailableMenus(menus)
          dispatch({ type: "SET_AVAILABLE_MENUS", payload: menus })
          !menus.length && setMenuProgress(100)
        })
        .catch((error) => setAlertMessage(error.message))
    }
    if (!menu?.thisAvailableMenus?.length) {
      return getAllMainMenu()
    } else {
      setAvailableMenus(menu?.thisAvailableMenus)
      setMenuProgress(100)
    }
  }, [])

  useEffect(() => {
    if (!activeMenuId && availableMenus.length)
      if (restaurant?.specialDish?.length) setActiveMenuId("special")
      else setActiveMenuId(availableMenus[0]._id)
  }, [availableMenus.length])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      {availableMenus.length ? (
        <Box display={"flex"} className={classes.tabWrapper}>
          {restaurant?.specialDish?.length ? (
            <Box
              // key={index}
              // label={eachMenu.name}
              onClick={() => {
                setActiveMenuId("special")
              }}
              className={
                activeMenuId == "special" ? classes.activeTab : classes.eachTab
              }
            >
              <Typography>Featured</Typography>
            </Box>
          ) : (
            ""
          )}
          {availableMenus.map((eachMenu, index) => {
            return (
              <>
                <Box
                  key={index}
                  label={eachMenu.name}
                  onClick={() => {
                    setActiveMenuId(eachMenu._id)
                  }}
                  className={
                    activeMenuId == eachMenu._id
                      ? classes.activeTab
                      : classes.eachTab
                  }
                >
                  <Typography>{eachMenu.name}</Typography>
                </Box>
              </>
            )
          })}
        </Box>
      ) : progress != 100 ? (
        <Box className={classes.loaderBox}>
          <LinearProgress style={{ width: "95%" }} />
        </Box>
      ) : (
        <Box sx={{ p: 1, textAlign: "center" }}>
          <Typography>Menu not available</Typography>
        </Box>
      )}
    </div>
  )
}

export default AvailableMenus
