import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Tab,
  Popper,
  Tabs,
  Zoom,
  Typography,
  Popover,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardTabIcon from "@material-ui/icons/KeyboardTab"
import CloseIcon from "@material-ui/icons/Close"
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import Skeleton from "@material-ui/lab/Skeleton"
import "animate.css"
import clsx from "clsx"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { io } from "socket.io-client"
import { setRestaurant } from "../actions/restaurantActions"
import { setCurrentUser } from "../actions/userActions"
import axios from "../axios"
import CouponHighlighter from "../components/CouponHighlighter"
import MenuItem from "../components/MenuItem"
import MenuItems from "../components/MenuItems"
import MenuItemsSkeleton from "../components/MenuItemsSkeleton"
import TopBar from "../components/TopBar"
import useLazyLoad from "../components/useLazyLoad"
import ViewBill from "../components/ViewBill"
import { MyContext } from "../context"
import { makeDecrypt } from "../utils/securels"
import AvailableMenus from "./AvailableMenus"
import DishScreen from "./DishScreen"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: "80vh",
    overflow: "scroll",
    flex: 2,
    marginTop: "8px",
  },
  box: {
    height: "100vh",
    width: "200px",
    backgroundColor: "red",
    margin: "20px",
  },
  menuTopBar: {
    position: "sticky",
    top: (highlightedPromo) => (highlightedPromo ? "45px" : 0),
    left: 0,
    flex: 1,
    right: 0,
    backgroundColor: "white",
    zIndex: "11",
    boxShadow:
      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
  },
  tabs: {
    position: "static",
    left: 0,
    right: 0,
    backgroundColor: "white",
    zIndex: "11",
    color: theme.palette.black,
    justifyContent: "center",
    minHeight: "20px !important",
    "& .Mui-selected": {
      color: "white",
      backgroundColor: "black",
      textDecoration: "none !important",
    },
  },
  scroller: {
    flexGrow: "0",
  },
  tab: {
    margin: "5px ",
    minHeight: "20px !important",
    color: theme.palette.text.secondary,
    textdecoration: "none",
    textAlign: "left",
    paddingLeft: "0",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "& .MuiTab-wrapper": {
      alignItems: "baseline",
    },
  },
  slideWrap: {
    width: "100%",
    height: "250px",
    position: "fixed",
    right: "0",
    bottom: "0",
    zIndex: "999",
    backgroundColor: "white",
    padding: "10px",
  },
  poperBox: {
    position: "fixed",
    // bottom: 0,
    bottom: "50%",
    top: "50%",
    right: 0,
  },
  menuBtnBox: {
    display: "flex",
    flexDirection: "column",
    transition: "all 0.3s",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    width: "25px",
    height: "80px",
    backgroundColor: "#df2800",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
}))

const MenuScreen = ({ match }) => {
  const [showOption, setShowOption] = useState(true)
  const categoryRef = React.useRef()
  const { highlightedPromo } = useSelector((state) => state.coupon)
  const { restaurant } = useSelector((state) => state.restaurant)
  const classes = useStyles(highlightedPromo ? true : false)
  const [value, setValue] = React.useState(0)
  const { thisUser } = useSelector((state) => state.user)
  const { menu } = useSelector((state) => state)
  const [menuItems, setMenuItems] = useState([])
  const [categories, setCategories] = useState([])
  const [alertMessage, setAlertMessage] = useState("")
  const [activeMenuId, setActiveMenuId] = useState()
  const [tags, setTags] = useState([])
  const [showTags, setShowTags] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [selectedTag, setSelectedTag] = useState([])
  const [menuProgress, setMenuProgress] = useState()
  const [tagsProgress, setTagsProgress] = useState()
  const [showPopularity, setShowPopularity] = useState(false)
  const [showCategories, setShowCategories] = useState(false)
  const [showDishScreen, setShowDishScreen] = useState(false)
  const [activeItem, setActiveItem] = useState()
  const [popopen, setPopopen] = useState(null)
  const [first, setfirst] = useState(true)
  // const [viewBill, setViewBill] = useState(false)
  const itemLimit = 5
  const [totalMenuItems, setTotalMenuItems] = useState()
  const [loadingCat, setLoadingCat] = useState()
  const [emptyCategories, setEmptyCategories] = useState([])
  const triggerRef = useRef(null)
  const [cartItems, setCartItems] = useState()
  const [progress, setProgress] = useState(0)
  const [searchItems, setSearchItems] = useState([])
  const [tempSocket, setTempSocket] = useState(null)
  const socket = useRef()
  const dispatch = useDispatch()
  const { viewBill, setViewBill } = useContext(MyContext)
  const [translatedItem, setTranslatedItem] = useState()

  useEffect(() => {
    if (menuItems?.length && first) {
      setPopopen(categoryRef.current)
      const timer = setTimeout(() => setPopopen(null), 2000)
      setfirst(false)
      return () => clearTimeout(timer)
    }
  }, [menuItems])

  const getCategoryItems = (category) => {
    if (!emptyCategories?.some((cat) => cat == category)) {
      axios
        .post(
          `/mainMenu/${match.params.restaurantId}/${activeMenuId}/get-category-items`,
          {
            category: category,
          }
        )
        .then((data) => {
          let items = data.data
          setLoadingCat("")
          if (items.length)
            items.map((item) => {
              if (
                !menuItems.some((menuItem) => {
                  return menuItem._id == item._id
                })
              ) {
                setMenuItems((prev) => [...prev, item])
              }
            })
          else {
            setEmptyCategories((preValue) => [...preValue, category])
          }
        })
        .catch((error) => console.log(error))
    }
  }

  const handleChange = (event, newValue) => {
    // setLoadingCat(newValue)
    setValue(newValue)
    setShowCategories(false)
    // getCategoryItems(newValue)
  }

  useEffect(() => {
    const makeConnection = () => {
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      })
      setTempSocket(socket)
    }
    window.addEventListener("hashchange", function () {
      window.scrollTo(window.scrollX, window.scrollY - 190)
    })

    if ((makeDecrypt("user") || makeDecrypt("userId")) && !thisUser) {
      axios
        .post(
          `/user/user-details`,
          {
            phone: makeDecrypt("user"),
            userId: makeDecrypt("userId"),
          },
          {
            onUploadProgress: (progressEvent) => {
              let tempProgress = (progressEvent.loaded / progressEvent.total) * 100
              setProgress(tempProgress)
            },
          }
        )
        .then((res) => {
          // if (res.data.cart.restaurant.length > 1) {
          //   res.data.cart.items = res.data.cart.items.filter(
          //     (i) => i.isOrdered == false
          //   )
          // }
          // console.log("ITEMS", res.data.cart.items)
          dispatch(setCurrentUser(res.data))
          const cartItems = res.data.cart.items.filter(
            (item) => item.restaurant == makeDecrypt("restaurantId")
          )
          setCartItems(cartItems)
          if (cartItems.length) setViewBill(true)
          else setViewBill(false)
        })
        .catch((error) => {
          setCartItems([])
        })
    } else if (thisUser) {
      // console.log("CONDITION", thisUser)
      // let temp = thisUser.cart.items
      // if (thisUser.cart.restaurant.length > 1) {
      //   temp = thisUser.cart.items.filter((i) => i.isOrdered == false)
      //   console.log("CONDITION", temp)
      // }
      const cart_Items = thisUser.cart.items.filter(
        (item) => item.restaurant == makeDecrypt("restaurantId")
      )
      setCartItems(cart_Items) //thisUser.cart.items
      if (cart_Items.length) setViewBill(true)
      else setViewBill(false)
      setProgress(100)
    } else {
      setProgress(100)
      setCartItems([])
    }

    makeConnection()
    return window.removeEventListener("hashchange", function () {
      window.scrollTo(window.scrollX, window.scrollY - 190)
    })
  }, [])

  /**
   * getting menu items and categories for the main menu type
   *
   */
  useEffect(() => {
    setMenuProgress(0)
    setMenuItems([])
    setCategories([])
    const getMenuItems = async () => {
      try {
        const menuData = await axios.post(
          `mainMenu/${
            match.params.restaurantId
          }/${activeMenuId}/get-menu-items-and-categories?page=${1}&limit=${
            restaurant?.lazyLoading && itemLimit
          }`,
          selectedTag.length > 0 && { selectedTag: selectedTag },
          {
            onDownloadProgress: (p) => {
              setMenuProgress((p.loaded / p.total) * 100)
            },
          }
        )
        setMenuItems(menuData.data.menuItems)
        setCategories(menuData.data.thisCategories)
        setTotalMenuItems(menuData.data.totalMenuItems)
        performDispatches(menuData)
      } catch (error) {
        setMenuProgress(100)
        setAlertMessage(error.message)
      }
    }

    const getFeaturedItems = async () => {
      let temp = []
      axios
        .post(`/menu/${makeDecrypt("restaurantId")}/get-daily-special-items`, {
          menuItems: restaurant?.specialDish,
        })
        .then((specials) => {
          axios
            .get(`/menu/${makeDecrypt("restaurantId")}/get-items-with-discount`)
            .then((discounted) => {
              var ids = new Set(specials.data.map((d) => d._id))
              temp = [
                ...specials.data,
                ...discounted.data.filter((d) => !ids.has(d._id)),
              ]
              // setMenuItems(temp)
              // setProgress(100)
              // setCategories(["discounted", "featured"])
              setMenuItems(temp)
              setProgress(100)
              setCategories([
                {
                  name: "Featured",
                  status: "Online",
                  sequence: 1,
                  printer: "62299cbcfda6ef0eb37331a2",
                  _id: "62b0dc4cb3cb24ba9312ebe2",
                },
              ])
            })
        })
    }

    //   const getFilteredMenuItems = async () => {
    //   try {
    //     const menuData = await axios.post(
    //       `mainMenu/${match.params.restaurantId}/${activeMenuId}/get-menu-items-and-categories`,
    //       selectedTag.length>0 && {selectedTag: selectedTag}
    //     )
    //     setMenuItems(menuData.data.menuItems)
    //     setCategories(menuData.data.categories)
    //   } catch (error) {
    //     setAlertMessage(error.message)
    //   }
    // }

    const getRestaurant = () => {
      dispatch(setRestaurant(match.params.restaurantId))
    }

    if (!restaurant) getRestaurant()
    if (
      activeMenuId &&
      menu[activeMenuId] &&
      menu[activeMenuId].thisTotalMenuItems
    ) {
      setMenuItems(menu[activeMenuId].thisMenuItems)
      setCategories(menu[activeMenuId].thisCategories)
      setTotalMenuItems(menu[activeMenuId].thisTotalMenuItems)
      setMenuProgress(100)
    } else if (activeMenuId && !menu[activeMenuId]?.thisTotalMenuItems) {
      if (activeMenuId == "special" || !activeMenuId) getFeaturedItems()
      else getMenuItems()
    }
  }, [activeMenuId, isFilter])

  const setFilter = () => {
    setIsFilter(true)
    axios
      .post(`/menu/${makeDecrypt("restaurantId")}/get-filtered-items`, {
        selectedTag: selectedTag,
      })
      .then((response) => {
        setMenuItems(response.data)
      })
    setShowTags(false)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }
    setShowTags(open)
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {tags.map((tag, index) => (
          <ListItem
            button
            key={tag}
            onClick={() => {
              setFilter(tag)
            }}
          >
            <ListItemText primary={tag} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  const performDispatches = (menuData) => {
    try {
      dispatch({
        type: "SET_MENU_ITEMS",
        payload: { activeMenuId, menuItems: menuData.data.menuItems },
      })
      dispatch({
        type: "SET_CATEGORIES",
        payload: { activeMenuId, categories: menuData.data.thisCategories },
      })
      dispatch({
        type: "SET_TOTAL_NUMBER_MENU_ITEMS",
        payload: {
          activeMenuId,
          totalMenuItems: menuData.data.totalMenuItems,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  const onGrabData = (currentPage) => {
    return new Promise((resolve) => {
      activeMenuId &&
        axios
          .post(
            `mainMenu/${
              match.params.restaurantId
            }/${activeMenuId}/get-menu-items-and-categories?page=${
              currentPage + 1
            }&limit=${itemLimit}`,
            selectedTag.length > 0 && { selectedTag: selectedTag },
            {
              onDownloadProgress: (p) => {
                setMenuProgress((p.loaded / p.total) * 100)
              },
            }
          )
          .then((menuData) => {
            let temp = []
            setMenuItems((preValue) => {
              temp = [...preValue, ...menuData.data.menuItems]
              return temp
            })
            setCategories(menuData.data.thisCategories)
            setTotalMenuItems(menuData.data.totalMenuItems)
            menuData.data.menuItems = temp
            performDispatches(menuData)
            const data = menuData.data.menuItems
            resolve(data)
          })
          .catch((error) => {
            setMenuProgress(100)
            setAlertMessage(error.message)
          })
    })
  }

  const { data, loading } = useLazyLoad({ triggerRef, onGrabData, activeMenuId })

  return (
    <>
      <Helmet>
        <title>Menu | {restaurant ? restaurant.name : ""}</title>
      </Helmet>
      <CouponHighlighter />
      <Box
        style={{
          display: "inline-flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        <Box className={classes.menuTopBar}>
          <TopBar
            screen="menu"
            setSearchItems={setSearchItems}
            showOption={showOption}
            setShowOption={setShowOption}
            setTags={setTags}
            showTags={showTags}
            setShowTags={setShowTags}
            setTagsProgress={setTagsProgress}
          />

          <AvailableMenus
            activeMenuId={activeMenuId}
            setActiveMenuId={setActiveMenuId}
            restId={match.params.restaurantId}
            setMenuProgress={setMenuProgress}
          />
        </Box>
        <Drawer anchor={"bottom"} open={showTags} onClose={() => setShowTags(false)}>
          <Paper elevation={4} className={classes.slideWrap}>
            <HighlightOffIcon
              size="large"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => setShowTags(false)}
            />
            <Typography variant="h6">Dietary Preference</Typography>
            <Divider style={{ marginBottom: "10px" }} />
            <Box sx={{ height: "150px", overflow: "auto" }}>
              {!tags.length ? (
                tagsProgress == 100 ? (
                  <Box
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: " translate(-50%,-50%)",
                    }}
                  >
                    <Typography>No filters availble</Typography>
                  </Box>
                ) : (
                  <Box
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: " translate(-50%,-50%)",
                    }}
                  >
                    <CircularProgress color="black" />
                  </Box>
                )
              ) : (
                tags.map((tag, index) => (
                  <Button
                    variant={selectedTag.includes(tag) ? "contained" : "outlined"}
                    color="primary"
                    size="small"
                    onClick={() => {
                      selectedTag.includes(tag)
                        ? setSelectedTag(selectedTag.filter((item) => item !== tag))
                        : setSelectedTag((prevValues) => [...prevValues, tag])
                    }}
                    style={{ margin: "5px" }}
                    key={index}
                  >
                    {tag}
                  </Button>
                ))
              )}
            </Box>
            <Divider style={{ mt: "auto" }} />
            <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setSelectedTag([])
                  setIsFilter(false)
                  setShowTags(false)
                }}
              >
                RESET FILTER
              </Button>

              <Button
                variant="contained"
                size="small"
                disabled={selectedTag.length == 0}
                onClick={() => setFilter()}
              >
                SET FILTER
              </Button>
            </Box>
          </Paper>
        </Drawer>
        <Box sx={{ px: 1.5 }} className={classes.paper}>
          {!searchItems.length ? (
            progress == 100 && menuItems.length && categories.length ? (
              <>
                <MenuItems
                  menuItems={menuItems}
                  categories={categories}
                  showPopularity={showPopularity}
                  tempSocket={tempSocket}
                  setShowDishScreen={setShowDishScreen}
                  setActiveItem={setActiveItem}
                  loadingCat={loadingCat}
                  emptyCategories={emptyCategories}
                  cartItems={cartItems}
                  setTranslatedItem={setTranslatedItem}
                />
                {restaurant?.lazyLoading &&
                  (menuItems.length < totalMenuItems ? (
                    !loadingCat && (
                      <div
                        ref={triggerRef}
                        className={clsx("trigger", { visible: loading })}
                      >
                        <Skeleton
                          variant="rect"
                          width="100%"
                          height={94}
                          style={{ marginBottom: "10px" }}
                        />
                        <Typography style={{ textAlign: "center" }}>
                          Loading...
                        </Typography>
                      </div>
                    )
                  ) : (
                    <Typography style={{ textAlign: "center", marginTop: "20px" }}>
                      You reached at the bottom
                    </Typography>
                  ))}
                <Box sx={{ height: "380px" }}></Box>
              </>
            ) : menuProgress == 100 ? (
              <Box sx={{ padding: "25px", textAlign: "center" }}>
                <Typography>Menu Items Not Available</Typography>
              </Box>
            ) : (
              <MenuItemsSkeleton />
            )
          ) : (
            <Box>
              {searchItems?.map((menuItem) => {
                return (
                  <MenuItem
                    menuItem={menuItem}
                    key={menuItem._id}
                    showPopularity={showPopularity}
                    tempSocket={tempSocket}
                    setShowDishScreen={setShowDishScreen}
                    setActiveItem={setActiveItem}
                    setTranslatedItem={setTranslatedItem}
                  />
                )
              })}
            </Box>
          )}
        </Box>
        {(makeDecrypt("checkout") || viewBill) && (
          <ViewBill menuScreen={true} match={match} />
        )}

        <Box className={classes.poperBox}>
          <Tooltip
            title="See Categories"
            placement="left"
            arrow={true}
            TransitionComponent={Zoom}
          >
            <Box
              ref={categoryRef}
              aria-describedby={popopen ? "simple-popover" : undefined}
              className={[
                classes.menuBtnBox,
                "animate__animated",
                "animate__slideInRight",
                "animate__delay-2s",
                // "animate__slow	1s",
              ]}
              onClick={() => setShowCategories(true)}
            >
              <KeyboardArrowLeftIcon
                style={{ color: "#F4F6F8", fontSize: "25px", fontWeight: "600" }}
                fontSize="25px"
              />
            </Box>
          </Tooltip>
          <Box>
            <Drawer
              anchor={"right"}
              open={showCategories}
              onClose={() => setShowCategories(false)}
            >
              <Box height={"200px"} width="210px" padding={"10px"}>
                <Typography variant="h6" style={{ fontSize: "17px" }}>
                  CATEGORIES
                </Typography>
                <CloseIcon
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  onClick={() => setShowCategories(false)}
                  onTouchStart={() => setShowCategories(false)}
                />
                <Divider />
                {categories.length ? (
                  !searchItems.length && (
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="primary"
                      variant="scrollable"
                      TabIndicatorProps={{
                        style: {
                          display: "none",
                        },
                      }}
                      linkBarStyle={{ backgroundColor: "blue" }}
                      id="tab"
                      orientation="vertical"
                    >
                      {categories.map((category, index) => {
                        return (
                          <Tab
                            label={category.name}
                            key={index}
                            href={`#${category.name}`}
                            className={classes.tab}
                            name={category.name}
                            value={category.name}
                          />
                        )
                      })}
                    </Tabs>
                  )
                ) : menuProgress == 100 ? (
                  activeMenuId ? (
                    <Box sx={{ p: 1, textAlign: "center" }}>
                      <Typography>Categories not available for this menu</Typography>
                    </Box>
                  ) : (
                    ""
                  )
                ) : (
                  <>
                    {[1, 2, 3, 4, 5].map((skeleton, index) => (
                      <Typography component="div" variant={"h4"} key={index}>
                        <Skeleton />
                      </Typography>
                    ))}
                  </>
                )}
              </Box>
            </Drawer>
          </Box>
        </Box>
        <Popover
          open={Boolean(popopen)}
          onClose={() => {
            setPopopen(null)
          }}
          anchorEl={popopen}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <Typography
            color="primary"
            variant="subtitle2"
            display="inline"
            align="center"
          >
            Categories
          </Typography>
          <KeyboardTabIcon />
        </Popover>
        <Drawer
          anchor={"bottom"}
          open={showDishScreen}
          id="dishContainer"
          onClose={() => setShowDishScreen(false)}
          PaperProps={{ square: false }}
        >
          <Box sx={{ maxHeight: "70vh" }}>
            <DishScreen
              item={activeItem}
              menuId={activeItem?._id}
              setShowDishScreen={setShowDishScreen}
              setViewBill={setViewBill}
              translatedItem={translatedItem}
            />
          </Box>
        </Drawer>
      </Box>
    </>
  )
}
export default MenuScreen;