var SecureLS = require("secure-ls")

var ls = new SecureLS({ isCompression: false })

export const makeEncrypt = (name, value) => {
  ls.set(name, value) // set key1
}

export const makeDecrypt = (name) => {
  return ls.get(name)
}

export const deleteFromLocal = (name) => {
  return ls.remove(name)
}
