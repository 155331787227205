import {
  Backdrop,
  Box,
  Button,
  InputLabel,
  Chip,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { MenuItem as MenuDrop } from "@material-ui/core"
// import {MenuItem} from "@material-ui/core"
import dayjs from "dayjs"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import CancelIcon from "@material-ui/icons/Cancel"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import InfoIcon from "@material-ui/icons/Info"
import PaymentIcon from "@material-ui/icons/Payment"
import RestaurantIcon from "@material-ui/icons/Restaurant"
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu"
import { Rating } from "@material-ui/lab"
import clsx from "clsx"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { Steps } from "intro.js-react"
import "intro.js/introjs.css"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { io } from "socket.io-client"
import { getCurrentOrder } from "../actions/orderActions"
import { setRestaurant } from "../actions/restaurantActions"
import { isAddToBill, setPaymentIntentId } from "../actions/stripeActions"
import { setCurrentUser as userAction } from "../actions/userActions"
import { default as axios } from "../axios"
import CouponHighlighter from "../components/CouponHighlighter"
import LoadingCheckoutScreen from "../components/LoadingCheckoutScreen"
import MenuItem from "../components/MenuItem"
import OrderTable from "../components/OrderTable"
import PciBox from "../components/PciBox"
import TopBar from "../components/TopBar"
import { MyContext } from "../context"
import Approval from "../images/approval.png"
import "../styles/intro.css"
import { Toursteps } from "../utils/Data.js"
import { makeDecrypt, makeEncrypt } from "../utils/securels"
import { RapdelBizOrder } from "@rapdel/bizdelivery"
import toast, { Toaster } from "react-hot-toast"
import { getDiscount } from "../actions/discountActions.js"
import ReactPixel from "react-facebook-pixel"

const useStyles = makeStyles((theme) => ({
  tipBtnContainer: {
    margin: "7px auto 20px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  tipBtn: {
    borderRadius: "5px",
    margin: "0 5px",
    border: "1px solid black !important",
    color: "black !important",
    "&:focus": {
      backgroundColor: "black !important",
      border: "1px solic blue !important",
    },
    "&:hover": {
      backgroundColor: "black !important",
      color: "black !important",
    },
  },
  activeTipBtn: {
    borderRadius: "5px",
    margin: "0 5px",
    border: "1px solid black !important",
    backgroundColor: "black !important",
    color: "white !important",
  },
  tipInputBtn: {
    height: "32px",
    // width: "60px",
    paddingLeft: "7px",
    border: "1px solid black",
    borderRadius: "5px",
    "&:active": {
      outlineColor: "#DF2800",
    },
  },
  tipInput: {
    height: "38px",
    width: "83px",
    marginLeft: "auto",
    color: "black",
    border: "1px solid black !important",
    "&:hover": {
      outline: "1px solid black !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px solid black !important",
    },
  },
  card: {
    width: "30px",
    height: "30px",
    marginRight: "20px",
  },
  input: {
    width: "100%",
    margin: "10px 0",
  },
  billBtnBox: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    padding: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundImage:
      "linear-gradient(to bottom, rgba(255,255,255,0.7), rgba(255,255,255,1))",
    paddingTop: "30px",
    zIndex: "10",
  },
  addBillBtn: {
    marginTop: "10px",
    width: "100%",
    height: "45px",
    boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    // backgroundImage:
    //   "linear-gradient(to bottom, rgba(255,255,255,0.7), rgba(255,255,255,1))",
  },
  orderStatus: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.text.secondary,
    color: "#fff",
    fontSize: "10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: "5px",
  },
  waitingBox: {
    backgroundColor: "#000",
    padding: "10px ",
    display: "flex",
  },
  checkIcon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  overlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
  },
  approvalIcon: {
    width: "35px",
    height: "35px",
    padding: "7px",
    animation: " pulse 1s infinite ease-in-out ",
  },
  "@keyframes pulse": {
    from: { transform: "scale(-10)" },
    to: { transform: "scale(100)" },
  },
}))

const CheckoutScreen = ({ onMenuScreen, match }) => {
  const dispatch = useDispatch()
  const { reload, thisOrder } = useSelector((state) => state.orderList)
  const { restaurant, currencySign } = useSelector((state) => state.restaurant)
  const classes = useStyles()
  const [totalPrice, setTotalPrice] = useState(0)
  const [tip, setTip] = useState(0)
  const [tax, setTax] = useState(0)
  const [finalValue, setFinalValue] = useState(0)
  const [open, setOpen] = React.useState(false)
  const [last4, setLast4] = useState("")
  const [openInfo, setOpenInfo] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [openEmailAlert, setOpenEmailAlert] = useState(false)
  const [currentCartItems, setCurrentCartItems] = useState([])
  const [tipPercentage, setTipPercentage] = useState(0)
  const [alertMessage, setAlertMessage] = useState("")
  const [orderIndex, setOrderIndex] = useState(0)
  const [status, setStatus] = useState("")
  const socket = useRef()
  const [values, setValues] = useState({
    paymentIntentId: "",
    isCompleteOrder: false,
    email: "",
    table: makeDecrypt("tableNo"),
  })
  const history = useHistory()
  const { email, table } = values
  const [alertOpen, setAlertOpen] = useState(false)
  const [arrayLength, setArrayLength] = useState(0)
  const [currentUser, setCurrentUser] = useState(null)
  const [customerName, setCustomerName] = useState("")
  const [orderForPrint, setOrderForPrint] = useState(null)
  const [taxTypes, setTaxTypes] = useState(null)
  const [updatedTip, setUpdatedTip] = useState(0)
  const [taxValue, setTaxValue] = useState(0)
  const [readytoPrint, setReadyToPrint] = useState(false)
  const [orderBtnClicked, setOrderBtnClicked] = useState(false)
  const [completeOrderBtnClicked, setCompleteOrderBtnClicked] = useState(false)
  const [pstValue, setPstValue] = useState(0)
  const [actualDataToPrint, setActualDataToPrint] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [snackOpen, setSnackOpen] = useState(true)
  const [activeTip, setActiveTip] = useState("")
  const [showReviewBox, setShowReviewBox] = useState(false)
  const [stars, setStars] = useState(0)
  const [feedBackMsg, setFeedbackMsg] = useState("")
  const [showFeedback, setShowFeedback] = useState(false)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [errorStatus, setErrorStatus] = useState("")
  const [steps, setSteps] = useState([])
  const [havePromo, setHavePromo] = useState(false)
  const [availablePromoCodes, setAvailablePromoCodes] = useState([])
  const [inputPromo, setInputPromo] = useState("")
  const [promoValid, setPromoValid] = useState()
  const [showPromoMsg, setShowPromoMsg] = useState()
  const [coupon, setCoupon] = useState(null)
  const [discount, setDiscount] = useState(0)
  const [tempSocket, setTempSocket] = useState(null)
  const [showTour, setShowTour] = useState(false)
  const [isFirstTimeOrder, setIsFirstTimeOrder] = useState(false)
  const [showRestrictionMsg, setShowRestrictionMsg] = useState()
  const [restrictionMsg, setRestrictionMsg] = useState(false)
  const { highlightedPromo, promos } = useSelector((state) => state.coupon)
  const { instructions } = useSelector((state) => state.restaurant)
  const { thisUser, cartItems } = useSelector((state) => state.user)
  const { thisCustomer } = useSelector((state) => state.stripe)
  const { discounts } = useSelector((state) => state.discount)
  const [stripeCustomer, setStripeCustomer] = useState(null)
  const [waitingNumber, setWaitingNumber] = useState()
  const [showWait, setShowWait] = useState()
  const [dinifySidePayments, setDinifySidePayments] = useState(true)
  const [forceOnline, setforceOnline] = useState(false)
  const [useRazorpay, setUseRazorpay] = useState(false)
  const [appliedFee, setAppliedFee] = useState(0)
  const [addItems, setAddItems] = useState(false)
  const [paythroughApp, setPayThroughApp] = useState(false)
  const [orderKind, setOrderKind] = useState("")
  const [showAcceptMsg, setShowAcceptMsg] = useState()
  const [showCancelMsg, setShowCancelMsg] = useState()
  const [placingOrder, setPlacingOrder] = useState()
  const [orderUpdated, setOrderUpdated] = useState(false)
  const [gstValue, setGstValue] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  const [deliveryCharge, setDeliveryCharge] = useState(0)
  const [deliveryOrderNumber, setDeliveryOrderNumber] = useState("")
  const [deliveryErrorMessage, setDeliveryErrorMessage] = useState("")
  const [openDeliveryErrorInfo, setDeliveryErrorInfo] = useState(false)
  const [unitNo, setUnitNo] = useState("")
  const [deliveryInstructions, setDeliveryInstructions] = useState("")
  const { refreshCart, setRefreshCart } = useContext(MyContext)
  const [isValidEmail, setIsValidEmail] = useState(true)

  const setup = {
    googleApiKey: "AIzaSyB3LZjKonBqqhSHVtPB3s7_2NCv3GHVP8I",
    rapdelApiKey: "f3ef3551-05e7-4c40-be9e-6564bf4498b8",
    sandbox: true, // for testing in sandbox remove this once ready for prod
  }
  const userDetails = {
    addressLine3: unitNo,
    phone: makeDecrypt("user"),
    name: customerName,
    email: email,
    specialInstructions: deliveryInstructions,
  }

  const projectSetup = {
    hideDeliveryCharges: true,
    showAddessOnly: true,
    hideButton: true,
    userDetails, // this will auto fill user details
    onDelveryChargeChange: (deliveryCharge) => {
      setDeliveryCharge(deliveryCharge.totalWithTax)
      if (deliveryCharge.name === "Error") {
        setDeliveryErrorMessage(deliveryCharge.message)
        setDeliveryErrorInfo(true)
        setDeliveryCharge(0)
      }
      if (!deliveryErrorMessage === "") {
        setDeliveryErrorInfo(true)
      }
    },
    onSuccessfulOrder: (order) => {
      setDeliveryOrderNumber(order.orderId)
    },
  }

  const rapdelOrderRef = useRef(null)

  // const RapdelBizOrder = RapdelBizDelivery(setup,{});

  useEffect(() => {
    ReactPixel.track("VisitCheckoutPage", { info: "User visits checkout screen" })
    const makeConnection = () => {
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      })
      setTempSocket(socket)
      socket.current.on("orderStatus", (data) => {
        if (makeDecrypt("orderId") === data.id)
          if (data.status === "Accepted") {
            setShowAcceptMsg(true)
            dispatch(getCurrentOrder(makeDecrypt("orderId")))
            setRefreshCart(true)
          }
        if (data.status === "Canceled") {
          setShowCancelMsg(true)
          handleClose()
        }
      })
      socket.current.on("complete-order", (data) => {
        if (makeDecrypt("orderId") === data.orderId) {
          setOpen(true)
          setOrderIndex(data.seqValue ? data.seqValue : data.orderId)
        }
      })
      if (!makeDecrypt("totalPrice")) setOpenInfo(true)
      socket.current.on("added-item", (data) => {
        if (makeDecrypt("orderId") === data.orderId) {
          makeEncrypt("paymentIntentId", data.paymentIntentId)
          setOpenDialog(true)
        }
      })
      socket.current.on("order-is-live", (data) => {
        if (data.userId == makeDecrypt("userId")) {
          localStorage.removeItem("waitingNumber")
          setWaitingNumber()
        }
      })

      socket.current.on("refresh-queue", (data) => {
        let user = data.tempWaitingOrders.find(
          (userId) => userId == makeDecrypt("userId")
        )
        if (user) getWaitingNumber()
      })
      socket.current.on("orderUpdate", (data) => {
        if (
          data.restaurantId == match.params.restaurantId &&
          data.userId === makeDecrypt("userId")
        ) {
          setStatus("Order Updated")
          handleReload(true)
          setRefreshCart(true)
          setShowAcceptMsg(true)
          setOrderUpdated(true)
        }
      })
    }
    if (localStorage.getItem("userName")) setCustomerName(makeDecrypt("userName"))
    loadScript("https://checkout.razorpay.com/v1/checkout.js")
    setStatus("Scroll down to start your bill")
    if (instructions.length) {
      setSteps(instructions)
    } else {
      axios.get("https://strapi.dinify.io/instructions").then((res) => {
        setSteps(res.data)
        dispatch({ type: "SET_INSTRUCTIONS", payload: res.data })
      })
    }

    makeDecrypt("discount") && setDiscount(makeDecrypt("discount"))
    return makeConnection()
  }, [deliveryCharge])

  useEffect(() => {
    setAvailablePromoCodes(discounts)
  }, [discounts])
  useEffect(() => {
    makeEncrypt("waitingNumber", waitingNumber)
  }, [waitingNumber])

  /**
   * setting tax types
   */
  useEffect(() => {
    if (restaurant) {
      const { GST, PST } = restaurant.taxTypes
      setTaxTypes({
        GST,
        PST,
      })
      setTax(parseFloat(GST))
      setDinifySidePayments(
        restaurant.dinifySidePayments === undefined ||
          makeDecrypt("tableNo") == "takeout"
          ? true
          : restaurant.dinifySidePayments
      )
      setforceOnline(
        restaurant.force_OnlinePayments === undefined
          ? true
          : restaurant.force_OnlinePayments
      )
      if (
        (restaurant.dinifySidePayments && !makeDecrypt("orderId")) ||
        makeDecrypt("tableNo") == "takeout"
      )
        setPayThroughApp(true)
      setUseRazorpay(restaurant.restaurantAddress.country?.toLowerCase() === "india")
      if (
        restaurant.orderThrottling.active &&
        makeDecrypt("tableNo") === "takeout"
      ) {
        axios
          .get(`/order/get-orders-in-progress/${match.params.restaurantId}`)
          .then((res) => {
            if (res.data >= restaurant.orderThrottling.limit) {
              makeEncrypt(
                "waitingNumber",
                res.data - restaurant.orderThrottling.limit + 1
              )
              setWaitingNumber(res.data - restaurant.orderThrottling.limit + 1)
            }
          })
          .catch((error) => console.log(error))
      }
    }
  }, [restaurant])

  /**
   * setting order status
   */
  useEffect(() => {
    if (thisOrder && currentCartItems.length) {
      if (
        thisOrder.items.every((item) => item.isAccepted === true) &&
        currentCartItems.length === thisOrder.items.length
      ) {
        setSnackOpen(true)
        setStatus("In Progress")
        setShowFeedback(true)
      } else {
        setSnackOpen(true)
        setStatus("Awaiting Acceptance")
      }
      setPayThroughApp(thisOrder.paymentThroughApp)
      setTip(parseFloat(thisOrder.tip))
      setUpdatedTip(parseFloat(thisOrder.tip))
      setCustomerName(thisOrder.orderName)
    }
  }, [thisOrder, currentCartItems])

  useEffect(() => {
    handleReload()
  }, [reload])

  useEffect(() => {
    if (refreshCart) getUser(true)
  }, [refreshCart])

  /**
   * setting email to localStorage
   */
  useEffect(() => {
    if (email) makeEncrypt("email", email)
    // localStorage.setItem("email", email);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         )
  }, [email])

  useEffect(() => {
    setGrandTotal(
      parseFloat(
        totalPrice +
        tip +
        taxValue +
        appliedFee +
        parseFloat(pstValue) +
        parseFloat(deliveryCharge)
      ).toFixed(2)
    )
  }, [totalPrice, tip, taxValue, appliedFee, pstValue])

  useEffect(() => {
    setGstValue(taxValue)
  }, [taxValue])

  /**
   * setting total Price
   */
  useEffect(() => {
    let tempPrice = 0
    let temp = 0
    currentCartItems.map((order) => {
      tempPrice = tempPrice + order.order.price * order.noOfItems
      if (order.order.pstApplicable) {
        temp =
          temp +
          ((order.order.price *
            (order.order.pstPercentage
              ? order.order.pstPercentage
              : parseFloat(taxTypes.PST))) /
            100) *
          order.noOfItems
      }
    })
    setPstValue(temp.toFixed(2))
    setSubTotal(tempPrice)
    discount && (tempPrice = tempPrice - discount)
    setTotalPrice(tempPrice)
  }, [currentCartItems, discount])

  useEffect(() => {
    if (currentCartItems.length !== 0)
      if (currentCartItems.length === arrayLength) {
        const updatedItems = []
        currentCartItems.map((item) => {
          if (item.isOrdered === true) {
            if (
              updatedItems.some((eachItem) => eachItem.order._id === item.order._id)
            ) {
              updatedItems.forEach((updatedItem) => {
                if (updatedItem.order._id === item.order._id) {
                  updatedItem.noOfItems = updatedItem.noOfItems + item.noOfItems
                }
              })
            } else updatedItems.push(item)
          } else updatedItems.push(item)
        })

        if (JSON.stringify(currentCartItems) != JSON.stringify(updatedItems)) {
          setCurrentCartItems(updatedItems)
        }
      }
  }, [currentCartItems])

  /**
   * setting tax, final value and tip percentage
   */
  useEffect(() => {
    let value = parseFloat((tax * parseFloat(subTotal)) / 100)
    setTaxValue(value)
    setFinalValue(
      parseFloat(parseFloat(totalPrice) + parseFloat(deliveryCharge) + value)
    )
    setTipPercentage(((tip * 100) / subTotal).toFixed(2))
    if (restaurant)
      setAppliedFee(
        restaurant.dinify_payment_plan.push_to_customer
          ? restaurant.dinify_payment_plan.application_service_fee
          : restaurant.dinify_payment_plan.half_to_customer
            ? restaurant.dinify_payment_plan.application_service_fee / 2
            : 0
      )
  }, [totalPrice, subTotal])

  /**
   * setting total price to local storage
   */
  useEffect(() => {
    makeEncrypt("totalPrice", finalValue.toFixed(2))
  }, [finalValue])

  useEffect(() => {
    if (orderBtnClicked) setCurrentCartItems([])
  }, [orderBtnClicked])

  /**
   * sending data for print
   */
  useEffect(() => {
    if (readytoPrint) {
      doPrint(actualDataToPrint)
    }
  }, [actualDataToPrint])

  /**
   * Start Order functionality for rapdel orders
   */
  useEffect(() => {
    if (deliveryOrderNumber != "") {
      startBillButton("normal")
    }
  }, [deliveryOrderNumber])

  /**
   * Checking promo code is valid or invalid
   *
   */
  useEffect(() => {
    if (coupon?.valid) {
      if (coupon.amountOff > 0 && coupon.percentOff === 0) {
        setDiscount(coupon.amountOff / 100)
        makeEncrypt("discount", coupon.amountOff / 100)
        setPromoValid(true)
      }
      if (coupon.amountOff === 0 && coupon.percentOff > 0) {
        setDiscount((coupon.percentOff / 100)*subTotal)
        makeEncrypt("discount", (coupon.percentOff / 100)*subTotal)
        setPromoValid(true)
      }
    } else {
      inputPromo && setPromoValid(false)
    }
  }, [coupon?._id])

  useEffect(() => {
    dispatch(getDiscount())
  }, [restaurant])

  /**
   * getting all data after reload
   */
  const handleReload = (hardReload) => {
    if (!hardReload) {
      if (!thisUser) {
        getUser(false)
      } else {
        handleUserInformation(thisUser, false)
      }
    }
    if ((makeDecrypt("orderId") && !thisOrder) || hardReload)
      dispatch(getCurrentOrder(makeDecrypt("orderId")))
    if (!restaurant) dispatch(setRestaurant(match.params.restaurantId))
    setOrderUpdated(false)
  }

  const handleUserInformation = async (userParam, hardReload) => {
    setIsFirstTimeOrder(userParam.firstTimeOrder)
    setCurrentUser(userParam)
    const Items = userParam.cart.items.filter(
      (item) => item.restaurant == match.params.restaurantId
    )
    setArrayLength(Items) //userParam.cart.items.length
    if (Items == 0) {
      //userParam.cart.items.length
      setCurrentCartItems([])
      dispatch({ type: "SET_CART_ITEMS", payload: [] })
      return handleClose()
    }
    let paymentMethodObj = userParam.stripeDetails.paymentMethod
    setValues({
      ...values,
      email: userParam.email
        ? userParam.email
        : makeDecrypt("email")
          ? makeDecrypt("email")
          : "",
    })
    if (userParam.stripeDetails.customerId) {
      makeEncrypt("CustomerId", userParam.stripeDetails.customerId)
      if (!makeDecrypt("paymentMethodId")) {
        if (paymentMethodObj.paymentMethodId)
          makeEncrypt("paymentMethodId", paymentMethodObj.paymentMethodId)
        else if (paymentMethodObj.nativePaymentMethodId)
          makeEncrypt("paymentMethodId", paymentMethodObj.nativePaymentMethodId)
      }
      if (makeDecrypt("paymentIntentId")) {
        if (makeDecrypt("paymentIntentId") !== paymentMethodObj.paymentIntentId) {
          makeEncrypt("paymentIntentId", paymentMethodObj.paymentIntentId)
        }
      }

      if (thisCustomer) {
        setStripeCustomer(thisCustomer)
      } else {
        let tempCustomer = await axios.get(
          `/stripe/get-customer/${userParam.stripeDetails.customerId}`
        )
        dispatch({
          type: "SET_STRIPE_CUSTOMER",
          payload: tempCustomer.data,
        })

        setStripeCustomer(tempCustomer.data)
      }
    }

    if (paymentMethodObj.paymentMethodId === makeDecrypt("paymentMethodId"))
      setLast4(paymentMethodObj.lastDigits)
    else if (
      paymentMethodObj.nativePaymentMethodId === makeDecrypt("paymentMethodId")
    )
      setLast4(paymentMethodObj.nativeCardLastDigits)
    if (cartItems.length && !hardReload) {
      const UserCart = cartItems.filter(
        (item) => item.restaurant == match.params.restaurantId
      )
      setCurrentCartItems(cartItems)
    } else {
      let justItems = []
      if (!hardReload) {
        Items.map(async (item) => {
          try {
            let menuItem = await axios.get(`/menu/${item.itemId}/menu-item-detail`)
            menuItem = menuItem.data
            justItems.push({
              order: {
                objectId: item._id,
                category: menuItem.category,
                price: item.price,
                _id: menuItem._id,
                name: menuItem.name,
                imageUrl: menuItem.imageUrl,
                pstApplicable: menuItem.pstApplicable,
                pstPercentage: menuItem.pstPercentage,
                specialInstructions: item.specialInstructions,
                modifiers: item.modifiers,
                status: menuItem.status,
              },
              noOfItems: item.quantity,
              isOrdered: item.isOrdered,
            })
            if (justItems.length == Items.length) {
              setCurrentCartItems(justItems)
              dispatch({ type: "SET_CART_ITEMS", payload: justItems })
              return ""
            }
          } catch (error) {
            setAlertMessage(error.message)
          }
        })
      } else if (hardReload) {
        // const User_Cart = cartItems.filter(
        //   (item) => item.restaurant == makeDecrypt("restaurantId")
        // )
        justItems = cartItems.filter((eachCartItem) => eachCartItem.isOrdered)
        let itemsRecentlyOrdered = cartItems.filter(
          (eachCartItem) => !eachCartItem.isOrdered
        )
        Items.map(async (item) => {
          if (
            itemsRecentlyOrdered.some(
              (thisItem) => thisItem.order.objectId === item._id
            )
          ) {
            let menuItem = await axios.get(`/menu/${item.itemId}/menu-item-detail`)
            menuItem = menuItem.data
            justItems.push({
              order: {
                objectId: item._id,
                category: menuItem.category,
                price: item.price,
                _id: menuItem._id,
                name: menuItem.name,
                imageUrl: menuItem.imageUrl,
                pstApplicable: menuItem.pstApplicable,
                pstPercentage: menuItem.pstPercentage,
                specialInstructions: item.specialInstructions,
                modifiers: item.modifiers,
                status: menuItem.status,
              },
              noOfItems: item.quantity,
              isOrdered: item.isOrdered,
            })
            if (justItems.length == Items.length) {
              setCurrentCartItems(justItems)
              dispatch({ type: "SET_CART_ITEMS", payload: justItems })
              return ""
            }
          }
        })
      }
    }
  }

  /**
   * getting and saving user details
   *
   */
  const getUser = (hardReload) => {
    axios
      .post(`/user/user-details`, {
        phone: makeDecrypt("user"),
        userId: makeDecrypt("userId"),
      })
      .then(async (response) => {
        handleUserInformation(response.data, hardReload)
        dispatch(userAction(response.data))
        if (hardReload) {
          setRefreshCart(false)
        }
      })
      .catch((error) => setAlertMessage(error.message))
  }

  /**
   * calculating tip depending on the total price
   *
   */
  const calculateTip = (perc) => {
    let thisTipValue = (perc * totalPrice) / 100
    setTipPercentage(perc)
    setTip(thisTipValue)
    if (thisOrder) {
      updateTipValue(false, thisTipValue)
    }
  }

  const requestDelivery = async () => {
    rapdelOrderRef.current && rapdelOrderRef.current.requestDelivery()
  }

  const createDeliveryOrder = async () => {
    await requestDelivery()
    // rapdelOrderRef.current && rapdelOrderRef.current.requestDelivery();
    // rapdelOrderRef.current.requestDelivery()
  }

  const checkWaiting = () => {
    if (restaurant?.orderThrottling.active) {
      axios
        .get(`/order/get-orders-in-progress/${match.params.restaurantId}`)
        .then((res) => {
          if (res.data >= restaurant.orderThrottling.limit) {
            makeEncrypt(
              "waitingNumber",
              res.data - restaurant.orderThrottling.limit + 1
            )
            setWaitingNumber(res.data - restaurant.orderThrottling.limit + 1)
            setShowWait(true)
          } else {
            startBillButton("normal")
          }
        })
        .catch((error) => console.log(error))
    } else {
      startBillButton("normal")
    }
  }

  /**
   * places order
   * canceling paymentIntent for already made order
   * generating new paymentIntent if order is being made for the first time
   * cloning payment method
   */
  const startBillButton = (type) => {
    ReactPixel.track("PlaceOrder", { info: "User places an order" })
    if (restaurant.orderThrottling.active) {
      getWaitingNumber()
    }
    if (type === "normal") {
      if (orderKind === "delivery" && !deliveryOrderNumber === "") {
        setOrderBtnClicked(true)
        setPlacingOrder(true)
      }
      if (!orderKind === "delivery") {
        setOrderBtnClicked(true)
        setPlacingOrder(true)
      }
    }
    setOpenAlert(false)
    if (dinifySidePayments && !useRazorpay && paythroughApp) {
      if (makeDecrypt("paymentIntentId")) {
        axios
          .post("/stripe/cancelpayment", {
            payIntId: makeDecrypt("paymentIntentId"),
            stripeAccountId: restaurant.stripeAccountId,
          })
          .then((response) => {
            try {
              createPaymentIntent(type, "same_order")
            } catch (error) {
              setAlertMessage(error.message)
              return setAlertOpen(true)
            }
          })
          .catch((error) => {
            setAlertMessage(error.message)
            setAlertOpen(true)
          })
      } else {
        try {
          createPaymentIntent(type, "new_order")
        } catch (error) {
          setAlertMessage(error.message)
          setAlertOpen(true)
        }
      }
    } else {
      if (makeDecrypt("orderId")) makeOrder("same_order")
      else makeOrder("new_order")
      if (orderKind == "delivery") {
        setAddItems(false)
      } else {
        setAddItems(true)
      }
    }

    dispatch(isAddToBill(false))
  }

  /**
   * get particular menu item details
   * returns menu item
   *
   */
  const getMenuItem = async (id) => {
    return await axios
      .get(`/menu/${match.params.restaurantId}/${id}/menu-item`)
      .then((res) => {
        return res.data
      })
      .catch((error) => setAlertMessage(error.message))
  }

  const sendNotification = (thisData) => {
    thisData.timeOfCreation = new Date().toISOString()
    axios
      .post(`/order/${match.params.restaurantId}/sendNotification`, thisData)
      .then((data) => {
        tempSocket.current.emit("notification-alert", data.data, (response) => { })
      })
      .catch((error) => console.log(error))
  }

  /**
   * if the print direct is true then
   * send prints to restaurant
   *
   */
  const printAtTheRestaurantEnd = (order) => {
    let dataForPrint = []
    order.items.map(async (item) => {
      const menuItem = await getMenuItem(item.itemId)

      if (item.isAccepted === false) {
        let tempModifiers = []
        if (item.modifiers) {
          item.modifiers.map((modifier) => {
            tempModifiers.push({
              type: modifier.selectedTypeId,
              options: modifier.selectedOptions.map((option) => {
                return { name: option.modifierName }
              }),
            })
          })
        }
        let temp = dataForPrint

        if (temp.length) {
          if (
            temp.find(
              (tempItem) =>
                tempItem.printerName ===
                restaurant.printers.find(
                  (printer) => printer._id == menuItem[0].printer
                )?.defaultName
            )
          ) {
            temp[
              temp.findIndex(
                (item) =>
                  item.printerName ===
                  restaurant.printers.find(
                    (printer) => printer._id == menuItem[0].printer
                  )?.defaultName
              )
            ].items.push({
              name: menuItem[0].name,
              quantity: item.quantity,
              specialInstructions: item.specialInstructions,
              modifiers: tempModifiers,
            })
          } else {
            temp.push({
              printerName: restaurant.printers.find(
                (printer) => printer._id == menuItem[0].printer
              )?.defaultName,
              items: [
                {
                  name: menuItem[0].name,
                  quantity: item.quantity,
                  specialInstructions: item.specialInstructions,
                  modifiers: tempModifiers,
                },
              ],
            })
          }
        } else
          temp.push({
            printerName: restaurant.printers.find(
              (printer) => printer._id == menuItem[0]?.printer
            )?.defaultName,
            items: [
              {
                name: menuItem[0].name,
                quantity: item.quantity,
                specialInstructions: item.specialInstructions,
                modifiers: tempModifiers,
              },
            ],
          })

        let tempLength = 0
        temp.forEach((tem) => {
          tempLength = tem.items.length + tempLength
        })

        if (
          order.items.filter((thisItem) => thisItem.isAccepted === false).length ===
          tempLength
        ) {
          setOrderForPrint(order)
          setReadyToPrint(true)

          setActualDataToPrint(temp)
        }
      }
    })
  }

  /**
   *
   * send print to restaurant
   *
   */

  const doPrint = (dataForPrint) => {
    dataForPrint.forEach((item) => {
      item.restaurantId = match.params.restaurantId
      item.orderId = orderForPrint._id
      item.orderName = orderForPrint.orderName ? orderForPrint.orderName : ""
      item.table = orderForPrint.tableNumber
        ? orderForPrint.tableNumber
        : makeDecrypt("tableNo")
      item.restaurantName = restaurant.name
      item.timeOfCreation = orderForPrint.createdAt

      axios
        .post(
          `/order/${item.restaurantId}/${item.orderId}/print-order?dinify_print_station=${restaurant.dinify_print_station}`,
          item
        )
        .then((result) => {
          setAlertMessage("Order successfully placed and accepted")
          setAlertOpen(true)
          handleReload(true)
          setRefreshCart(true)
        })
        .catch((error) => {
          setAlertMessage(
            "Order is successfully placed and accepted but printing failed"
          )
          setAlertOpen(true)
        })
    })
  }

  /**
   * if the print direct is true then
   * accepting the order and sending to the restaurant
   *
   */

  const acceptAndPrint = (orderId, printTheOrder) => {
    axios
      .put(`/order/${match.params.restaurantId}/${orderId}/accept-order`, {})
      .then((res) => {
        makeEncrypt("orderId", orderId)
        tempSocket.current.emit("orderStatus", {
          id: orderId,
          status: "Accepted",
          restaurantId: match.params.restaurantId,
        })
        if (printTheOrder) printAtTheRestaurantEnd(res.data.order)
      })
      .catch((error) => {
        setAlertMessage("Order Cannot be placed")
        setAlertOpen(true)
      })
  }

  const updateCustomerOnStripe = async () => {
    try {
      let thisPromoCodes = stripeCustomer.metadata.promoCodesUsed
        ? JSON.parse(stripeCustomer.metadata.promoCodesUsed)
        : false
      let tempPromoCodes = thisPromoCodes
        ? [...thisPromoCodes, makeDecrypt("appliedPromo")]
        : [makeDecrypt("appliedPromo")]
      let resultCutomer = await axios.post(`/stripe/update-customer`, {
        customerId: stripeCustomer.id,
        promoCodesUsed: JSON.stringify(tempPromoCodes),
      })
    } catch (err) {
      setAlertMessage(err.message)
    }
  }

  /**
   * places order and reflecting it on the restaurant side
   *
   *
   */
  const makeOrder = (orderType, paymentDone) => {
    axios
      .post(
        `/order/${makeDecrypt("userId")}/${match.params.restaurantId}/place-order`,
        {
          tableNumber: table,
          email: email,
          tip: tip,
          phone: makeDecrypt("user"),
          totalPrice: parseFloat(
            finalValue +
            (dinifySidePayments && paythroughApp ? parseFloat(appliedFee) : 0)
          ).toFixed(2),
          tax: (parseFloat(taxValue) + parseFloat(pstValue)).toFixed(2),
          orderName: customerName ? customerName : thisOrder?.orderName,
          pstValue: parseFloat(pstValue)?.toFixed(2),
          appliedPromoCode: makeDecrypt("appliedPromo"),
          orderStatus: makeDecrypt("waitingNumber") ? "Waiting" : "Live",
          waitNumber: makeDecrypt("waitingNumber") && makeDecrypt("waitingNumber"),
          discount: discount,
          discountName: coupon?.name,
          paymentDone: paymentDone ? paymentDone : false,
          paymentThroughApp: paythroughApp,
          gstValue: gstValue?.toFixed(2),
          grandTotal: grandTotal,
          appliedFee: appliedFee,
          subTotal: subTotal?.toFixed(2),
          deliveryOrder: orderKind === "delivery" ? true : false,
          rapdelOrderNumber: deliveryOrderNumber,
        }
      )
      .then((res) => {
        // console.log(res.data, 'from checkout 1196');
        //dinify side payment check and pay through app is removed from below if condition
        if (restaurant.printDirect && table !== "takeout") {
          acceptAndPrint(res.data.order._id, true)
        }
        if (
          res.data.order._id &&
          table === "takeout" &&
          dinifySidePayments &&
          paythroughApp &&
          !useRazorpay
        ) {
          makeEncrypt("orderId", res.data.order._id)

          // acceptAndPrint(res.data.order._id)

          // !makeDecrypt("waitingNumber") && capturePaymentIntent()
        }

        // if (
        //   table === "takeout" &&
        //   dinifySidePayments &&
        //   useRazorpay &&
        //   paythroughApp
        // ) {
        //   acceptAndPrint(res.data.order._id, false)
        // }
        if (res.data.message) {
          if (orderType === "same_order") {
            sendNotification({
              orderName: res.data.order.orderName,
              restaurantId: match.params.restaurantId,
              tableNo: makeDecrypt("tableNo"),
              title: "Order Update",
              content: `Order ${res.data.order.orderName} at table no ${makeDecrypt(
                "tableNo"
              )} updated the tab `,
            })
          } else if (orderType === "new_order") {
            sendNotification({
              orderName: res.data.order.orderName,
              restaurantId: match.params.restaurantId,
              tableNo: makeDecrypt("tableNo"),
              title: "New Order",
              content: `New order ${res.data.order.orderName
                } at table no ${makeDecrypt("tableNo")} started the tab `,
            })
          }
          tempSocket.current.emit("orderStatusChange", {
            orderId: makeDecrypt("orderId"),
            restaurantId: match.params.restaurantId,
          })
          if (makeDecrypt("appliedPromo")) updateCustomerOnStripe()
          setAlertMessage(res.data.message)
        } else setAlertMessage("Something went wrong")
        setAlertOpen(true)
        makeEncrypt("orderId", res.data.order._id)
        handleReload(true)
        setPlacingOrder(false)
        setRefreshCart(true)
        if (makeDecrypt("user")) {
          sendMessage(grandTotal)
        }
      })
      .catch((error) => {
        setAlertMessage("Order Cannot be placed")
        setAlertOpen(true)
      })
  }

  const updateOrder = async (data) => {
    return await axios
      .put(
        `/order/${match.params.restaurantId}/${makeDecrypt("orderId")}/update-order`,
        data
      )
      .then((res) => {
        return res
      })
      .catch((error) => {
        setAlertMessage(error.message)
      })
  }

  /**
   * updating tip value in order
   *
   */
  const updateTipValue = (capture, thisTipValue) => {
    updateOrder({
      tip: capture ? tip : thisTipValue,
      totalPrice: parseFloat(finalValue + appliedFee).toFixed(2),
    })
      .then((res) => {
        if (capture && table !== "takeout") capturePaymentIntent()

        if (res.data.message) setAlertMessage(res.data.message)
        else setAlertMessage("Something went wrong")
        setAlertOpen(true)
      })
      .catch((error) => {
        setAlertMessage("Order Cannot be Completed")
        setAlertOpen(true)
      })
  }

  /**
   * creating payment intent for the current total price
   * intent will change if the new item get added to the order
   */
  const createPaymentIntent = async (type, orderType) => {
    try {
      try {
        axios
          .post("/stripe/create-payment-intent", {
            orderdetails: currentCartItems,
            tip: tip,
            currency: "CAD",
            customerId: makeDecrypt("CustomerId"),
            payment_method: makeDecrypt("paymentMethodId"),
            restaurantId: match.params.restaurantId,
            description: makeDecrypt("email"),
            stripeAccountId: restaurant.stripeAccountId,
            taxValue: taxValue,
            pstValue: pstValue,
            discount: discount * 100,
            deliveryCharge: deliveryCharge,
          })
          .then((res) => {
            dispatch(setPaymentIntentId(res.data.id))
            makeEncrypt("paymentIntentId", res.data.id)
            axios
              .put(`/user/${makeDecrypt("userId")}/update-payment-method`, {
                expMonth: currentUser.stripeDetails.paymentMethod.expMonth,
                expYear: currentUser.stripeDetails.paymentMethod.expYear,
                lastDigits: currentUser.stripeDetails.paymentMethod.lastDigits,
                paymentMethodId:
                  currentUser.stripeDetails.paymentMethod.paymentMethodId,
                paymentId: currentUser.stripeDetails.paymentMethod.paymentId,
                setupIntentId: currentUser.stripeDetails.paymentMethod.setupIntentId,
                customerId: currentUser.stripeDetails.customerId,
                nativePaymentMethodId:
                  currentUser.stripeDetails.paymentMethod.nativePaymentMethodId,
                nativeCardLastDigits:
                  currentUser.stripeDetails.paymentMethod.nativeCardLastDigits,
                nativeType: currentUser.stripeDetails.paymentMethod.nativeType,
                phone: makeDecrypt("user"),
                email: makeDecrypt("email"),
                paymentIntentId: makeDecrypt("paymentIntentId"),
              })
              .then((response) => {
                if (type === "normal") makeOrder(orderType)
                else if (type === "update") {
                  updateTipValue(true)
                }
              })
              .catch((error) => setAlertMessage("Cannot place order"))
            setValues({ ...values, paymentIntentId: res.data.id })
            setValues({ ...values, isCompleteOrder: true })
          })
          .catch((error) => {
            setAlertMessage("cannot create intent:", error.message)
            setAlertOpen(true)
          })
      } catch (error) {
        setAlertMessage(error.message)
      }
    } catch (error) {
      setAlertMessage(error.message)
      setAlertOpen(true)
    }
  }

  const completeOrderByUser = () => {
    axios
      .put(
        `/order/${makeDecrypt("userId")}/${makeDecrypt(
          "restaurantId"
        )}/${makeDecrypt("orderId")}/complete-order-user`,
        {
          phone: makeDecrypt("user"),
          paymentIntentId: makeDecrypt("paymentIntentId"),
        }
      )
      .then((res) => {
        if (res.data.order._id)
          setOrderIndex(
            res.data.order.seqValue ? res.data.order.seqValue : res.data.order._id
          )
        sendNotification({
          orderName: res.data.order.orderName,
          restaurantId: match.params.restaurantId,
          tableNo: makeDecrypt("tableNo"),
          title: "Paid",
          content: `Order ${res.data.order.orderName} at table no ${makeDecrypt(
            "tableNo"
          )} paid the tab `,
        })
        setOpen(true)

        socket.current.emit("orderStatusChange", {
          orderId: makeDecrypt("orderId"),
          restaurantId: match.params.restaurantId,
        })
        socket.current.emit("orderStatus", {
          id: makeDecrypt("orderId"),
          status: "Completed",
          restaurantId: match.params.restaurantId,
        })

        if (res.data.message) setAlertMessage(res.data.message)
        else setAlertMessage("Something went wrong")
        setAlertOpen(true)
      })
      .catch((error) => {
        setAlertMessage("Order Could Not Complete")
        setAlertOpen(true)
      })
  }

  /**
   * capturing payment intent/actually charging user
   * and completing order and reflection on the restaurant side
   */
  const capturePaymentIntent = async () => {
    axios
      .post("/stripe/capturePayment", {
        PaymentIntentId: makeDecrypt("paymentIntentId"),
        stripeAccountId: restaurant.stripeAccountId,
        discount: discount * 100,
        merchantSidePromotion: highlightedPromo
          ? highlightedPromo.metadata.merchantSidePromotion === "true" &&
          highlightedPromo.code.toLowerCase() === inputPromo.toLowerCase()
          : false,
      })
      .then((res) => {
        if (res.data.status === "succeeded") {
          if (table === "takeout" && dinifySidePayments && !useRazorpay) {
            updateOrder({ paymentDone: true })
            setTimeout(() => {
              socket.current.emit("orderStatusChange", {
                orderId: makeDecrypt("orderId"),
                restaurantId: match.params.restaurantId,
              })
            }, 1000)
          }
          if (!restaurant.orderThrottling.active) {
            if (!useRazorpay && table !== "takeout") {
              completeOrderByUser()
            }
          } else {
            axios
              .get(`/order/${match.params.restaurantId}/make-order-live`)
              .then((result) => {
                if (result.data.user) {
                  socket.current.emit("order-is-live", {
                    userId: result.data.user,
                  })
                  if (restaurant.printDirect && table !== "takeout" && !useRazorpay)
                    acceptAndPrint(result.data._id, true)
                  let tempWaitingOrders = []
                  axios
                    .get(`/order/${match.params.restaurantId}/get-waiting-orders`)
                    .then((response) => {
                      if (response.data.length) {
                        response.data.map((order) => {
                          if (order.user != response.data.user)
                            tempWaitingOrders.push(order.user)
                        })
                      }
                      socket.current.emit("refresh-queue", {
                        tempWaitingOrders: tempWaitingOrders,
                      })
                      if (!useRazorpay && table !== "takeout") {
                        completeOrderByUser()
                      }
                    })
                    .catch((error) => console.log(error))
                } else {
                  if (restaurant.printDirect && table !== "takeout" && !useRazorpay)
                    acceptAndPrint(result.data._id, true)
                  if (!useRazorpay && table !== "takeout") {
                    completeOrderByUser()
                  }
                }
              })
              .catch((error) => console.log(error))
          }
        } else {
          setAlertMessage("Order Could Not Complete")
          setAlertOpen(true)
        }
      })
      .catch((error) => {
        if (error.message) setAlertMessage(error.message)
        else setAlertMessage("cannot capture payment")
        setAlertOpen(true)
      })
  }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script")
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  const initPayment = (currentData) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: currentData.amount,
      currency: currentData.currency,
      name: "Dinify",
      description: "Pay for your order",
      order_id: currentData.id,
      handler: async (response) => {
        try {
          const thisResponse = response
          thisResponse.amount = currentData.amount
          thisResponse.currency = currentData.currency
          thisResponse.razorpayAccountId = restaurant.razorpayAccountId
          const { data } = await axios.post("/razorpay/verify-payment", thisResponse)
          if (
            data.message == "Payment verified successfully" &&
            table === "takeout"
          ) {
            setAddItems(true)
            return makeOrder("new_order", true)
          } else {
            setAddItems(true)
            updateOrder({ paymentDone: true })
            tempSocket.current.emit("orderStatusChange", {
              orderId: makeDecrypt("orderId"),
              restaurantId: match.params.restaurantId,
            })
            return ""
          }
        } catch (error) {
          setAlertMessage(error.message)
        }
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function () {
          setCompleteOrderBtnClicked(false)
        },
        backdropclose: false,
        escape: false,
        handleback: false,
        confirm_close: true,
      },
      payments: {
        method: {
          netbanking: 0,
        },
      },
    }
    const rzp1 = new window.Razorpay(options)
    rzp1.open()
  }

  /**
   *
   *completing order and reflection on the restaurant side
   */
  const completeOrder = async (checkWaiting) => {
    if (makeDecrypt("waitingNumber") && checkWaiting) {
      return setShowWait(true)
    }
    setCompleteOrderBtnClicked(true)
    setOpenAlert(false)
    if (
      updatedTip != tip &&
      table !== "takeout" &&
      dinifySidePayments &&
      paythroughApp &&
      !useRazorpay
    ) {
      setOpenAlert(false)
      startBillButton("update")
      return ""
    } else if (
      table === "takeout" &&
      dinifySidePayments &&
      paythroughApp &&
      !useRazorpay
    ) {
      setOpenAlert(false)
      startBillButton("normal")
      return ""
    } else if (table === "takeout" && !dinifySidePayments) {
      setOpenAlert(false)
      makeOrder("new_order")
      return ""
    }
    //Note:New condition added if things goes wrong check this condition
    else if (table === "takeout" && !paythroughApp && dinifySidePayments) {
      setOpenAlert(false)
      startBillButton("normal")
      return ""
    }

    if (dinifySidePayments && !useRazorpay && paythroughApp && table !== "takeout") {
      capturePaymentIntent()
    } else if (
      useRazorpay &&
      table !== "takeout" &&
      dinifySidePayments &&
      paythroughApp
    ) {
      try {
        const { data } = await axios.post("/razorpay/create-order", {
          amount: parseFloat(
            totalPrice + tip + taxValue + appliedFee + parseFloat(pstValue)
          ).toFixed(2),
        })
        initPayment(data.data)
      } catch (error) {
        console.log(error)
      }
    } else if (
      useRazorpay &&
      table === "takeout" &&
      dinifySidePayments &&
      paythroughApp
    ) {
      const { data } = await axios.post("/razorpay/create-order", {
        amount: parseFloat(
          totalPrice + tip + taxValue + appliedFee + parseFloat(pstValue)
        ).toFixed(2),
      })

      initPayment(data.data)
    } else {
      //Note: Client side order completion is off now onwards
      //Only restaurant can complete order for india only
      if (!useRazorpay) completeOrderByUser()
    }
  }

  let buttontext = null
  if (currentCartItems.length) {
    if (
      !currentCartItems.some((item) => item.isOrdered === true) ||
      (currentCartItems.length === 1 && currentCartItems[0].isOrdered === false)
    ) {
      buttontext =
        dinifySidePayments && paythroughApp && !useRazorpay ? (
          <Button
            onClick={() => {
              orderKind === "delivery" ? createDeliveryOrder() : checkWaiting()
            }}
            disabled={
              !(makeDecrypt("paymentMethodId") && customerName) ||
              orderBtnClicked ||
              !validateEmail(email) ||
              orderKind === "" ||
              (orderKind === "delivery" && deliveryCharge === 0)
            }
            className={[classes.addBillBtn, "billbutton"]}
            variant="contained"
            color="primary"
          >
            <Typography variant="h5">Start Your Order</Typography>
          </Button>
        ) : (
          <Button
            onClick={() => {
              orderKind === "delivery" ? createDeliveryOrder() : checkWaiting()
            }}
            disabled={orderBtnClicked || !customerName}
            className={[classes.addBillBtn, "billbutton"]}
            variant="contained"
            color="primary"
          >
            <Typography variant="h5">Start Your Order</Typography>
          </Button>
        )
    } else if (
      currentCartItems.some((item) => item.isOrdered === false) &&
      !(currentCartItems.length === 1 && currentCartItems[0].isOrdered === false)
    ) {
      buttontext = (
        <Button
          className={[classes.addBillBtn, "billbutton"]}
          color="primary"
          variant="contained"
          onClick={() => startBillButton("normal")}
          disabled={orderBtnClicked}
        >
          <Typography variant="h5">Add to Order</Typography>
        </Button>
      )
    } else if (
      currentCartItems.every((item) => item.isOrdered === true) &&
      table !== "takeout"
    ) {
      buttontext = thisOrder ? (
        <Button
          className={[classes.addBillBtn, "billbutton"]}
          color="primary"
          onClick={() => {
            setOpenAlert(true)
          }}
          variant="contained"
          disabled={
            !thisOrder.items.every((item) => item.isAccepted === true) ||
            completeOrderBtnClicked ||
            (!dinifySidePayments && makeDecrypt("orderId")) ||
            (makeDecrypt("orderId") &&
              useRazorpay &&
              !dinifySidePayments &&
              table !== "takeout") ||
            !paythroughApp ||
            (dinifySidePayments &&
              makeDecrypt("orderId") &&
              !useRazorpay &&
              table !== "takeout") ||
            (table == "takeout" && !makeDecrypt("paymentMethodId")) ||
            thisOrder.tableNumber === "takeout"
          }
        >
          <Typography variant="h5">Finish Dining</Typography>
        </Button>
      ) : (
        <Button
          className={[classes.addBillBtn, "billbutton"]}
          color="primary"
          onClick={() => {
            setOpenAlert(true)
          }}
          variant="contained"
          disabled={
            !customerName ||
            completeOrderBtnClicked ||
            (dinifySidePayments &&
              !useRazorpay &&
              paythroughApp &&
              !makeDecrypt("paymentMethodId"))
          }
        >
          <Typography variant="h5">Place Order</Typography>
        </Button>
      )
    }
  }
  /**
   * closing final confirmation of the order
   *
   */
  const handleClose = () => {
    localStorage.removeItem("paymentIntentId")
    localStorage.removeItem("checkout")
    localStorage.removeItem("totalPrice")
    localStorage.removeItem("orderId")
    localStorage.removeItem("discount")
    localStorage.removeItem("appliedPromo")
    localStorage.removeItem("waitingNumber")
    setOpen(false)
    dispatch({ type: "SET_INITIAL_ORDER_STATE" })
    dispatch({ type: "SET_INITIAL_USER_STATE" })
    history.push(
      `/restaurant/${match.params.restaurantId}/table/${makeDecrypt("tableNo")}`
    )
  }

  const handleCloseDeliveryErrorInfo = () => {
    setDeliveryErrorInfo(false)
    // setOpenInfo(false)
    makeEncrypt("closeDeliveryErrorInfo", true)
  }
  const handleInfoClose = () => {
    setOpenInfo(false)
    isFirstTimeOrder && setShowTour(true)
    makeEncrypt("infoClose", true)
  }
  const handleCloseAlert = () => {
    setOpenAlert(false)
  }
  const handleCloseEmailAlert = () => {
    setOpenEmailAlert(false)
  }

  /**
   * validating user's email for order
   *
   */
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    window.location.reload()
  }

  const handleTipClick = (val, perc) => {
    setActiveTip(val)
    calculateTip(perc)
  }

  const openReviewBox = () => {
    setOpenAlert(false)
    setOpen(false)
    setShowReviewBox(true)
  }

  const giveFeedback = () => {
    setShowReviewBox(false)
    axios
      .post(
        `/user/${match.params.restaurantId}/${makeDecrypt("userId")}/give-feedback`,
        {
          stars,
          feedBackMsg,
          type: "user-experience",
        }
      )
      .then((response) => {
        setStatus("Thanks for valuable Feedback!")
        setSnackOpen(true)
      })
      .catch((err) => {
        setErrorStatus("Please retry.")
        setErrorSnackOpen(true)
      })

    setTimeout(function () {
      handleClose()
    }, 3000)
  }

  /**
   * Function for applying entered promo code
   *
   */
  const convertTimeToMinutes = (dateString, time) => {
    const targetDate = new Date(dateString);
    const [hours, minutes] = time.split(':');
    targetDate.setHours(parseInt(hours, 10));
    targetDate.setMinutes(parseInt(minutes, 10));
    return targetDate.getHours() * 60 + targetDate.getMinutes();
  }
  const couponTimeRangeValidation = (data) => {
    const currentDate = new Date();
    const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
    if (!data.days.includes(currentDay)) {
      return false;
    }
    const startMinutes = convertTimeToMinutes(data.startDate, data.startTime);
    const endMinutes = convertTimeToMinutes(data.endDate, data.endTime);
    return currentTime >= startMinutes && currentTime <= endMinutes;
  }
  const handleApply = () => {
    setHavePromo(false)
    setDiscount()
    setCoupon()
    setPromoValid(false)
    setShowRestrictionMsg(false)
    setShowPromoMsg(false)
    localStorage.removeItem("discount")
    localStorage.removeItem("appliedPromo")
    let tempCoupon = availablePromoCodes?.find(
      (promo) => promo.name.toLowerCase() == inputPromo.toLowerCase()
    )
    // time range validation login
    setShowPromoMsg(true)
    if (tempCoupon) {
      const checkTimeRange = couponTimeRangeValidation(tempCoupon);
      if (tempCoupon.valid && tempCoupon.customerFacing && checkTimeRange) {
        let grandTotal = parseFloat(
          totalPrice +
          taxValue +
          appliedFee +
          parseFloat(pstValue) +
          parseFloat(deliveryCharge)
        )
        if (tempCoupon.minOrderValue <= grandTotal) {
          if (currentUser.couponsUsed.filter(element => element === inputPromo).length >= tempCoupon.limitPerCustomer) {
            setPromoValid(false)
            toast.error("You already used this coupon")
            setShowRestrictionMsg(true)
          } else {
            setCoupon(tempCoupon)
            makeEncrypt("appliedPromo", inputPromo)
            toast.success("Coupon applied successfully!")
          }
        } else {
          setPromoValid(false)
          toast.error(`The minimum order value to avail this coupon is $${(
            tempCoupon.minOrderValue
          ).toFixed(2)}`)
          setShowRestrictionMsg(true)
        }
      } else {
        setPromoValid(false)
        setShowRestrictionMsg(false)
        toast.error("The promo code is invalid!")
      }
    } else {
      toast.error("The promo code is invalid!")
      setPromoValid(false)
      setShowRestrictionMsg(false)
    }
  }

  /**
   * Function for remove applied promocode data
   *
   */
  const removePromo = () => {
    setDiscount()
    setCoupon()
    setInputPromo()
    setPromoValid(false)
    setShowRestrictionMsg(false)
    setShowPromoMsg(false)
    localStorage.removeItem("discount")
    localStorage.removeItem("appliedPromo")
  }

  /**
   * Function to make the first time order false
   *
   */
  const closeTour = () => {
    setShowTour(false)
    isFirstTimeOrder &&
      axios
        .get(`/user/disable-first-time-order/${makeDecrypt("userId")}`)
        .then((res) => window.location.reload())
  }

  const getWaitingNumber = () => {
    const restId = match.params.restaurantId
    axios
      .get(`/order/${restId}/get-waiting-orders`, {
        headers: { Authorization: makeDecrypt("jwt") },
      })
      .then((res) => {
        let waitingOrders = res.data
        let tempNo =
          waitingOrders.findIndex((order) => order.user === makeDecrypt("userId")) +
          1
        if (tempNo > 0) {
          setWaitingNumber(tempNo)
          makeEncrypt("waitingNumber", tempNo)
        }
      })
  }

  const changePaymentType = (state) => {
    setPayThroughApp(state)
  }

  const changeOrderType = (state) => {
    setOrderKind(state)
    if (!state === "delivery") {
      setDeliveryCharge(0)
    }
  }

  const sendMessage = (totalAmount) => {
    let tempMsg
    if (orderKind == "delivery") {
      tempMsg = `Delivery order (via Rapdel) at ${restaurant.name
        } is placed, awaiting acceptance from restaurant. Please check your order here: ${`https://dine.dinify.io/checkout/${restaurant._id}`}`
    }
    if (table == "takeout" && orderKind != "delivery")
      tempMsg = `Takeout order at ${restaurant.name
        } is placed, awaiting acceptance from restaurant. Please check your order here: ${`https://dine.dinify.io/checkout/${restaurant._id}`}`
    else
      tempMsg = `Your dine-in order at ${restaurant.name
        } is placed successfully. Add more items here ${`https://dine.dinify.io/restaurant/menu/${restaurant._id}`}`

    if (makeDecrypt("user"))
      axios.post("order/send-message", {
        userPhoneNo: makeDecrypt("user"),
        msg: tempMsg,
      })
  }

  return (
    <Box sx={{ minHeight: !onMenuScreen ? "90vh" : "" }}>
      <Helmet>
        <title>Checkout | {restaurant ? restaurant.name : ""}</title>
      </Helmet>
      <CouponHighlighter />
      {waitingNumber && (
        <Box className={classes.waitingBox}>
          <Typography style={{ margin: "auto", color: "#fff" }}>
            Waiting No. - {waitingNumber}
          </Typography>
        </Box>
      )}

      {!onMenuScreen && (
        <Box style={{ position: "relative" }}>
          <TopBar screen="checkout" />

          {currentCartItems && currentCartItems.length ? (
            <>
              <HelpOutlineIcon
                onClick={() => setShowTour(true)}
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "47px",
                  zIndex: "150",
                }}
              />
              <InfoIcon
                onClick={() => {
                  makeEncrypt("infoClose", false)
                  setOpenInfo(true)
                }}
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "17px",
                  zIndex: "150",
                }}
              />
            </>
          ) : (
            ""
          )}
        </Box>
      )}
      {currentCartItems && currentCartItems.length ? (
        <>
          <Container>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <Typography variant="h4">Order Items</Typography>
            </Box>
            <Box>
              {!onMenuScreen && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {/* <Button variant="outlined" color="primary"> */}
                  {/* { orderKind == 'takeout' ? 'Takeout' : 'Delivery'} */}
                  {/* {makeDecrypt("tableNo") == "takeout"
                      ? makeDecrypt("tableNo")
                      : `Table No.${makeDecrypt("tableNo")}`} */}
                  {/* </Button> */}
                  {orderKind == "delivery" &&
                    !addItems &&
                    status != "Scroll down to start your bill" ? (
                    ""
                  ) : (
                    <Typography variant="body2">
                      <Link to={`/restaurant/menu/${match.params.restaurantId}`}>
                        <div className="addItems">
                          <Button
                            id="addItems"
                            variant="outlined"
                            color="primary"
                            size="small"
                            className={[classes.button]}
                            endIcon={<AddCircleOutlineIcon />}
                          >
                            ADD ITEMS
                          </Button>
                        </div>
                      </Link>
                    </Typography>
                  )}
                </Box>
              )}
              <Box sx={{ my: 1 }}>
                {currentCartItems.map((order, index) => {
                  const {
                    name,
                    price,
                    _id,
                    imageUrl,
                    category,
                    objectId,
                    specialInstructions,
                    modifiers,
                    status,
                  } = order.order
                  const menuItem = {
                    name,
                    price,
                    _id,
                    category,
                    quantity: order.noOfItems,
                    imageUrl,
                    isOrdered: order.isOrdered,
                    objectId,
                    specialInstructions,
                    modifiers,
                    status,
                  }
                  return (
                    <MenuItem
                      menuItem={menuItem}
                      isCheckout={true}
                      key={index}
                      setSnackOpen={setSnackOpen}
                      setStatus={setStatus}
                      showFeedback={showFeedback}
                      tempSocket={tempSocket}
                    />
                  )
                })}
              </Box>
            </Box>
            {!thisOrder?.orderName && restaurant.supportDelivery ? (
              <>
                <Divider style={{ margin: "10px 0" }} />
                <Box display="flex" flexDirection="column">
                  <Typography
                    style={{
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                    variant="h5"
                  >
                    Delivery or takeout?
                  </Typography>
                  <FormControlLabel
                    label="I'll pick it up myself!"
                    control={
                      <Checkbox
                        style={{ marginLeft: "10px" }}
                        type="radio"
                        name="orderKind"
                        onChange={() => changeOrderType("takeout")}
                        checked={orderKind === "takeout" ? true : false}
                        className={classes.root}
                        disableRipple
                        color="default"
                        checkedIcon={
                          <span
                            className={clsx(classes.checkIcon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.checkIcon} />}
                        inputProps={{ "aria-label": "decorative checkbox" }}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Deliver it to me"
                    control={
                      <Checkbox
                        style={{ marginLeft: "10px" }}
                        type="radio"
                        name="orderKind"
                        onChange={() => changeOrderType("delivery")}
                        checked={orderKind === "delivery" ? true : false}
                        className={classes.root}
                        disabled={
                          totalPrice >= restaurant.minimumDeliveryOrderAmount
                            ? false
                            : true
                        }
                        disableRipple
                        color="default"
                        checkedIcon={
                          <span
                            className={clsx(classes.checkIcon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.checkIcon} />}
                        inputProps={{ "aria-label": "decorative checkbox" }}
                      />
                    }
                  />
                  {orderKind === "delivery" ? (
                    <>
                      <div className={[classes.input]}>
                        <RapdelBizOrder
                          setup={setup}
                          projectSetup={projectSetup}
                          ref={rapdelOrderRef}
                        />
                      </div>
                      <TextField
                        label="Unit no"
                        variant="outlined"
                        // size="small"
                        className={[classes.input, "ordername"]}
                        value={unitNo}
                        onChange={(e) => {
                          setUnitNo(e.target.value)
                        }}
                        disabled={false}
                      />
                      <TextField
                        label="Delivery Instructions"
                        variant="outlined"
                        // size="small"
                        required
                        className={[classes.input, "ordername"]}
                        value={deliveryInstructions}
                        onChange={(e) => {
                          setDeliveryInstructions(e.target.value)
                        }}
                        disabled={false}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </>
            ) : (
              ""
            )}
            <Divider />
            {dinifySidePayments && paythroughApp && (
              <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap" }}>
                <Typography variant="h4" style={{ width: "50%" }}>
                  Tip
                </Typography>
                <Typography
                  variant="h6"
                  style={{ width: "50%", textAlign: "right" }}
                >
                  {currencySign} {tip.toFixed(2)}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={[classes.tipBtnContainer, "tipbutton"]}
                >
                  <Box>
                    <Button
                      variant="outlined"
                      // size="small"
                      className={
                        activeTip === "a" ? classes.activeTipBtn : classes.tipBtn
                      }
                      onClick={() => handleTipClick("a", 15)}
                      disabled={tipPercentage == "15.00"}
                      value="a"
                    >
                      15%
                    </Button>
                    <Button
                      variant="outlined"
                      className={
                        activeTip === "b" ? classes.activeTipBtn : classes.tipBtn
                      }
                      onClick={() => handleTipClick("b", 18)}
                      disabled={tipPercentage == "18.00"}
                      value="b"
                    >
                      18%
                    </Button>
                    <Button
                      variant="outlined"
                      className={
                        activeTip === "c" ? classes.activeTipBtn : classes.tipBtn
                      }
                      onClick={() => handleTipClick("c", 20)}
                      disabled={tipPercentage == "20.00"}
                      value="c"
                    >
                      20%
                    </Button>
                  </Box>
                  <Box>
                    <FormControl variant="outlined" size="small" color="black">
                      <OutlinedInput
                        type="number"
                        id="outlined-adornment-amount"
                        size="small"
                        className={classes.tipInput}
                        placeholder={tipPercentage}
                        onChange={(e) => {
                          setActiveTip("")
                          if (e.target.value == "" || e.target.value < 0) setTip(0)
                          else calculateTip(e.target.value)
                        }}
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        value={tipPercentage}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            )}
            <Divider />
            <Box sx={{ mt: 2 }} className="grandtotal">
              <Typography variant="h4">Totals</Typography>
              <OrderTable
                totalPrice={totalPrice}
                tip={tip}
                tax={tax}
                finalValue={finalValue}
                taxTypes={taxTypes}
                taxValue={taxValue}
                pstValue={pstValue}
                discount={discount}
                fee={appliedFee}
                deliveryCharge={deliveryCharge}
                orderKind={orderKind}
              />
            </Box>
            {(
              <Box sx={{ mb: 0.7, mt: 1.7 }}>
                {!havePromo &&
                  (!currentCartItems.every((item) => item.isOrdered === true) ||
                    table === "takeout") && (
                    <Box sx={{ textAlign: "right" }}>
                      {!discount ? (
                        <Typography
                          color="primary"
                          onClick={() => {
                            setHavePromo(true)
                          }}
                          sx={{ textAlign: "right" }}
                        >
                          Have a promo code?
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            width: "fit-content",
                            marginLeft: "auto",
                          }}
                        >
                          <Typography style={{ color: "black" }}>
                            Applied Promo Code -{" "}
                            <span style={{ fontWeight: "600" }}>
                              {makeDecrypt("appliedPromo")}
                            </span>
                          </Typography>
                          <CancelIcon
                            style={{ marginLeft: "10px", color: "black" }}
                            onClick={removePromo}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                {havePromo &&
                  (!currentCartItems.every((item) => item.isOrdered === true) ||
                    table === "takeout") && (
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        id="outlined-basic"
                        label="Enter Promo Code"
                        variant="outlined"
                        value={inputPromo}
                        onChange={(e) => setInputPromo(e.target.value)}
                      />
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={handleApply}
                      >
                        Apply
                      </Button>
                      <CancelIcon
                        style={{ margin: "auto" }}
                        onClick={() => {
                          setHavePromo(false)
                          setShowPromoMsg(false)
                        }}
                      />
                    </Box>
                  )}
                <Box sx={{ mt: 1 }}>
                  {promoValid || discount ? (
                    <Typography color="primary" style={{ textAlign: "center" }}>
                      Nice you have saved{" "}
                      <span style={{ fontSize: "18px", fontWeight: "700" }}>
                        {currencySign}
                        {discount.toFixed(2)}
                      </span>
                    </Typography>
                  ) : (
                    inputPromo &&
                    showPromoMsg &&
                    (showRestrictionMsg ? (
                      <Typography color="red">{restrictionMsg}</Typography>
                    ) : (
                      <Typography color="red">

                      </Typography>
                    ))
                  )}
                </Box>
              </Box>
            )}
            <Box sx={{ p: 1, my: 2, position: "relative" }} boxShadow="2">
              <Box>
                <TextField
                  label="Enter your name"
                  variant="outlined"
                  // size="small"
                  required
                  className={[classes.input, "ordername"]}
                  value={thisOrder?.orderName ? thisOrder.orderName : customerName}
                  onChange={(e) => {
                    setCustomerName(e.target.value)
                    makeEncrypt("userName", e.target.value)
                  }}
                  disabled={thisOrder?.orderName ? true : false}
                />
                <TextField
                  id="email-address"
                  label="Email Address For Receipt"
                  variant="outlined"
                  type="email"
                  className={[classes.input, "email"]}
                  value={email}
                  onChange={(e) => {
                    setValues({ ...values, email: e.target.value.trim() })
                  }}
                  disabled={thisOrder?.orderName ? true : false}
                // required={true}
                />
              </Box>
              {!makeDecrypt("orderId") &&
                dinifySidePayments &&
                orderKind === "takeout" &&
                ((makeDecrypt("tableNo") == "takeout" && !forceOnline) ||
                  makeDecrypt("tableNo") != "takeout") ? (
                <Box display="flex" flexDirection="column">
                  <Typography
                    style={{
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                    variant="h5"
                  >
                    Select payment mode
                  </Typography>
                  <FormControlLabel
                    label="Pay online"
                    control={
                      <Checkbox
                        style={{ marginLeft: "10px" }}
                        type="radio"
                        name="paymentType"
                        onChange={() => changePaymentType(true)}
                        checked={paythroughApp ? true : false}
                        className={classes.root}
                        disableRipple
                        color="default"
                        checkedIcon={
                          <span
                            className={clsx(classes.checkIcon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.checkIcon} />}
                        inputProps={{ "aria-label": "decorative checkbox" }}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Pay in store"
                    control={
                      <Checkbox
                        style={{ marginLeft: "10px" }}
                        type="radio"
                        name="paymentType"
                        onChange={() => changePaymentType(false)}
                        checked={paythroughApp ? false : true}
                        className={classes.root}
                        disableRipple
                        color="default"
                        checkedIcon={
                          <span
                            className={clsx(classes.checkIcon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.checkIcon} />}
                        inputProps={{ "aria-label": "decorative checkbox" }}
                      />
                    }
                  />
                </Box>
              ) : (
                ""
              )}
              {paythroughApp && dinifySidePayments && !useRazorpay && (
                <Box sx={{ my: 1, p: 1 }} className="cardbutton">
                  <Typography
                    variant="h6"
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="paymentMethod "
                  >
                    Payment Method
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {makeDecrypt("paymentMethodId") && (
                      <Box display="flex" alignItems="center">
                        <PaymentIcon className={classes.card} />

                        <Typography variant="body2">
                          **** **** {last4 ? last4 : ""}
                        </Typography>
                      </Box>
                    )}
                    {!currentCartItems.some((order) => order.isOrdered) && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!(email && validateEmail(email))}
                        size="small"
                        className={classes.button}
                      >
                        <Link to="/add_payment" style={{ color: "white" }}>
                          {!makeDecrypt("paymentMethodId") ? "ADD" : "EDIT"}
                        </Link>
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            {restaurant?.dinifySidePayments && <PciBox />}
            <Divider></Divider>
            {table == "takeout" ? (
              <Box display={"flex"} alignItems="center" sx={{ mt: 1 }}>
                <Typography variant="h4">Order status : </Typography>
                <Typography style={{ marginLeft: "10px" }}>{status}</Typography>
              </Box>
            ) : (
              ""
            )}
            <Box sx={{ height: "100px" }}></Box>
            <Box className={classes.billBtnBox}>
              {/* {!email && (
                <Typography color="primary">Please enter valid email</Typography>
              )} */}
              {!makeDecrypt("paymentMethodId") &&
                dinifySidePayments &&
                paythroughApp &&
                !useRazorpay && (
                  <Typography color="primary">Please add your card first</Typography>
                )}

              {buttontext}
            </Box>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              style={{ width: "100%", margin: "0" }}
            >
              <DialogTitle id="alert-dialog-title">
                <Typography variant="h5">Order Completed</Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant="h6">Order No: {orderIndex}</Typography>
                </DialogContentText>
                <OrderTable
                  totalPrice={totalPrice}
                  tip={tip}
                  taxValue={taxValue}
                  tax={tax}
                  discount={discount}
                  fee={appliedFee}
                  finalValue={finalValue}
                  taxTypes={taxTypes}
                  pstValue={pstValue}
                  orderCompleted={true}
                />
                <Typography variant="h6">Want to share experience?</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="black"
                  style={{ marginTop: "10px" }}
                  onClick={() => handleClose()}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "10px" }}
                  onClick={() => openReviewBox()}
                >
                  PROCEED
                </Button>
              </DialogActions>
            </Dialog>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openDeliveryErrorInfo && !makeDecrypt("closeDeliveryErrorInfo")}
              onClose={handleCloseDeliveryErrorInfo}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openDeliveryErrorInfo}>
                <Paper
                  style={{ padding: "10px", textAlign: "center", width: "90%" }}
                >
                  <Typography variant="h5">We've got a problem!</Typography>
                  <Typography variant="h6">{deliveryErrorMessage}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCloseDeliveryErrorInfo}
                  >
                    Close
                  </Button>
                </Paper>
              </Fade>
            </Modal>

            {/* <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openInfo && !makeDecrypt("infoClose")}
              onClose={handleInfoClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openInfo}>
                <Paper
                  style={{ padding: "10px", textAlign: "center", width: "90%" }}
                >
                  <Typography variant="h5">How it works?</Typography>
                  {steps.length ? (
                    <List dense>
                      {steps.map((step, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemText
                              primary={`${step.id}. ${step.instruction}`}
                            />
                          </ListItem>
                        )
                      })}
                    </List>
                  ) : (
                    <Box className={classes.loaderBox}>
                      <CircularProgress />
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleInfoClose}
                  >
                    Close
                  </Button>
                </Paper>
              </Fade>
            </Modal> */} // new user guide
            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Want to complete order?
              </DialogTitle>
              <DialogContent>
                {dinifySidePayments && paythroughApp ? (
                  <DialogContentText id="alert-dialog-description">
                    {!tip ? (
                      <Typography>
                        You have not selected tip, do you want to proceed? Select
                        'Proceed' if you finished dining else select 'Cancel' to
                        order more or update tip %
                      </Typography>
                    ) : (
                      <Typography>
                        Select 'Proceed' if you finished dining else select 'Cancel'
                        to order more or update tip %
                      </Typography>
                    )}
                  </DialogContentText>
                ) : (
                  <DialogContentText id="alert-dialog-description">
                    <Typography>
                      Select 'Proceed' if you finished dining else select 'Cancel' to
                      order more
                    </Typography>
                  </DialogContentText>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setOpenAlert(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    // if (table == "takeout") checkWaiting()
                    // else
                    completeOrder(true)
                  }}
                >
                  Proceed
                </Button>
              </DialogActions>
            </Dialog>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openEmailAlert}
              onClose={handleCloseEmailAlert}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openEmailAlert}>
                <div className={classes.paper}>
                  <Typography>
                    We collect email to send you payment receipts after you complete
                    dining, we do not use it for sending promotional emails
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpenEmailAlert(false)
                    }}
                  >
                    Close
                  </Button>
                </div>
              </Fade>
            </Modal>
            <Dialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Item has been added to your bill from restaurant."}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => handleCloseDialog()}
                  color="primary"
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={showReviewBox}
              onClose={() => {
                setShowReviewBox(false)
                handleClose()
              }}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                How Was Your Overall Experience ?
              </DialogTitle>
              <DialogContent>
                <Rating
                  name="simple-controlled"
                  value={stars}
                  size="large"
                  onChange={(event, newValue) => {
                    setStars(newValue)
                  }}
                  style={{ fontSize: "40px" }}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="feedBack"
                  label="Give your feedback"
                  type="text"
                  fullWidth
                  onChange={(e) => setFeedbackMsg(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => giveFeedback()}
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={stars < 1}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
        </>
      ) : placingOrder ? (
        <Dialog open={true}>
          <Box
            sx={{ p: 2, position: "relative" }}
            alignItems="center"
            display="flex"
            flexDirection={"column"}
          >
            <Typography variant="h6" style={{ fontSize: "15px" }}>
              {restaurant?.name}
            </Typography>
            <Box
              sx={{ mt: 2, mb: 1, position: "relative" }}
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              <Box className={classes.spinner}>
                <RestaurantIcon
                  className={classes.overlay}
                  color="primary"
                  style={{ fontSize: "50px" }}
                />
              </Box>
              <CircularProgress size={100} thickness={1} />
            </Box>
            <Typography variant="h6" style={{ fontSize: "18px" }}>
              Processing Your Order
            </Typography>
          </Box>
        </Dialog>
      ) : (
        <LoadingCheckoutScreen />
      )}
      {showTour && (
        <Steps
          enabled={showTour}
          steps={Toursteps}
          initialStep={0}
          onExit={() => closeTour()}
        />
      )}
      <Dialog
        open={showWait}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your waiting number is {makeDecrypt("waitingNumber")}. Do you want to
            place order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowWait(false)} color="primary">
            NO
          </Button>
          <Button
            onClick={() => {
              setShowWait(false)
              if (table === "takeout") completeOrder(false)
              else startBillButton("normal")
            }}
            color="primary"
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAcceptMsg}>
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
        >
          <Box sx={{ mb: 2 }} display={"flex"} alignItems="center">
            <img
              src={Approval}
              className={classes.approvalIcon}
              width="25px"
              height={"25px"}
            />
            <Typography variant="h6" style={{ marginRight: "7px" }}>
              {!orderUpdated ? "Order Accepted" : "Order Updated"}
            </Typography>
          </Box>
          <Button
            margin="auto"
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setShowAcceptMsg(false)}
          >
            OK
          </Button>
        </Box>
      </Dialog>
      <Dialog open={showCancelMsg}>
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
        >
          <Box sx={{ mb: 2 }} display={"flex"} alignItems="center">
            <RestaurantMenuIcon className={classes.approvalIcon} color="primary" />
            <Typography variant="h6" style={{ marginRight: "7px" }}>
              Order Cancelled
            </Typography>
          </Box>
          <Button
            margin="auto"
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setShowCancelMsg(false)}
          >
            OK
          </Button>
        </Box>
      </Dialog>
      <Toaster />
    </Box>
  )
}

export default CheckoutScreen
