import React, { useState, useEffect } from "react"
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  CircularProgress,
  Button,
  Container,
  Typography,
  Snackbar,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import axios from "../axios"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { makeDecrypt } from "../utils/securels"
import { makeStyles } from "@material-ui/styles"
const useStyles = makeStyles((theme) => ({
  loadingBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "300px",
  },
}))
const UserProfile = () => {
  const { thisUser } = useSelector((state) => state.user)
  const classes = useStyles()
  const [refcount, setrefcount] = useState(0)
  const [user, setuser] = useState(thisUser)
  const [loading, setloading] = useState(false)
  const [snack, setsnack] = useState(false)
  const [msg, setmsg] = useState("")
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const [values, setValues] = useState({
    name: user?.name,
    email: user?.email,
    phone: user?.phone,
    createdAt: user?.createdAt,
    referralCode: user?.referralCode,
  })

  const UpdateChanges = () => {
    axios
      .patch(`/user/update-user/${makeDecrypt("userId")}`, {
        name: values.name,
        email: values.email,
        phone: values.phone,
        createdAt: values.createdAt,
        referralCode: values.referralCode,
      })
      .then((res) => {
        setmsg("Updated Sucessfully")
        setsnack(true)
      })
      .catch((error) => {
        setmsg("Update Failed")
        setsnack(true)
      })
  }
  useEffect(() => {
    // console.log(thisUser)

    if (!thisUser) {
      setloading(true)
      axios
        .post(`/user/get-user-details`, {
          userId: makeDecrypt("userId"),
        })
        .then((response) => {
          setuser(response.data)
          setrefcount(response.data?.reference?.length ?? 0)
          setValues({
            name: response.data?.name,
            email: response.data.email,
            phone: response.data?.phone,
            createdAt: response.data.createdAt,
            referralCode: response.data.referralCode,
          })
          setloading(false)
        })
        .catch((error) => {
          console.log(error.message)
        })
    }
  }, [])

  return (
    <Box style={{ height: "100%", backgroundColor: "#f7f7f7" }}>
      <Helmet>
        <title>Profile | Dinify</title>
      </Helmet>
      <Snackbar open={snack} autoHideDuration={6000} onClose={() => setsnack(false)}>
        <Alert
          onClose={() => setsnack(false)}
          severity={msg === "Updated Sucessfully" ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      {loading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            <Typography
              sx={{ mb: 3 }}
              style={{ justifyContent: "center", marginBottom: 10, display: "flex" }}
              variant="h4"
            >
              Welcome {values.name ?? "user"}
            </Typography>
            <Grid container>
              <Grid
                item
                lg={12}
                md={10}
                xs={12}
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <form autoComplete="off" noValidate>
                  <Card>
                    <CardHeader style={{ textAlign: "center" }} title="Profile" />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            helperText="Please specify the first name"
                            label="Name"
                            name="name"
                            onChange={handleChange}
                            required
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            onChange={handleChange}
                            required
                            value={values.email}
                            variant="outlined"
                          />
                        </Grid>
                        {/* <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Email Address"
                          name="email"
                          onChange={handleChange}
                          required
                          value={values.email}
                          variant="outlined"
                        />
                      </Grid> */}
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Phone Number"
                            name="phone"
                            onChange={handleChange}
                            value={values.phone}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="referral_code"
                            value={values.referralCode}
                            variant="outlined"
                            disabled={true}
                          />
                          <Typography variant="h6">
                            Referal Count: {refcount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={UpdateChanges}
                      >
                        Update details
                      </Button>
                    </Box>
                  </Card>
                </form>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  )
}

export default UserProfile
