import { Box, Button } from "@material-ui/core"
import Modal from "@material-ui/core/Modal"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}))

export default function AlertModalForNativePayment({
  open,
  setOpen,
  deleteGooglePayCard,
}) {
  const classes = useStyles()
  // getModalStyle is not a pure function, we roll the style only on the first render

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const body = (
    <div className={classes.paper}>
      <h5 id="simple-modal-title">Delete Alert</h5>
      <p id="simple-modal-description">
        Once you delete this card you will be not able to add card using
        Gpay/ApplePay from this browser
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={() => deleteGooglePayCard()}
      >
        Delete Card
      </Button>
    </div>
  )

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {body}
      </Modal>
    </Box>
  )
}
