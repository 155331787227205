import axios from "../axios"
import { makeDecrypt } from "../utils/securels";

export const getDiscount = () => async (dispatch) => {
    return axios
      .get(`discount/get-all-discounts/${makeDecrypt("restaurantId")}`)
      .then((res) => {
        if (res.data.length)
          dispatch({
            type: "SET_DISCOUNT",
            payload: res.data,
          });
        else {
          console.log("error");
        }
        return res.data;
      })
      .catch((error) => {
        return error.message;
      });
  };