import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

import { orderListReducer } from "./reducers/orderReducers"

import restaurantReducer from "./reducers/restaurantReducers"
import stripeReducer from "./reducers/stripeReducers"
import { couponReducer } from "./reducers/couponReducer"
import { menuReducer } from "./reducers/menuReducer"
import { userReducer } from "./reducers/userReducer"
import discountReducer from "./reducers/discountReducer"
import loadingReducer from "./reducers/loadingReducer"

const reducer = combineReducers({
  orderList: orderListReducer,
  restaurant: restaurantReducer,
  stripe: stripeReducer,
  coupon: couponReducer,
  menu: menuReducer,
  user: userReducer,
  discount: discountReducer,
  loading: loadingReducer,
})

const middleware = [thunk]

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
