const initialState = {
    discounts: [],
    loading: true,
}
  
  const discountReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_DISCOUNT": {
        return {
          ...state,
          discounts: action.payload,
          loading: false,
        }
      }
      default:
        return state;
    }
  }
  export default discountReducer
  