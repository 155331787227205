import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  Alert,
  TimelineSeparator,
} from "@material-ui/lab"
import {
  Typography,
  Grid,
  CardHeader,
  Box,
  Button,
  Card,
  Snackbar,
  Backdrop,
  CircularProgress,
  CardContent,
  Tooltip,
  IconButton,
  makeStyles,
  Popover,
  MenuItem,
  AppBar,
  Toolbar,
  Paper,
  List,
  Avatar,
  TablePagination,
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { useDispatch } from "react-redux"
import InputIcon from "@material-ui/icons/Input"
import { logoutAction } from "../actions/loginActions"
import { PastOrders } from "../components/customer/PastOrders"
import DateFilter from "../components/customer/DateFilter"
import { useSelector } from "react-redux"
import axios from "../axios"
import { getUserOrders } from "../actions/orderActions"
import { makeDecrypt } from "../utils/securels"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import LiveOrders from "../components/customer/LiveOrders"
import { useRef } from "react"
import { useHistory } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
  iconButton: {
    border: "none",
  },
  score: {
    borderColor: "green",
    border: 4,
    borderBlockStyle: "solid",
    borderRadius: 5,
  },

  paginationBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "right",
    },
  },
}))

const CustomerDashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [snack, setsnack] = useState(false)
  const [time_line, setTime_line] = useState([])
  const onLogout = () => {
    dispatch(logoutAction())
  }
  const { authenticated } = useSelector((state) => state.user)
  const [count, setcount] = useState(1)
  const [loading, setloading] = useState(false)
  const [live, setLive] = useState([])
  const [data, setdata] = useState([])
  const [startDate, setstartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  )
  const userRef = useRef(null)
  const [endDate, setendDate] = useState(new Date())
  const [pageNo, setpageNo] = useState(1)
  const [open, setopen] = useState(false)
  useEffect(() => {
    setloading(true)
    const getdetails = async () => {
      const userId = makeDecrypt("userId")
      dispatch(getUserOrders(startDate, endDate, pageNo, userId)).then((res) => {
        setdata(res.data)
        setTime_line(res.time_line)
        setcount(res.maxPages * 6)
        setloading(false)
      })
    }
    getdetails()
  }, [startDate, endDate, pageNo])

  useEffect(() => {
    const getLiveOrders = () => {
      const userId = makeDecrypt("userId")
      axios
        .get(`/order/${userId}/all-live-orders`, {
          headers: { Authorization: makeDecrypt("jwt") },
        })
        .then((response) => {
          if (response.data) {
            setLive(response.data)
          }
        })
    }
    getLiveOrders()
  }, [])

  return (
    <>
      <Helmet>
        <title>Dashboard | Dinify</title>
      </Helmet>
      <AppBar elevation={1} style={{ position: "relative" }}>
        <Toolbar>
          <RouterLink to="/">
            <Typography color="inherit" variant="h4">
              Dinify
            </Typography>
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton color="inherit" ref={userRef} onClick={() => setopen(true)}>
            <SupervisedUserCircleIcon className={classes.iconButton} />
          </IconButton>
          <Tooltip title="LogOut" arrow={true}>
            <IconButton color="inherit" onClick={() => onLogout()}>
              <InputIcon className={classes.iconButton} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Snackbar open={snack} autoHideDuration={6000} onClose={() => setsnack(false)}>
        <Alert
          onClose={() => setsnack(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Email sent successfully
        </Alert>
      </Snackbar>
      <Backdrop style={{ zIndex: 10000 }} open={loading}>
        <CircularProgress style={{ color: "black" }} />
      </Backdrop>
      {!!data.length && (
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "row",
            maxWidth: "100%",
            paddingTop: 64,
            width: "100%",
            backgroundColor: "#f7f3f0",
          }}
        >
          <Grid container>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <Card component={Paper} style={{ borderRadius: 5 }}>
                <DateFilter
                  setStartingDate={setstartDate}
                  setEndingDate={setendDate}
                ></DateFilter>
                <PastOrders data={data} snack={setsnack} />
                <Box className={classes.paginationBox}>
                  <Box>
                    <TablePagination
                      count={count}
                      rowsPerPageOptions={[]}
                      page={pageNo}
                      rowsPerPage={6}
                      onPageChange={(e, val) => {
                        setpageNo(val)
                      }}
                    />
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card component={Paper} style={{ borderRadius: 5 }}>
                <CardHeader title="TIMELINE" />
                <CardContent>
                  <Timeline position="alternate">
                    {time_line?.map((val) => (
                      <TimelineItem>
                        <TimelineOppositeContent color="text.secondary">
                          {new Date(val.time).toUTCString()}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot variant="outlined" color="primary" />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>{val.name}</TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </CardContent>
              </Card>
            </Grid>
            {!!live?.length && (
              <Grid item lg={8} md={12} xl={9} xs={12}>
                <Box component={Paper} sx={{ mt: 10 }}>
                  <Card>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      {live.map((item) => (
                        <LiveOrders data={item} />
                      ))}
                    </List>
                  </Card>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      {!!open && (
        <Popover
          anchorEl={userRef.current}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          onClose={() => setopen(false)}
          open={open}
          PaperProps={{
            sx: { width: "300px" },
          }}
        >
          <Box sx={{ py: 1.5, px: 2 }}>
            <Typography variant="overline" className={classes.score}>
              Reward Score: 0
            </Typography>
            <RouterLink to="/customer/profile">
              <Typography variant="h3">Profile</Typography>
            </RouterLink>
          </Box>
        </Popover>
      )}
    </>
  )
}

export default CustomerDashboard
