import { Box, Grid, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setRestaurant } from "../actions/restaurantActions"
import axios from "../axios"
import AlertMessage from "../components/AlertMessage"
import CouponHighlighter from "../components/CouponHighlighter"
import { makeDecrypt } from "../utils/securels"
import DishScreen from "./DishScreen"
function UpdateItem({ item, menuId, objectId, setShowUpdateScreen }) {
  const [currentItem, setCurrentItem] = useState(null)
  const [noOfItems, setNoOfItems] = useState()
  const [alertMessage, setAlertMessage] = useState("")
  const { restaurant } = useSelector((state) => state.restaurant)
  const { thisUser } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    const getCurrentItemFromTheCart = () => {
      axios
        .post(`/user/user-details`, {
          phone: makeDecrypt("user"),
          userId: makeDecrypt("userId"),
        })
        .then((response) => {
          response.data.cart.items.map((cartItem) => {
            if (
              cartItem.itemId === menuId &&
              cartItem.isOrdered === false &&
              cartItem._id == objectId
            ) {
              setNoOfItems(cartItem.quantity)
              setCurrentItem(cartItem)
              return ""
            }
          })
        })
        .catch((error) => setAlertMessage(error.message))
    }
    if (!restaurant) dispatch(setRestaurant(makeDecrypt("restaurantId")))

    if (menuId && !thisUser) {
      getCurrentItemFromTheCart()
    } else if (menuId && thisUser) {
      thisUser.cart.items.map((cartItem) => {
        if (
          cartItem.itemId === menuId &&
          cartItem.isOrdered === false &&
          cartItem._id == objectId
        ) {
          setNoOfItems(cartItem.quantity)
          setCurrentItem(cartItem)
          return ""
        }
      })
    }
  }, [menuId])

  return (
    <Box sx={{ minHeight: "300px" }}>
      <CouponHighlighter />
      {noOfItems ? (
        currentItem && (
          <DishScreen
            menuId={menuId}
            objectId={objectId}
            item={item}
            update={true}
            currentItem={currentItem}
            itemsNo={noOfItems}
            setShowUpdateScreen={setShowUpdateScreen}
          />
        )
      ) : (
        <Box sx={{ px: 2, py: 3 }}>
          <Grid container sx={{ display: "flex" }}>
            <Grid item xs={4}>
              <Skeleton
                style={{ borderRadius: "5px" }}
                variant="rect"
                width={70}
                height={70}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h3">
                <Skeleton height={30} width={100} />
              </Typography>
              <Typography variant="h6">
                <Skeleton height={40} width={50} />
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Skeleton variant="rect" height={50} />
          </Box>
          <Grid
            container
            style={{
              padding: "20px",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
            spacing={3}
          >
            <Grid item xs={6}>
              <Skeleton variant="rect" height={50} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rect" height={50} />
            </Grid>
          </Grid>
        </Box>
      )}
      {alertMessage && <AlertMessage alertMessage={alertMessage} />}
    </Box>
  )
}

export default UpdateItem
