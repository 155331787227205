import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import "firebase/auth"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { makeEncrypt } from "../../utils/securels"

const useStyles = makeStyles((theme) => ({
  topBar: {},
  root: {
    backgroundColor: "white",
    padding: "20px 5px",
  },
  otpWarning: {
    textAlign: "center",
    width: "90%",
    margin: "auto",
    marginBottom: "30px",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    height: "53px",
    width: "100%",
  },
  backdrop: {
    zIndex: "100000",
  },
  refBox: {
    textAlign: "center",
  },
  btnBox: {
    display: "flex",
    width: "fit-content",
    margin: "20px 0px 10px auto",
  },
  closeBox: {
    position: "absolute",
    top: "10px",
    right: "10px",
    boxShadow:
      " rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;",
    borderRadius: "50%",
  },
}))

function Otp({ user, setOpen, setShowLoading, showLoading, open }) {
  const classes = useStyles()
  const [otp, setOtp] = useState("")
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    setShowLoading(false)
  }, [])

  const handleSubmitOtp = (e) => {
    setShowLoading(true)
    e.preventDefault()
    const code = otp
    console.log("Code " + code)
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        console.log("Result " + result)
        dispatch({
          type: "SET_USER",
          payload: user,
        })
        dispatch({ type: "SET_AUTHENTICATED", payload: true })
        makeEncrypt("userId", user._id)
        makeEncrypt("user_email", user.email)
        makeEncrypt("auth", true)
        setShowLoading(false)
        setOpen(false)
        history.push("/customer/dashboard", { replace: true })
        // setLoading(false)
      })
      .catch((error) => {
        setShowLoading(true)
      })
  }

  return (
    <Dialog open={true}>
      <Typography
        variant="h5"
        style={{
          textAlign: "center",
          marginTop: "10px",
          fontWeight: 600,
          color: "#DF2800",
        }}
      >
        DINIFY
      </Typography>
      <Typography variant="body2" style={{ textAlign: "center" }}>
        New era of dining!
      </Typography>
      <Box onClick={() => setOpen(false)} className={classes.closeBox}>
        <CloseIcon />
      </Box>
      <Divider style={{ marginTop: "10px" }} />
      <DialogContent>
        <Box className={[classes.root, "root"]}>
          {open ? (
            <Box>
              <form id="otpBox" onSubmit={(e) => handleSubmitOtp(e)}>
                <Typography className={classes.otpWarning}>
                  Please enter the OTP sent on your phone number.
                </Typography>
                <TextField
                  value={otp}
                  name="otp"
                  variant="outlined"
                  label="Enter OTP "
                  fullWidth
                  style={{ marginTop: "20px" }}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!otp}
                  style={{ marginTop: "20px" }}
                >
                  Submit
                </Button>
              </form>
            </Box>
          ) : (
            <CircularProgress />
          )}
        </Box>
        <Backdrop className={classes.backdrop} open={showLoading}>
          <CircularProgress style={{ color: "black" }} />
        </Backdrop>
        <Typography style={{ textAlign: "center" }} variant="body2">
          Your information is never shared with third parties.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default Otp
