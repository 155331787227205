import React, { useState } from 'react'
import { Box, Typography, makeStyles } from "@material-ui/core"
import StarRatings from "react-star-ratings";
const useStyles = makeStyles((theme) => ({
    image: {
        borderRadius: "50%",
        margin: "0px auto",
        width: "60px",
        height: "60px",
        marginTop: '10px',
    },
    box: {
        overflow: "auto",
        marginRight: '10px',
        wordWrap: 'break-word',
        // backgroundColor: 'pink',
    },
}));
const ReviewCard = ({ item }) => {
    const [showFullText, setShowFullText] = useState(false);
    const classes = useStyles()
    const handleClick = (e) => {
        e.preventDefault();
        setShowFullText(!showFullText);
    }
    return (
        
                <Box key={item} className={classes.box} p={2} style={{
                    border: "0.5px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "7px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 5px",
                    // width: "250px",
                    height: "250px",
                    // justifyContent: "center",
                }}>
                    <Box>
                        <img
                         className={classes.image}
                            // width="60px"
                            // height="60px"
                            src={
                                item?.profile_photo_url
                                    ? item?.profile_photo_url
                                    : "https://i.ibb.co/3Mrx6Fg/blank-profile.webp"
                            }
                        />
                    </Box>
                    <Typography align="center" style={{ fontSize: "13px", fontWeight: "bold", marginTop: '10px' }}>
                        {item?.author_name?.toUpperCase()}
                    </Typography>
                    <Typography align="center" style={{ fontSize: "10px" }}>
                        {item?.relative_time_description}
                    </Typography>
                    <Typography style={{ color: "#202020", fontSize: "12px", textAlign: "justify", marginTop: '10px'  }}>
                        {showFullText ? item?.text : item?.text?.slice(0, 100)}
                        {!showFullText && "..."} {item?.text?.length > 100 && <a onClick={handleClick} style={{ fontSize: "bold", color: "blue" }}>{showFullText ? 'See Less' : 'See More'}</a>}
                    </Typography>
                    <Box style={{ textAlign: "center", marginTop: '10px' }}>
                        <StarRatings
                            rating={item?.rating}
                            starDimension="20px"
                            starSpacing="2px"
                            starRatedColor="orange"
                        />
                    </Box>
                </Box>
    )
}

export default ReviewCard