import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  makeStyles,
  Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import axiosInstance from "../axios"
import { makeDecrypt } from "../utils/securels"

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
  },
  taxRow: {
    width: "70vw",
    display: "flex",
    justifyContent: "space-between",
  },
}))

function OrderTable({
  totalPrice,
  tip,
  taxTypes,
  taxValue,
  fee,
  pstValue,
  orderCompleted,
  discount,
  finalValue,
  deliveryCharge,
  orderKind,
}) {
  const classes = useStyles()
  const [showTaxInfo, setShowTaxInfo] = useState(false)
  const [tax, setTax] = useState({})
  const { currencySign, restaurant } = useSelector((state) => state.restaurant)
  useEffect(() => {
    if (restaurant) {
      setTax(restaurant.taxTypes)
    }
  }, [restaurant])
  return (
    <Accordion
      style={{ shadow: 0 }}
      boxShadow="0"
      expanded={orderCompleted}
      className="grandtotal"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ margin: "0px" }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography style={{ fontWeight: "900" }}>Grand Total</Typography>
          <Typography style={{ fontWeight: "900", marginLeft: "auto" }}>
            {currencySign}
            {parseFloat(
              totalPrice +
              tip +
              parseFloat(deliveryCharge) +
              taxValue +
              fee +
              parseFloat(pstValue)
            ).toFixed(2)}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>Total</Typography>
          <Typography>
            {currencySign}
            {(totalPrice + (discount ? discount : 0)).toFixed(2)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>Tip</Typography>
          <Typography>
            {currencySign}
            {tip.toFixed(2)}
          </Typography>
        </Box>
        {orderKind === "delivery" ? (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Delivery Fee</Typography>
            <Typography>
              {currencySign}
              {deliveryCharge}
            </Typography>
          </Box>
        ) : (
          ""
        )}

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box sx={{ display: "flex" }}>
            <Typography>Taxes and Fees : </Typography>
            <InfoOutlinedIcon
              style={{ fontSize: "20px", margin: "auto 5px", cursor: "pointer" }}
              onClick={() => setShowTaxInfo(true)}
            />
          </Box>
          <Typography>
            {" "}
            {currencySign}{" "}
            {parseFloat(taxValue + fee + parseFloat(pstValue)).toFixed(2)}{" "}
          </Typography>
        </Box>
        
        {discount && (
          <Box display="flex" alignItems="center" color='red' justifyContent="space-between">
            <Typography>Discount :</Typography>
            <Typography>
              {" - "}
              {currencySign} {discount.toFixed(2)}{" "}
            </Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography style={{ fontWeight: "900" }}>Grand Total</Typography>
          <Typography style={{ fontWeight: "900" }}>
            {/* {console.log(deliveryCharge, tip, totalPrice)} */}
            {currencySign}
            {orderKind === "delivery"
              ? parseFloat(
                totalPrice +
                tip +
                parseFloat(deliveryCharge) +
                taxValue +
                fee +
                parseFloat(pstValue)
              ).toFixed(2)
              : parseFloat(
                totalPrice + tip + taxValue + fee + parseFloat(pstValue)
              ).toFixed(2)}
          </Typography>
        </Box>
      </AccordionDetails>
      {showTaxInfo && (
        <Dialog
          onClose={() => setShowTaxInfo(false)}
          aria-labelledby="simple-dialog-title"
          open={showTaxInfo}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="body2">
              {`Taxes and fees include applicable GST${restaurant.dinify_payment_plan.push_to_customer ||
                  restaurant.dinify_payment_plan.half_to_customer
                  ? `,`
                  : ` and`
                } applicable PST `}
              {(restaurant.dinify_payment_plan.push_to_customer ||
                restaurant.dinify_payment_plan.half_to_customer) &&
                `and technology fee ($ ${fee}) to help us serve
            you better`}
            </Typography>
          </Box>
        </Dialog>
      )}
    </Accordion>
  )
}

export default OrderTable
