import { colors } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
// import shadows from "./shadows"
// import typography from "./typography"

const theme = createTheme({
  palette: {
    background: {
      default: "#F4F6F8",
      paper: colors.common.white,
    },
    primary: {
      contrastText: "#ffffff",
      main: "#DF2800",
    },
    text: {
      primary: "#000000",
      secondary: "#6b778c",
    },
  },
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: "-0.24px",
    },
    h2: {
      fontWeight: 600,
      fontSize: 19,
      letterSpacing: "-0.24px",
    },
    h3: {
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: "-0.06px",
    },
    h4: {
      fontWeight: 500,
      fontSize: 17,
      letterSpacing: "-0.06px",
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: "-0.05px",
    },
    h6: {
      fontWeight: 500,
      fontSize: 15,
      letterSpacing: "-0.05px",
    },
    body1: {
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: "-0.05px",
    },
    body2: {
      fontWeight: 400,
      fontSize: 13,
      letterSpacing: "-0.05px",
      color: "#6b778c",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 15,
      letterSpacing: "-0.05px",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 12,
      letterSpacing: "-0.05px",
    },
    button: {
      fontWeight: 500,
      fontSize: 13,
      letterSpacing: "-0.05px",
    },
    caption: {
      fontWeight: 500,
      fontSize: 10,
      letterSpacing: "-0.05px",
    },
    // overline
  },
  // shadows,
})

export default theme
