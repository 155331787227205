import axiosInstance from "../axios"
import { makeDecrypt } from "../utils/securels"
export const refreshCheckout = () => {
  return {
    type: "REFRESH",
  }
}

export const getCurrentOrder = (id) => async (dispatch) => {
  try {
    const order = await axiosInstance.get(`/user/order/${id}`)
    dispatch({ type: "SET_ORDER", payload: order.data })
  } catch (error) {
    console.log(error)
  }
}

export const getUserOrders =
  (StartingDate, EndingDate, page, userId) => async (dispatch) => {
    const start_date = new Date(StartingDate).toISOString().split("T")[0]
    const end_date = new Date(EndingDate).toISOString().split("T")[0]
    return await axiosInstance
      .get(
        `/order/${userId}/get-user-orders?start=${start_date}&end=${end_date}&page=${page}`,
        {
          headers: { Authorization: makeDecrypt("jwt") },
        }
      )
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        return error.message
      })
  }
