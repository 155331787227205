import { deleteFromLocal, makeDecrypt, makeEncrypt } from "../utils/securels"
import axios from "../axios"

// let history = useHistory();

export const loginAction = (userData, navigate) => async (dispatch) => {
  return axios
    .post("/auth/signin-customer", userData)
    .then((response) => {
      const jwt = `Bearer ${response.data.token}`
      makeEncrypt("jwt", jwt)
      makeEncrypt("userId", response.data.user._id)

      axios.defaults.headers.common.Authorization = jwt
      // navigate('/app/dashboard', { replace: true })
      return response.data
    })
    .catch((error) => {
      return error.message
    })
}

export const logoutAction = () => (dispatch) => {
  deleteFromLocal("jwt")
  deleteFromLocal("auth")
  dispatch({ type: "SET_AUTHENTICATED", payload: false })
  delete axios.defaults.headers.common.Authorization
  // dispatch({ type: SET_UNAUTHENTICATED })
  window.location.href = "/customer"
}
