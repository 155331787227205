import jsPDF from "jspdf"
import "jspdf-autotable"
import axios from "../../../axios"
import { makeDecrypt } from "../../../utils/securels"
export const SendEmail = (email, order, items, restaurant) => {
  axios
    .post(
      `/order/send-receipt-mail`,
      {
        email: email,
        order: order,
        items: items,
        restaurant: restaurant,
      },
      {
        headers: { Authorization: makeDecrypt("jwt") },
      }
    )
    .then((response) => {
      console.log(response)
      return response.data.message
    })
}
