import axios from "../../axios"
import { makeDecrypt } from "../../utils/securels"

export const getUser = () => {
  return axios
    .post("/user/user-details", {
      phone: makeDecrypt("user"),
      userId: makeDecrypt("userId"),
    })
    .then((user) => {
      return user.data
    })
    .catch((error) => {
      return error.message
    })
}

export const getTotolOrders = () => {
  return axios
    .post("order/get-total-orders", { userId: makeDecrypt("userId") })
    .then((data) => {
      return data.data
    })
    .catch((error) => {
      return error
    })
}

export const createUser = async (phone, createFlag) => {
  try {
    if (createFlag)
      return await axios
        .post("/user/create-user", { phone: phone })
        .then((res) => res.data)
        .catch((error) => error.message)
    else
      return await axios
        .post("/user/user-details", { phone: phone })
        .then((res) => res.data)
        .catch((error) => error.message)
  } catch (error) {
    return error
  }
}
